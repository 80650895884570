import { render, staticRenderFns } from "./basketballBoard.vue?vue&type=template&id=22ed1e20&scoped=true"
import script from "./basketballBoard.vue?vue&type=script&lang=js"
export * from "./basketballBoard.vue?vue&type=script&lang=js"
import style0 from "./basketballBoard.vue?vue&type=style&index=0&id=22ed1e20&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22ed1e20",
  null
  
)

export default component.exports