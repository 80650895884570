<template>
    <div class="content">
        <mt-header title="管理裁判">
            <router-link to="/menu" slot="left" style="background-color: #fff;border: #fff;">
                <mt-button icon="back">返回</mt-button>
            </router-link>
        </mt-header>
		<!-- <el-button class="exportExcel" :type="is_signup == 0 ? 'danger' : 'success' "  @click="changeSignup(is_signup)">{{is_signup == 0 ? "所有队伍报名截止" : "所有队伍报名开启"}}</el-button> -->
        <div class="main-box">
            <div class="scroll-box">
              <div class="table-item" v-for="(item,index) in refsData" :key="index">
				  <div class="table-item-main">
					  <div class="table-item-team">
						  <img :src="item.portrait || defaultlogo" alt="" class="table-item-team-img" />
					  </div>
					  <div class="table-item-team-info-main">
					  		<span class="table-item-team-name">{{item.name}}（{{item.sfzh}}）</span>
					  		<span class="table-item-team-info">{{getLevel(item.grade)}}·{{getType(item.rjob)}}·{{item.tel}}</span>
					  </div>
					  
				  </div>
				  <i class="iconfont icon-gengduo2" @click="showmenu(item.id)"></i>
				  <span class="table-item-team-group"></span>
			  </div>
				<div class="footer-page">
				    <el-pagination
				      background
					  :current-page="pageIndex"
				      layout="prev, pager, next"
				      :total="total"
				      :page-size="pageSize"
				      @current-change="changepage"
				      :hide-on-single-page="true"
				      >
				    </el-pagination>
				</div>
			</div>
			<mt-actionsheet
			  :actions="actions"
			  v-model="sheetVisible">
			</mt-actionsheet>
			
			<mt-popup
			 style="width: 100%;"
			  v-model="popupVisible"
			  position="bottom">
			  <div class="header-box"><span @click="changeType()">确认</span><span @click="cancelSel">取消</span></div>
			  <mt-picker :slots="slots" @change="onValuesChange"></mt-picker>
			</mt-popup>
        </div>
        
    </div>
</template>

<script>
	// import { MessageBox } from 'mint-ui';
    export default {
      data() {
		  let that = this
        return {
			slots: [
			{
			  flex: 1,
			  values: ['普通', '技术代表', '副裁判长', '裁判长'],
			  className: 'slot1',
			  textAlign: 'center'
			}
			],
           loading: true,
           total: 0,
           preimg: "",
           is_show: false,
           refsData: [],
           liveid: "",
           PageIndex: 1,
           pagesize: -1,
           boldRowIndex: -1,
		   chooseId: 0,
		   sheetVisible: false,
		   popupVisible: false,
		   selectRefType: '',
		   actions: [
		   	{
		   		name: '修改身份',
		   		method: that.selectType
		   	},
		   	{
		   		name: '免费观看回放',
		   		method: that.freeView
		   	},
		   	{
		   		name: '删除',
		   		method: that.del
		   	}
		   ]
        }
    },
    created() {
        if (this.liveid == "") {
            this.$api.post('api/Main/GetGame').then(response => {
                if (response.data.code == 0) {
                    this.$message({
                      type: 'error',
                      message: response.data.data
                    });
                    this.$router.push({ path: '/login'});
                    return;
                }
                this.liveid = response.data.data.id
                this.fetchData()
            }).catch(error => {
                console.error("There was an error!", error);
            })
        } else {
            this.fetchData()
        }
        
        
    },
    methods: {
        fetchData() {
            console.log(this.PageIndex)
            this.$api.get(`app/match/referees`,{
              params: {
                PageIndex: this.PageIndex,
                liveid: this.liveid,
                pagesize: this.pagesize
              }
            }).then(response => {
                // this.loading = false
                this.refsData = response.data.data.rows
                this.total = response.data.data.total
            })
            .catch(error => {
              console.error("There was an error!", error);
            });
        },
        changepage(p) {
            
            this.PageIndex = p
            
            this.fetchData()
        },
        changeType() {
			this.popupVisible = false
            const type = this.selectRefType
            let type_name = ""
            switch(type) {
                case '普通':
                    type_name = 0;break;
                case '技术代表':
                    type_name = 1;break;
                case '副裁判长':
                    type_name = 2;break;
                case '裁判长':
                    type_name = 3;break;
            }
            this.$confirm("确定要更改为【"+type+"】吗?", '提示', {
				customClass: 'elmessageWidth',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let formdata = new FormData();
                formdata.append('id', this.chooseId)
                formdata.append('liveid', this.liveid)
                formdata.append('job', type_name)
                this.$api.post('app/match/setRJob',formdata).then(response => {
                    if (response.data.code == 1) {
                        this.$message({
                            type: 'success',
                            message: '已更新！'
                        });
                        let item = this.refsData.find(item => item.id === this.chooseId)
                        item.rjob = type_name
                        item.isBold = true
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                        });
                    }
                }).catch(error => {
                    console.error("There was an error!", error);
                })
                
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        getType(type) {
            let type_name = ""
            switch(type) {
                case 0:
                    type_name = '普通';break;
                case 1:
                    type_name = '技术代表';break;
                case 2:
                    type_name = '副裁判长';break;
                case 3:
                    type_name = '裁判长';break;
                default: type_name = '普通';break;
            }
            return type_name;
        },
        getLevel(type) {
            let type_name = ""
            switch(type) {
                case 0:
                    type_name = '三级裁判';break;
                case 1:
                    type_name = '二级裁判';break;
                case 2:
                    type_name = '一级裁判';break;
                case 4:
                    type_name = '国际级裁判';break;
                default: type_name = '裁判';break;
            }
            return type_name;
        },
        del() {
			const id = this.chooseId
            this.$confirm("确定删除该裁判吗?", '提示', {
				customClass: 'elmessageWidth',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.get(`/api/Match/DelReferee/${id}/${this.liveid}`)
                .then(response => {
                    let reult = response.data
                    if (reult.code == 1) {
                        this.$message({
                            type: 'success',
                            message: '删除成功！'
                        });
                        this.refsData = this.refsData.filter(item => item.id !== id)
                    } else {
                        this.$message({
                            type: 'error',
                            message: reult.data
                        });
                    }
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        viewImg(img) {
            this.chooseImg = img
            this.isBig = true
        },
        freeView() {
			const id = this.chooseId
			const team = this.refsData.find(item=>item.id === id)
			const set = team.rbackvideo
            let msg = ""
            if (set == 0) {
                msg = "确定设置该裁判为免费观看吗?"
            }else {
                msg = "确定取消该裁判免费观看吗?"
            }
            this.$confirm(msg, '提示', {
				customClass: 'elmessageWidth',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let formdata = new FormData();
                formdata.append('id', id)
                formdata.append('liveid', this.liveid)
				
                formdata.append('backvideo', Math.abs(set - 1))
                this.$api.post('app/match/setRVideo',formdata).then(response => {
                    if (response.data.code == 1) {
                        this.$message({
                            type: 'success',
                            message: '设置成功！'
                        });
                        let item = this.refsData.find(item => item.id === id)
                        item.rbackvideo = Math.abs(set - 1)
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                        });
                    }
                }).catch(error => {
                    console.error("There was an error!", error);
                })
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        showPre(url) {
            this.preimg = url
            this.is_show = true
        },
		showmenu(id) {
			this.chooseId = id
			this.sheetVisible = true
			const team = this.refsData.find(item=>item.id === id)
			team.rbackvideo == 0 ? this.actions[1].name = '免费观看回放' : this.actions[1].name = '解除免费'
			console.log(this.actions)
		},
		selectType() {
			this.popupVisible = true
		},
		onValuesChange(e) {
			this.selectRefType = e.values[0]
		},
		cancelSel() {
			this.sheetVisible = false
			this.popupVisible = false
		}
        
      }
    }
  </script>

<style scoped>
	
	.search-box {
	    position: absolute;
	    top: 20px;
	    right: 60px;
		width: 250px;
		height: 40px;
	}
	
	::v-deep .el-input__inner {
	  background: #f0f0f0;
	  border-radius: 40px;
	  border: 0px;
	}
	
    .team-logo-box {
        display: flex;
        padding-left: 20px;
    }
    
    .team-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        line-height: 30px;
        text-align: left;
    }
    
    .team-info-name {
        font-size: 18px;
        font-weight: bold;
    }
    
    .team-info-score {
        text-align: left;
        font-size: 14px;
    }
    
    .score-win {
        font-size: 18px;
        font-weight: bold;
        color: #039f2f;
        mmargin-right: 5px;
    }
    .score-lose {
        font-size: 18px;
        font-weight: bold;
        color: #e80000;
        mmargin-right: 5px;
    }
    
    .el-table .cell {
      font-size: 16px; /* 调整字体大小 */
    }
    
    .el-icon-more {
        font-size: 22px;
        cursor: pointer;
        margin-left: 20px;
    }
    
    .el-icon-more:hover {
        color: black;
    }
    
    .el-table__cell {
        height: 80px;
    }
    
    .slideBox {
        width: 90%;
        margin: 0 auto;
    }
    
    .el-scrollbar__wrap {
        overflow-x: hidden!important;
    }
    
    .footer-page {
        width: 100%;
        height: 60px;
        line-height: 60px;
        margin-top: 30px;
        text-align: center;
    }
    
    .members_box {
        height: 500px;
        overflow-y: scroll;
    }
    
    #app {
        text-align: left;
    }
    .redFont {
        color: red;
    }
    
    .greenFont {
        color: green;
    }
    
    #tags-container {
      display: flex;
      background: transparent; /* 设置父元素的背景色 */
      border-bottom: 1px solid #ddd; /* 父元素底部边框线 */
      padding-left: 0;
      margin: 0;
      list-style: none;
      position: relative;
      margin-top: 10px;
	  flex-wrap: wrap;
    }
    
    .tag {
      font-size: 14px;
      color: #333; /* 标签文字颜色 */
      background: #f0f0f0; /* 未选中的标签背景色 */
      padding: 8px 16px; /* 根据实际需要调整内间距 */
      cursor: pointer;
      transition: background-color 0.3s ease; /* 平滑背景色变化 */
      width: auto;
      height: 20px;
      position: relative;
      bottom: -6px;
	  z-index: 9990;
    }
    
    /* 未选中的标签样式 */
    .tag:not(.active) {
      transform: scale(1); /* 未选中的标签稍微缩小 */
      border: 1px solid transparent; /* 防止布局抖动 */
    }
    
    /* 选中的标签样式 */
    .tag.active {
      position: relative;
      background: #fff; /* 选中的标签背景色 */
      border-top: 1px solid #ddd; /* 选中标签上边框 */
      border-left: 1px solid #ddd; /* 选中标签左边框 */
      border-right: 1px solid #ddd; /* 选中标签右边框 */
      border-bottom: none; /* 移除选中标签底部边框 */
      transform: scale(1); /* 恢复标签正常大小 */
      z-index: 1; /* 确保边框不被其他元素覆盖 */
      bottom: -1px;
      height: 25px;
    }
    
    .draggable-container {
      display: flex;
      flex-wrap: wrap;
    }
	
	.exportExcel {
	    position: absolute;
	    top: 20px;
	    right: 20px;
	}
	
	.content {
	    position: relative;
	}
	
	/deep/ .el-table__row {
		cursor: pointer;
	}
	
	/deep/ .el-slider__marks-text:last-child {
		width: 50%!important;
	}
	
	.table-item {
		width: 100%;
		height: 100px;
		border-bottom: 1px solid #ccc;
		position: relative;
		display: flex;
		align-items: center;
		
	}
	
	.table-item-top {
		display: flex;
		justify-content: right;
		padding: 10px;
		min-height: 20px;
	}
	
	.table-item-top-tag {
		background-color: #559BFF;
		padding: 5px 10px;
		font-size: 12px;
		color: #fff;
		border-radius: 5px;
		margin-left: 5px;
	}
	
	.table-item-main {
		width: 100%;
		display: flex;
	}
	
	.table-item-team {
		display: flex;
		margin-left: 30px;
		/* margin-top: -10px; */
	}
	
	.table-item-team-img {
		width: 60px;
	}
	
	.table-item-team-grade-home {
		width: 20px;
		height: 30px;
		background-color: #559BFF;
		color: #fff;
		text-align: center;
		line-height: 30px;
		
	}
	
	.table-item-team-grade-guest {
		width: 20px;
		height: 30px;
		background-color: red;
		color: #fff;
		text-align: center;
		line-height: 30px;
	}
	
	.table-item-team-info-main {
		display: flex;
		flex-direction: column;
		justify-content: left;
		align-items: left;
		text-align: left;
		/* margin-top: -10px; */
		margin-left: 20px;
	}
	
	.table-item-team-name {
		margin-bottom: 10px;
		font-size: 16px;
		font-weight: bold;
		margin-top: 5px;
	}
	
	.table-item-team-info {
		font-size: 14px;
		color: #818181;
	}
	
	.icon-gengduo2 {
		position: absolute;
		right: 10px;
		top: 50px;
	}
	
	.table-item-team-group {
		position: absolute;
		right: 10px;
		top: 70px;
		font-size: 13px;
	}
	
	.scroll-box {
		height: calc(100vh - 120px);
		overflow-y: scroll;
	}
	
	.mint-header {
		background-color: #fff;
		color: #000;
	}
	
	.mint-header-title {
		color: #000;
	}
	
	.header-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 60px;
		width: calc(100% - 40px);
		padding: 0px 20px;
	}
	
	
	
    







    
</style>