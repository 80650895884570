<template>
	<div class="content">
		<mt-header :title="title">
		    <router-link to="/menu" slot="left" style="background-color: #fff;border: #fff;">
		        <mt-button icon="back">返回</mt-button>
		      </router-link>
		</mt-header>
		<div class="main-box main-box-1">
		    <div>
		        <el-form ref="form" :model="form" label-width="80px" class="form-box">
		            <el-form-item :label="gamemode == 9 ? '项目名称': '球队名称'">
		                <el-input v-model="form.name" :placeholder="gamemode == 9 ? '请输入项目名称': '请输入球队名称'" @input="getHistory"></el-input>
		            </el-form-item>
		            <el-form-item label="名称缩写">
		                <el-input v-model="form.shortnick" placeholder="请输入不超过5个字符的缩写"></el-input>
		            </el-form-item>
		            <el-form-item :label="gamemode == 9 ? '项目标志': '球队标志'">
		                <el-upload
		                  class="avatar-uploader"
		                  action="''"
		                  drag
		                  :auto-upload="false"
		                  :show-file-list="false"
		                  :on-change="handleChangeUpload">
		                  <img v-if="form.logo" :src="form.logo" class="avatar">
		                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
		                  <template #tip>
		                      <div class="el-upload__tip">请上传jpg/png格式图片，300*300像素，2M以下图片<br>
		                      如果无法上传图片，请看看文件扩展名是否jpg或png
		                      </div>
		                  </template>
		                </el-upload>
		            </el-form-item>
		            <el-form-item>
						<div style="display: flex">
		                <el-button class="create_submit" v-if="type === 'add'" type="primary" @click="onSubmit">{{gamemode == 9 ? '创建项目': '创建球队'}}</el-button>
		                <el-button class="create_submit" v-else type="primary" @click="onEdit">确定修改</el-button>
		                <el-button @click="reset">重置</el-button>
						</div>
		            </el-form-item>
		        </el-form>
		    </div>
		</div>
		<div class="historyLogos" v-if="isHistory || type=='edit'">
		    <div>曾经用过的标志</div>
		    <div class="logos">
		        <img v-for="(item,index) in usedlogo" :key="index" @click="selLogo(item.id,'used')" :src="item.url" alt="" />
		    </div>
		</div>
		<div class="historyLogos" v-if="isHistory || type=='edit'">
		    <div>推荐的标志</div>
		    <div class="logos">
		        <img v-for="(item,index) in recommendlogos" :key="index" @click="selLogo(item.id,'recom')" :src="item.url" alt="" />
		    </div>
		</div>
		<MyCropperMobile :showDialog="dialogVisible" :image="cropperImage" @update-logo="handleLogoUpdate" @cancelDialog="closeDialog" :width="cropperWidth" :height="cropperWidth"></MyCropperMobile>
	</div>
</template>

<script>
    import { MessageBox } from 'mint-ui';
    export default {
        data() {
          return {
            form: {
                id: 0,
                intro: "",
                logo: "",
                name: "",
                seq: 0,
                shortnick: "",
                teamgroup: "A",
				teamtag: "全部"
            },
			gamemode: 1,
            isHistory: false,
            title: "创建球队",
            type: "add",
            editId: 0,
            isPreview: false,
            dialogVisible: false,
            cropperImage: "",
            previewImg: '', // 预览图片地址
            recommendlogos: [
                { id: 1, url: require("@/assets/images/logo/basketball/1.jpg"), },
                { id: 2, url: require("@/assets/images/logo/basketball/2.jpg"), },
                { id: 3, url: require("@/assets/images/logo/basketball/3.jpg"), },
                { id: 4, url: require("@/assets/images/logo/basketball/4.jpg"), },
                { id: 5, url: require("@/assets/images/logo/basketball/5.jpg"), },
                { id: 6, url: require("@/assets/images/logo/basketball/6.jpg"), },
                { id: 7, url: require("@/assets/images/logo/basketball/7.jpg"), },
                { id: 8, url: require("@/assets/images/logo/basketball/8.jpg"), },
                { id: 9, url: require("@/assets/images/logo/basketball/9.jpg"), },
                { id: 10, url: require("@/assets/images/logo/basketball/10.jpg"), },
                { id: 11, url: require("@/assets/images/logo/basketball/11.jpg"), },
                { id: 12, url: require("@/assets/images/logo/basketball/12.jpg"), },
                
            ],
            teamData: [],
            usedlogo: [
                {
                    id: 1, url: "http://www.douzhan.club/resources/teamlogo/431e6e88-f35f-4b63-8558-4a46671cdf4d.png"
                }
            ],
            defaultlogo: require('@/assets/images/defaultlogo.jpg'),
            pageIndex: 1,
            limit: 5,
            liveid: "",
            oid: "",
            teamname: "",
            total: 0,
			backGroup: '全部',
			cropperWidth: 300,
			cropperHeight: 300,
			
			
          }
        },
        created() {
            let query = this.$route.query
            if (query.id) {
                this.type = "edit"
                this.title = "编辑球队"
                this.editId = query.id
                this.backGroup = query.group
                this.$api.get(`api/Team/GetTeam/${query.id}`)
                .then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    let reult = response.data.data
                    this.form = reult
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
                
            }
            
            if (this.liveid == "") {
                this.$api.post('api/Main/GetGame').then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    this.liveid = response.data.data.id
                    this.oid = response.data.data.oid
					this.gamemode = response.data.data.otype
                    this.fetchTeamData()
                }).catch(error => {
                    console.error("There was an error!", error);
                })
            } else {
                this.fetchTeamData()
            }
        },
        watch: {  
            '$route.query.id'(newVal) {  
              // 当路由的查询参数id发生变化时，执行这里的代码  
              if (newVal) {  
                // 如果新值存在，则进入编辑模式  
                this.type = "edit";  
                this.title = "编辑球队";  
                this.editId = newVal;  
                // 你可以在这里根据id获取球队信息并填充到form中  
                // 例如，你可以发起一个API请求来获取球队信息    
              } else {  
                // 如果新值为空，则可能进入添加模式或其他默认模式  
                this.type = "add"; // 或设置为默认模式  
                this.title = "添加球队"; // 或设置为默认标题  
                this.editId = null;  
                // 重置form到初始状态或添加模式的默认状态  
                this.form = {  
                  id: 0,
                  intro: "",
                  logo: "",
                  name: "",
                  seq: 0,
                  shortnick: "",
                  teamgroup: "A",
                  teamtag: "全部",
                };  
              }  
            },  
        },
		mounted() {
			console.log("滚了")
		    window.scrollTo(0, 0);
		},
        methods: {
            fetchTeamData() {
                let formData = new FormData();
                formData.append('tname', this.teamname);
                formData.append('oid', this.oid);
                formData.append('liveid', this.liveid);
                formData.append('PageIndex', this.pageIndex);
                formData.append('limit', this.limit);
                this.$api.post('api/Team/copyteams', formData).then(response => {
                    this.teamData = response.data.rows;
                    this.total = response.data.total;
                    console.log(this.teamData)
                })
            },
            changepage(p) {
                this.pageIndex = p
                this.fetchTeamData()
            },
            onSubmit() {
                if (this.form.name == "") {
                    this.$message({
                        type: 'warning',
                        message: '请填写队伍名称'
                    });
                    return;
                }
                
                if (this.form.shortnick == "") {
                    this.$message({
                        type: 'warning',
                        message: '请填写队伍名称缩写'
                    });
                    return;
                }
				
                MessageBox.confirm('确认创建球队?').then(() => {
                    this.$api.post('api/Team/SaveTeam', this.form ,{
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => {
                      console.log(response.data);
                        if (response.data.code == 1) {
                            this.$message({
                                type: 'success',
                                message: '发布成功!'
                            });
                            this.$router.push({ path: '/mteam'});
                        } else {
                            this.$message({
                                type: 'error',
                                message: response.data.data
                            });
                            this.$router.push({ path: '/mlogin'});
                        }
                    })
                    .catch(error => {
                      console.error("There was an error!", error);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
                });
            },
            onEdit() {
                if (this.form.name == "") {
                    this.$message({
                        type: 'warning',
                        message: '请填写队伍名称'
                    });
                    return;
                }
                
                if (this.form.shortnick == "") {
                    this.$message({
                        type: 'warning',
                        message: '请填写队伍名称缩写'
                    });
                    return;
                }
                MessageBox.confirm('确定要修改吗?').then(() => {
                    this.$api.post('api/Team/SaveTeam', this.form ,{
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => {
                        if (response.data.code == 1) {
                            this.$message({
                                type: 'success',
                                message: '更新成功!'
                            });
                            this.$router.push({ path: '/mteam', query: { group: this.backGroup}});
                        } else {
                            this.$message({
                                type: 'error',
                                message: response.data.data
                            });
                            this.$router.push({ path: '/mlogin'});
                        }
                    })
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
            },
            reset() {
                this.form = {
                    id: 0,
                    intro: "",
                    logo: "",
                    name: "",
                    seq: 0,
                    shortnick: "",
                    teamgroup: "A"
                }
            },
            handleAvatarSuccess(res, file) {
                this.form.logo = URL.createObjectURL(file.raw);
            },
            handleChangeUpload(file) {
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                    return false
                }
                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(async () => {
                    // base64方式
                    // this.option.img = await fileByBase64(file.raw)
                    console.log("成功", URL.createObjectURL(file.raw))
                    this.cropperImage = URL.createObjectURL(file.raw)
                    this.loading = false
                    this.dialogVisible = true
                })
            },
            base64ToBlob(base64Data) {
                // 分割数据
                let parts = base64Data.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
            
                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }
            
                return new Blob([uInt8Array], {type: contentType});
            },
            
            fetchTeamInfo(id) {  
             
              console.log(id)
              this.form = {
                  name: '西南战队',
                  abbr: 'EDG',
                  logo: 'https://5b0988e595225.cdn.sohucs.com/images/20180108/709fbd0d401e426784be57845d305276.jpeg',
              }
            },
            handleLogoUpdate(croppedImageUrl) {
                let blobImage = this.base64ToBlob(croppedImageUrl);
                let formData = new FormData();
                formData.append('file', blobImage, +new Date+'.jpg');
                this.$api.post('api/Team/playerupload', formData).then(response => {
                    this.form.logo = response.data.data;
                })
            },
            closeDialog() {
                this.dialogVisible = false
            },
            getHistory() {
                if (this.form.name !== "") {
                    this.isHistory = true
                } else {
                   this.isHistory = false 
                }
            },
            selLogo(id, t) {
                let list = ""
                t == "recom" ? list = this.recommendlogos : list = this.usedlogo
                const item = list.find(item => item.id === id)
                fetch(item.url)
                .then(response => {
                  if (response.ok) return response.blob();
                  throw new Error('Network response was not ok.');
                })
                .then(blob => {
                  // 创建一个Blob URL
                  let formData = new FormData();
                  formData.append('file', blob, +new Date+'.jpg');
                  this.$api.post('api/Team/playerupload', formData).then(response => {
                      this.form.logo = response.data.data;
                  })
                })
                .catch(error => {
                  console.error('Error:', error);
                });
            },
            copyTeam(id, mode) {
                this.$confirm('确定要复制该球队吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let formData = new FormData();
                    formData.append('id', id);
                    formData.append('liveid', this.liveid);
                    formData.append('playerin', mode);
                    this.$api.post('api/Team/teamin', formData).then(response => {
                        if (response.data.code == 1) {
                            this.$message({
                                type: 'success',
                                message: '复制成功'
                            }); 
                        }else {
                            this.$message({
                                type: 'error',
                                message: response.data.data
                            });
                            this.$router.push({ path: '/login'});
                        }
                        
                    })
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
                
            },
            searchTeam() {
                this.pageIndex = 1
                this.fetchTeamData()
            }
        }
      }
</script>
<style scoped>
    .content {
        padding: 10px;
        width: calc(100% - 20px);
        
    }
    .main-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .main-box-item {
        width: 49%;
    }
    
    .main-box-left {
        min-height: calc(100vh - 240px);
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        
        margin: 10px;
    }
    
    .main-box-right {
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(65% - 50px);
        margin: 10px;
    }
    .topnav {
        text-align: left;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        height: 40px;
        line-height: 40px;
        padding-bottom: 20px;
    }
    
    .btn-area {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .btn-area-btn {
        width: 120px;
        margin-bottom: 10px;
    }
    
    .search-input {
        width: 200px;
        height: 30px;
        border-radius: 30px;
        border: 1px solid #ccc;
        text-indent: 1em;
    }
    
    .el-icon-search {
        position: absolute;
        right: 10px;
        top: 60%;
        color: #666;
    }
    
    .create_submit {
        width: 220px;
    }
	
	.form-box {
		margin: 0px;
		width: calc(100% - 20px);
		margin-top: 20px;
	}
	
	.logos {
	    display: grid; 
	    grid-template-columns: repeat(3, 1fr); 
	    gap: 20px;
		width: 100%;
	}
	
	.logos img {
		margin: 0px;
		width: auto;
	}
	
	.historyLogos {
		margin: 0px;
		width: 100%
	}
	
	.mint-header {
		background-color: #fff;
		color: #000;
	}
	
	.mint-header-title {
		color: #000;
	}
	
</style>
