<template>
    <div class="content">
        <mt-header title="发布通知">
            <router-link to="/menu" slot="left" style="background-color: #fff;border: #fff;">
                <mt-button icon="back">返回</mt-button>
              </router-link>
        </mt-header>
        <Toolbar
            style="border-bottom: 1px solid #ccc;padding-top: 20px"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <div class="main-box">
            
            <el-form :rules="rules" label-width="100px" class="demo-ruleForm">
              <el-form-item prop="name" label-width="0px">
                <el-input v-model="editBody.title" placeholder="请输入通知标题" class="notice-title"></el-input>
              </el-form-item>
              <el-form-item prop="delivery" style="font-weight: normal;" label-width="0px">
                
                <Editor
                    style="height: 500px; overflow-y: hidden;font-size: 20px;border-bottom: 1px solid #ccc;"
                    v-model="editBody.content"
                    :defaultConfig="editorConfig"
                    :mode="html"
                    @onCreated="onCreated"
                />
              </el-form-item>
              <!-- <el-form-item label="选择封面" prop="region">
                <div class="cover-box-img">
                    <img :src="ruleForm.cover" alt="" />
                </div>
                <div class="imgs-item">
                    <div class="cover-box">
                        <div class="cover-items" v-for="(item,index) in coverList" @click="chooseCover(index)" :key="index" :class="chooseCoverIdx == index ? 'active_item' : ''">
                            <div class="check-icon" v-show="chooseCoverIdx == index" >
                                <img src="@/assets/images/check.png" alt="Checked" />
                            </div>
                            <img class="cover-items-img" :src="item.img" alt="" />
                        </div>
                    </div>
                    <el-upload
                      class="avatar-uploader"
                      action="''"
                      drag
                      :auto-upload="false"
                      :show-file-list="false"
                      :on-change="handleChangeUpload">
                      <img v-if="ruleForm.cover" :src="ruleForm.cover" class="avatar">
                      <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
              </el-form-item> -->
              
              <!-- <el-form-item label="上传素材">
                <el-upload
                      ref="upload"
                      list-type="picture-card"
                      :on-change="handleFileChange"
                      :file-list="fileListImgs"
                      :auto-upload="false"
                      :multiple="true"
                      >
                      <i class="el-icon-plus"></i>
                    </el-upload>
              </el-form-item> -->
              <el-form-item label="上传附件">
                <el-upload
                  :action="upload_pre+'/api/Cms/Articleupload'"
                  :on-remove="handleRemove"
                  :on-success="handleChange"
                  :file-list="fileList"
                  class=""
                  >
                  <i class="el-icon-plus upload-file"></i>
                  <div slot="tip" class="el-upload__tip">请上传不超过2M的文件</div>
                </el-upload>
                
              </el-form-item>
              
              <el-form-item>
                <el-button type="primary" v-if="type == 'add'" @click="add()">立即发布</el-button>
                <el-button type="primary" v-else @click="edit()">立即修改</el-button>
                <el-button>重置</el-button>
              </el-form-item>
            </el-form>
        </div>
        <my-cropper :showDialog="dialogFaceVisible" :image="cropperImage" @update-logo="handleLogoUpdate" @cancelDialog="closeDialog" :width="cropperWidth"
             :height="cropperHeight"></my-cropper>
    </div>
    
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
    components: { Editor, Toolbar },
    data() {
      return {
		upload_pre: "",
        editor: null,
        title: "发布通知",
        is_bak: false,
        type: "add",
        html: '',
        toolbarConfig: { 
			excludeKeys: [
				'insertImage'
			],
		},
        mode: 'default', // or 'simple'
        chooseCoverIdx: 0,
        cropperImage: "",
        dialogFaceVisible: false,
        cropperWidth: 200,
        cropperHeight: 150,
        fileList: [
        ],
        fileListImgs: [],
        previewFiles: [],
        noticetitle: "",
		gamemode: 1,
        coverList: [
          {
              id: 1,
              img: "http://www.douzhan.club/resources/themes/i/tz/tz_lstz.jpg"
          },
          {
              id: 2,
              img: "http://www.douzhan.club/resources/themes/i/tz/tz_zytz.jpg"
          },
          {
              id: 3,
              img: "http://www.douzhan.club/resources/themes/i/tz/tz_scb.jpg"
          },
          {
              id: 4,
              img: "http://www.douzhan.club/resources/themes/i/tz/tz_ssgc.jpg"
          },
          {
              id: 5,
              img: "http://www.douzhan.club/resources/themes/i/tz/tz_ssjl.jpg"
          }
          
        ],
        rules: {
          name: [
            { required: false, message: '', trigger: 'blur' },
          ],
        },
        editorConfig: {
            placeholder: '请输入正文',
            // autoFocus: false,
            // 所有的菜单配置，都要在 MENU_CONF 属性下
            MENU_CONF: {
                ['uploadImage'] : {
                    fieldName: 'file',
                    server: this.upload_pre+'api/api/Cms/Articleupload',
                    base64LimitSize: 2 * 1024 * 1024,
                    customInsert(res, insertFn) {
                        insertFn(res.data, "", "")
                    },
                },
                ['uploadVideo'] : {
                    fieldName: 'file',
                    server: this.upload_pre+'api/api/Cms/Articleupload',
                    customInsert(res, insertFn) { 
                        insertFn(res.data, "", "")
                    },
                }
            }
        },
        editBody: {
            articleId: 0,
            attach: "",
            content: "",
            title: ""
        }
      };
    },
    created() {
		this.upload_pre = `${window.location.protocol}//www.douzhan.club/`
        let query = this.$route.query
        
        if (query.id) {
            this.type = "edit"
            this.is_bak = true
            this.title = "编辑通知"
            this.editId = query.id
            this.$api.get(`api/Cms/GetArticle/${query.id}`)
            .then(response => {
                if (response.data.code == 0) {
                    this.$message({
                      type: 'error',
                      message: response.data.data
                    });
                    this.$router.push({ path: '/login'});
                    return;
                }
                let reult = response.data.data
                this.editBody = reult
                console.log(this.editBody);
                this.noticetitle = this.editBody.title
                this.html = this.editBody.content
                this.fileList.push({
                    name: "附件",
                    url: this.editBody.attach
                })
            })
            .catch(error => {
              console.error("There was an error!", error);
            });
            
            
        } else {
			this.$api.post('api/Main/GetGame').then(response => {
			    if (response.data.code == 0) {
			        this.$message({
			          type: 'error',
			          message: response.data.data
			        });
			        this.$router.push({ path: '/login'});
			        return;
			    } else {
			        this.gamemode = response.data.data.otype
			    }
			}).catch(error => {
			    console.error("There was an error!", error);
			}) 
		}
    },
    watch: {
        '$route.query.id'(newVal) {  
          // 当路由的查询参数id发生变化时，执行这里的代码  
          if (newVal) {  
            // 如果新值存在，则进入编辑模式  
            this.type = "edit";  
            this.title = "编辑通知";  
            this.editId = newVal;  
            this.is_bak = true
            // 你可以在这里根据id获取球队信息并填充到form中  
            // 例如，你可以发起一个API请求来获取球队信息  
          } else {  
            // 如果新值为空，则可能进入添加模式或其他默认模式  
            this.type = "add"; // 或设置为默认模式  
            this.title = "发布通知 — 发布信息后,将通知所有注册球员、裁判和关注用户"; // 或设置为默认标题  
            this.editId = null;  
            this.is_bak = false
            // 重置form到初始状态或添加模式的默认状态  
            this.resetForm()
          }  
        },  
    },
    mounted() {

    },
    beforeDestroy() {
        // 销毁编辑器
        this.editor.destroy();
        this.editor = null;
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        add() {
            let body = this.editBody
            if (body.attach == "") {
                delete body.attach;
            }
            this.$confirm('确认发布通知?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                
                this.$api.post('api/Cms/SaveArticle', body ,{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (response.data.code == 1) {
                        this.$message({
                            type: 'success',
                            message: '发布成功!'
                        });
                        this.$router.push({ path: '/listnotice'});
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                    }
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
                
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        edit() {
            console.log(this.editBody)
            this.$confirm('确认更新通知?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.post('api/Cms/SaveArticle', this.editBody ,{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (response.data.code == 1) {
                        this.$message({
                            type: 'success',
                            message: '更新成功!'
                        });
                        this.$router.push({ path: '/listnotice'});
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        resetForm() {
            this.noticetitle = ""
            this.html = ""
            this.fileList = []
        },
        handleChangeUpload(file) {
          const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isJPG) {
              this.$message.error('上传图片只能是 JPG/PNG 格式!');
              return false
          }
          if (!isLt2M) {
              this.$message.error('上传图片大小不能超过 2MB!');
              return false
          }
          // 上传成功后将图片地址赋值给裁剪框显示图片
          this.$nextTick(async () => {
              // base64方式
              this.cropperImage = URL.createObjectURL(file.raw)
              this.loading = false
              this.dialogFaceVisible = true
          })
        },
        handleLogoUpdate(croppedImageUrl) {
            this.ruleForm.cover = croppedImageUrl;
            this.chooseCoverIdx = -1
        },
        closeDialog() {
            this.dialogFaceVisible = false
        },
        chooseCover(index) {
            this.chooseCoverIdx = index
            this.ruleForm.cover = this.coverList[index].img
        },
        handleChange(file,) {
            this.editBody.attach = file.data
            console.log(this.editBody)
        },
        handleRemove(file, fileList) {
            this.editBody.attach = ""
            console.log(file)
            console.log(fileList)
            console.log(this.editBody)
        },
        // handleFileChange(file) {
        //   this.createPreview(file.raw);
        // },
        // createPreview(file) {
        //   // 读取文件并创建预览
        //   const reader = new FileReader();
        //   reader.onload = (e) => {
        //     this.previewFiles.push({
        //       name: file.name,
        //       url: e.target.result,
        //       type: file.type
        //     });
        //   };
        //   reader.readAsDataURL(file);
        // },
        handleFileChange(file) {
            // 检查文件类型
            if(file.raw.type.startsWith('video')) {
              // 如果是视频文件，设置为视频图标
              file.url = require('@/assets/images/video.png'); // 请替换为实际的视频图标URL
            } else {
              // 处理其他类型文件的逻辑（如果有必要）
            }
            // 更新文件列表
            this.updateFileList(file);
          },
        
        updateFileList(file) {
            // 这里用于更新fileList，确保之前的文件不会丢失
            // 注意：这个方法假设你有一个名为 fileList 的data属性
            // 请根据你的实际情况做相应调整
            if (!this.fileListImgs.find(f => f.uid === file.uid)) {
              this.fileListImgs.push(file);
            }
            // 更新Vue响应式数据
            this.$set(this, 'fileListImgs', [...this.fileListImgs]);
        },
        
        
    }
  }
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style  lang='less' scoped>
    
    .main-box {
        padding: 20px 30px ;
        
    }
    
    .demo-ruleForm {
        /* width: 900px; */
        margin: 0 auto;
    }
    
    .avatar {
        width: 150px;
        height: 110px;
        display: block;
    }
    
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 150px;
        height: 110px;
        line-height: 110px;
        text-align: center;
    }
    
    
    
    
    .cover-box {
        display: flex;
        justify-content: flex-start;
    }
    
    .cover-items {
        width: 150px;
        height: 110px;
        position: relative;
        margin-right: 10px;
        cursor: pointer;
        overflow: hidden;
    }
    
    .check-icon {
        position: absolute;
        top: -2px;
        right: -2px;
    }
    
    .check-icon img{
        width: 35px;
        height: 35px;
    }
    
    
    
    .cover-items-img {
        width: 100%;
        height: 100%;
    }
    
    .imgs-item {
        display: flex;
        justify-content: flex-start;
    }
    
    .cover-box-img {
        width: 150px;
        height: 110px;
        margin-bottom: 10px;
    }
    
    .cover-box-img img{
        width: 100%;
        height: 100%;
    }
    
    .active_item {
        /* box-shadow: 0px 0px 0px 3px #02a7ff; */
        /* border: 1px solid #02a7ff; */
    }
    
    .notice-title {
        height: 80px;
        line-height: 80px;
        border-bottom: 1px solid #ccc;
        
    }
    
    .notice-title {
        :deep(.el-input__inner) {
            border: 0px;
            font-size: 26px;
        }
    }
    
    .upload-file {
        width: 80px;
        height: 80px;
        border: 1px dashed #ccc;
        text-align: center;
        line-height: 80px;
        font-size: 26px;
    }
	
	/deep/ .el-upload-list__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	/deep/ .el-upload-list__item-name {
		display: flex;
		align-items: center;
	}
	
	.el-icon-document {
		font-size: 80px;
	}
	
	/deep/ .el-upload-list__item-status-label {
	    position: unset;
	}
	
	/deep/ .el-icon-close {
		position: unset;
	}
	
	.mint-header {
		background-color: #fff;
		color: #000;
	}
	
	.mint-header-title {
		color: #000;
	}
</style>