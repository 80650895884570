<template>
    <div class="content">
        <mt-header title="管理球队">
            <router-link to="/menu" slot="left" style="background-color: #fff;border: #fff;">
                <mt-button icon="back">返回</mt-button>
            </router-link>
        </mt-header>
		<!-- <el-button class="exportExcel" :type="is_signup == 0 ? 'danger' : 'success' "  @click="changeSignup(is_signup)">{{is_signup == 0 ? "所有队伍报名截止" : "所有队伍报名开启"}}</el-button> -->
        <div class="main-box">
            <div id="tags-container">   
				<div
				  v-for="tag in orderedTags"
				  :key="tag"
				  class="tag"
				  :class="{ active: activeTag === tag }"
				  @click.prevent="setActiveTag(tag)"
				>
				  {{ tag.indexOf("null") !== -1 ?  tag.split('/')[1] : tag }}
				  
				</div>
            </div>
            <div class="scroll-box">
              <div class="table-item" v-for="(item,index) in tableData" :key="index">
				  <router-link :to="`/mplayer/${item.name}/${item.id}`">
				  <div class="table-item-top">
					  <div class="table-item-top-tag" v-show="item.playerage != 0 || item.playerminage != 0">年龄限制 {{item.playerage}}岁</div>
					  <div class="table-item-top-tag" style="background-color: #FF5555;" v-show="item.isJoin == 1">截止报名</div>
				  </div>
				  <div class="table-item-main">
					  
					  <div class="table-item-team">
						  <img :src="item.logo || defaultlogo" alt="" class="table-item-team-img" />
						  <div class="table-item-team-grade">
							  <div class="table-item-team-grade-home">{{item.winnum}}</div>
							  <div class="table-item-team-grade-guest">{{item.gamenum - item.winnum}}</div>
						  </div>
					  </div>
					  <div class="table-item-team-info-main">
					  		<span class="table-item-team-name">{{item.name}}</span>
					  		<span class="table-item-team-info">{{item.shortnick}}·{{item.playernum}}人·{{item.integral}}积分</span>
					  </div>
					  
				  </div>
				  </router-link>
				  <i class="iconfont icon-gengduo2" @click="showmenu(item.id)"></i>
				  <span class="table-item-team-group"> <span class="team-tag" v-if="item.teamtag !== '全部'">{{item.teamtag}} / </span>
                    <span class="team-group" v-if="item.teamgroup">{{item.teamgroup}} </span>
                    <span class="team-seq" v-if="item.seq !== '' && item.seq !== 0">/ {{item.seq}}</span>组</span>
			  </div>
				<div class="footer-page">
				    <el-pagination
				      background
					  :current-page="pageIndex"
				      layout="prev, pager, next"
				      :total="total"
				      :page-size="pageSize"
				      @current-change="changepage"
				      :hide-on-single-page="true"
				      >
				    </el-pagination>
				</div>
			</div>
			<mt-actionsheet
			  :actions="actions"
			  v-model="sheetVisible">
			</mt-actionsheet>
			<template slot-scope="scope">
			    <el-button size="mini" @click.stop="stopReg(scope.row.id)" :style="scope.row.isJoin === 0 ? '' : 'border: 1px solid red'">{{ scope.row.isJoin === 0 ? '队伍截止报名' : '队伍开启报名'}}</el-button>
			    <el-button size="mini" @click.stop="setAgelimit(scope.row.id)">年龄限制</el-button>
			    <el-button size="mini" @click.stop="setGroup(scope.row.id)">分组</el-button>
			    <el-dropdown trigger="click" placement="bottom">
			          <span class="el-dropdown-link">
			            <i class="el-icon-more el-icon--center" style="font-size: 20px;cursor:pointer"></i>
			          </span>
			          <el-dropdown-menu slot="dropdown">
			            <el-dropdown-item icon="el-icon-sort" @click.native="orderTeam(scope.row.id)">排序</el-dropdown-item>
			            <el-dropdown-item icon="el-icon-edit" @click.native="edit(scope.row.id)">编辑</el-dropdown-item>
			            <el-dropdown-item icon="el-icon-delete" style="color: #f00;" @click.native="del(scope.row.id)">删除</el-dropdown-item>
			          </el-dropdown-menu>
			    </el-dropdown>
			    
			</template>
            
            <el-dialog title="年龄限制" :close-on-click-modal='false' width="80%" :visible.sync="dialogFormVisible">
                <el-row>
				<el-col :span="24" style="text-align: left;margin-top: 50px;margin-left: 30px;">
					<el-checkbox v-model="isChecked" label="无限制" border @change="setZeroAge()"></el-checkbox>
				</el-col>
                  <el-col :span="24">
					  <el-slider
						class="slideBox"
						@change="setAge($event)"
						 :min="4"
						 :max="70"
						 show-stops
						 :marks="marks"
						v-model="value">
						</el-slider>
                  </el-col>
                  <el-col :span="24" style="text-align: left;margin-top: 50px;margin-left: 30px;" v-if="this.editForm.playerage > 0">
                        <el-date-picker
                            v-model="editForm.ageMonth"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                        &emsp;及以后出生可报名
                  </el-col>
                </el-row>
                
               
              <div slot="footer" class="dialog-footer">
                <el-button @click="cancelAge()">取 消</el-button>
                <el-button type="primary" @click="applyAge()">确 定</el-button>
              </div>
            </el-dialog>
            <el-dialog title="设定分组" :visible.sync="dialogGroupVisible" width="80%">
                <el-select v-model="editForm.teamtag" placeholder="请选择" style="margin-left: 20px;margin-bottom: 10px;">
                    <el-option
                      v-for="item in options1"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                      >
                    </el-option>
                </el-select>
                <el-select v-model="editForm.teamgroup" placeholder="请选择" style="margin-left: 20px;">
                    <el-option
                      v-for="item in options2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                      >
                    </el-option>
                </el-select>
              <div slot="footer" class="dialog-footer">
                <el-button @click="cancelAge()">取 消</el-button>
                <el-button type="primary" @click="applyGroup()">保存分组</el-button>
              </div>
            </el-dialog>
            <previewPage :url="preimg" :show_pre.sync="is_show"></previewPage>
            <v-tour name="myTour" :steps="steps" :options="myOptions" @onStop="onFinishStep"></v-tour>
        </div>
        
    </div>
</template>

<script>
	import { MessageBox } from 'mint-ui';
    export default {
      data() {
		  let that = this
        return {
            // steps: [
            //     {
            //         target: '.draggable-container',  // We're using document.querySelector() under the hood
            //         content: `标签可以拖动喔`,
            //         params: {
            //           placement: 'left',
            //         },
            //     },
            // ],
            // myOptions:{
            //     dialogVisible: false,
            //     useKeyboardNavigation: false,
            //     labels: {
            //         buttonSkip: '跳过',
            //         buttonPrevious: '上一步',
            //         buttonNext: '下一步',
            //         buttonStop: '我知道了'
            //     }
            // },
            total: 0,
            pageSize: 10,
            pageIndex: 1,
            preimg: "",
            is_show: false,
            value: 0,
            marks: {
                4: '4岁',
                10: '10岁',
                20: '20岁',
                30: '30岁',
                40: '40岁',
                50: '50岁',
                60: '60岁',
                70: '70岁',
            },
            chooseId: 0,
            minAge: 0,
            maxAge: 0,
            dialogFormVisible: false,
            dialogGroupVisible: false,
            dialogTableVisible: false,
            dialogFaceVisible: false,
            cropperImage: "",
            dialogAddVisible: false,
            isPreview: false,
            dialogVisible: false,
            memberType: "add",
            activeTag: '全部',
            teamtag: "",
            teamgroup: "",
            options1: [{
                  value: '1',
                  label: '全部'
                }, {
                  value: '2',
                  label: '甲组'
                }, {
                  value: '3',
                  label: '乙组'
                }, {
                  value: '4',
                  label: '公开组'
                }, {
                  value: '5',
                  label: '幼儿组'
                }, {
                  value: '6',
                  label: '未成年组'
                }, {
                  value: '7',
                  label: '成年组'
                }, {
                  value: '8',
                  label: '青少年组'
                }, {
                  value: '9',
                  label: '青壮年组'
                }, {
                  value: '10',
                  label: '壮年组'
                }, {
                  value: '11',
                  label: '老年组'
                }, {
                  value: '12',
                  label: '小学组'
                }, {
                  value: '13',
                  label: '初中组'
                }, {
                  value: '14',
                  label: '高中组'
                }, {
                  value: '15',
                  label: '女篮'
                }, {
                  value: '16',
                  label: '小学女篮'
                }, {
                  value: '17',
                  label: '初中女篮'
                }, {
                  value: '18',
                  label: '高中女篮'
                }, {
                  value: '19',
                  label: 'U6'
                }, {
                  value: '20',
                  label: 'U7'
                }, {
                  value: '21',
                  label: 'U8'
                }, {
                  value: '22',
                  label: 'U9'
                }, {
                  value: '23',
                  label: 'U10'
                }, {
                  value: '24',
                  label: 'U11'
                }, {
                  value: '25',
                  label: 'U12'
                }, {
                  value: '26',
                  label: 'U13'
                }, {
                  value: '27',
                  label: 'U14'
                }, {
                  value: '28',
                  label: 'U15'
                },  {
                  value: '29',
                  label: 'U16'
                }
            ],
            options2: [{
                  value: '1',
                  label: 'A'
                }, {
                  value: '2',
                  label: 'B'
                }, {
                  value: '3',
                  label: 'C'
                }, {
                  value: '4',
                  label: 'D'
                }, {
                  value: '5',
                  label: 'E'
                },
                {
                  value: '6',
                  label: 'F'
                },
                {
                  value: '7',
                  label: 'G'
                },
                {
                  value: '8',
                  label: 'H'
                },
                {
                  value: '9',
                  label: 'I'
                },
				{
				  value: '10',
				  label: 'J'
				},
				{
				  value: '11',
				  label: 'K'
				},
				{
				  value: '12',
				  label: 'L'
				},
				{
				  value: '13',
				  label: 'M'
				},
				{
				  value: '14',
				  label: 'N'
				},
				{
				  value: '15',
				  label: 'O'
				},
				{
				  value: '16',
				  label: 'P'
				},
				{
				  value: '17',
				  label: 'Q'
				},
				{
				  value: '18',
				  label: 'R'
				},
				{
				  value: '19',
				  label: 'S'
				},
				{
				  value: '20',
				  label: 'T'
				},
				{
				  value: '21',
				  label: 'U'
				},
				{
				  value: '22',
				  label: 'V'
				},
				{
				  value: '23',
				  label: 'W'
				},
				{
				  value: '24',
				  label: 'X'
				},
				{
				  value: '25',
				  label: 'Y'
				},
				{
				  value: '26',
				  label: 'Z'
				},
            ],
            options3: [{
                  value: '0',
                  label: '0'
                }, {
                  value: '1',
                  label: '1'
                }, {
                  value: '2',
                  label: '2'
                }, {
                  value: '3',
                  label: '3'
                }, {
                  value: '4',
                  label: '4'
            }],
            tableData: [],
            tableTagData: [],           
            editForm: [{
                ageMonth: '',
				playerage: 0,
				playerminage: 0
            }],
            defaultlogo: require('@/assets/images/defaultlogo.jpg'),
            orderedTags: [],
			is_signup: 0,
			searchKey: "",
			isChecked: true,
			gamemode: 1,
			sheetVisible: false,
			actions: [
				{
					name: '队伍截止报名',
					method: that.stopReg
				},
				{
					name: '年龄限制',
					method: that.setAgelimit
				},
				{
					name: '分组',
					method: that.setGroup
				},
				{
					name: '排序',
					method: that.orderTeam
				},
				{
					name: '编辑',
					method: that.edit
				},
				{
					name: '删除',
					method: that.del
				}
			]
        }
    },
    mounted: function () {
        console.log(localStorage.getItem('tips_show'))
		
          if (localStorage.getItem('tips_show') == 'true') {
              this.$tours['myTour'].start()
              localStorage.setItem('tips_show', false)
          }
    },
	watch: {
	    editForm: {
	      handler() {
	        this.isChecked = this.editForm.playerage === 0;
	      },
	      deep: true,
	    },
	},
    computed: {
        uniqueTags() {
          // const tagSet = new Set(this.tableTagData
          //   .map(item => `${item.teamtag}/${item.teamgroup}`));
          // return ['全部', ...Array.from(tagSet)];
          
          const tagSet = new Set(this.tableTagData
            .map(item => {
              if (item.teamtag === null || item.teamtag === undefined || item.teamtag === '全部') {
                return `${item.teamgroup}组`;
              } else {
                return `${item.teamtag}/${item.teamgroup}组`;
              }
            })
          );
          return ['全部', ...Array.from(tagSet)];
        },
        filteredTableData() {
          if (this.activeTag === '全部') {
            return this.tableTagData;
          } else {
            return this.tableTagData.filter(item => {
              const tag = `${item.teamtag}/${item.teamgroup}`;
              return tag === this.activeTag;
            });
          }
        }
    },
    created() {
        
		this.pageIndex = Number(localStorage.getItem('teamPageIndex')) || 1;
		this.$api.post('api/Main/GetGame').then(response => {
		    if (response.data.code == 0) {
		        this.$message({
		          type: 'error',
		          message: response.data.data
		        });
		        this.$router.push({ path: '/mlogin'});
		        return;
		    }
		    this.is_signup = response.data.data.isSignup
		    this.gamemode = response.data.data.otype
		}).catch(error => {
		    console.error("There was an error!", error);
		}) 
		let query = this.$route.query
		if (query.group) {
			this.setActiveTag(query.group)
			const newQuery = { ...query };
			delete newQuery.group;
			this.$router.replace({ query: newQuery });
		} else {
			this.fetchData()
			
		}
		this.fetchTagData()
		
       
        
    },
	beforeRouteLeave(to, from, next) {
	    // 在跳转前判断目标路由
	    if (to.path !== '/createTeam' && !to.path.startsWith('/teammembers/')) {
	       localStorage.setItem('teamPageIndex', 1);
	    }
	    next();
	},
    methods: {
        fetchData() {

            let params = {}
            if ((this.teamtag == "null" || this.teamtag == null || this.teamtag == "") && this.teamgroup == "") {
                params = {
                    PageIndex: this.pageIndex,
                    limit: this.pageSize,
                    teamgroup: this.teamgroup.slice(0,-1),
					search: this.searchKey
                }
            }else if((this.teamtag == "null" || this.teamtag == null || this.teamtag == "") && this.teamgroup != "") {
				params = {
				    PageIndex: 1,
				    limit: -1,
					teamtag: '全部',
				    teamgroup: this.teamgroup.slice(0,-1),
					search: this.searchKey
				}
			}else {
                params = {
                  PageIndex: 1,
                  limit: -1,
                  teamtag: this.teamtag,
                  teamgroup: this.teamgroup.slice(0,-1),
				  search: this.searchKey
                }
            }
            this.$api.get('api/Team/Teams', {
              params: params
            })
            .then(response => {
                if (response.data.code == 0) {
                    this.$message({
                      type: 'error',
                      message: response.data.data
                    });
                    this.$router.push({ path: '/login'});
                    return;
                }
                
                this.tableData = response.data.rows
                this.tableData = this.tableData.map(item => {
                  this.$set(item, 'playerboth', '');
                  return item;
                });
                this.total = response.data.total
                this.pageSize = response.data.PageSize
				
              
            })
            .catch(error => {
              console.error("There was an error!", error);
            });
        
        },
        fetchTagData() {
            this.$api.get('api/Team/Teams', {
              params: {
                PageIndex: 1,
                limit: -1
              }
            })
            .then(response => {
                this.tableTagData = response.data.rows.map(item => ({
                    'teamtag': item.teamtag,
                    'teamgroup': item.teamgroup
                }));
                this.initializeOrderedTags()
                // this.orderedTags = this.uniqueTags
            })
            .catch(error => {
              console.error("There was an error!", error);
            });
        },
        getTeamData(id) {
            this.$api.get(`api/Team/GetTeam/${id}`)
            .then(response => {
                let reult = response.data.data
                this.editForm = reult
                this.value = reult.playerage
            })
            .catch(error => {
              console.error("There was an error!", error);
            }); 
        },
        saveTeamData() {
			// if(this.editForm.teamtag === '全部') {
			// 	this.editForm.teamtag = null
			// }
            this.$api.post('api/Team/SaveTeam', this.editForm ,{
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.data.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    this.dialogFormVisible = false
                    this.dialogGroupVisible = false
                    this.fetchData()
                    this.fetchTagData()
                } else {
                    this.$message({
                        type: 'error',
                        message: response.data.data
                    });
                    this.$router.push({ path: '/login'});
                }
            })
            .catch(error => {
              console.error("There was an error!", error);
            });
        },
        changepage(p) {
            this.pageIndex = p
			localStorage.setItem('teamPageIndex', p);
            this.fetchData()
        },
        stopReg() {
			const id = this.chooseId
            this.getTeamData(id)
            let message = ""
            let item = this.tableData.find(item => item.id === id)
            if (item.isJoin === 0) {
                message = '确定要单独截止此球队报名吗?'
            } else {
                message = '确定要单独开启此球队报名吗?'
            }
            MessageBox.confirm(message).then(() => {
                this.editForm.isJoin = Math.abs(this.editForm.isJoin - 1)
                this.saveTeamData()
                item.isJoin  = Math.abs(item.isJoin - 1)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        setAgelimit() {
			const id = this.chooseId
            this.dialogFormVisible = true
            this.chooseId = id
            this.getTeamData(id)
        },
        setAge(val) {
			console.log('年龄选择',val)
            this.editForm.playerage = val
			
			if (val > 0) {
				const currentYear = new Date().getFullYear();
				this.editForm.ageMonth = (currentYear - val)+"-01-01"
			} else {
				this.editForm.ageMonth = ""
			}
			this.$forceUpdate()

			
			
        },
        applyAge() {
			this.dialogFormVisible = false
            let msg = ''
            let ageMsg = ''
            if (this.editForm.ageMonth == "" || this.editForm.ageMonth == null) {
                ageMsg = "出生日期设为无限制"
            } else {
                ageMsg = "出生日期限制为"+this.editForm.ageMonth+"之后"
            }
            if(this.editForm.playerage == 0) {
                msg = '确定要将此球队年龄设定无限制，'+ageMsg+'吗?'
            }else{
                msg = '确定要将此球队年龄限制为'+this.editForm.playerage+'岁<br>'+ageMsg+'吗?'
            }
             MessageBox.confirm(msg).then(() => {
                this.saveTeamData()
                let item = this.tableData.find(item => item.id === this.editForm.id)
                item.playerage = this.editForm.playerage
                item.playerboth = this.editForm.ageMonth
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
		setZeroAge() {
			this.value = 0
			this.editForm.playerage = 0
			this.editForm.ageMonth = ""
		},
        cancelAge() {
            this.dialogFormVisible = false
            this.dialogGroupVisible = false
        },
        setGroup() {
			const id = this.chooseId
            this.getTeamData(id)
            this.dialogGroupVisible = true
            this.chooseId = id
			
        },
        applyGroup() {
            if (!this.editForm.teamgroup) {
                this.$message({
                  showClose: true,
                  message: '不能为空',
                  type: 'error'
                });
                return;
            }
            this.saveTeamData()
			
            // this.$confirm('确定要将此球队分组设定为【'+this.editForm.teamtag+' / '+this.editForm.teamgroup+' / '+this.editForm.seq+'】吗?', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }).then(() => {
            //     this.saveTeamData()
            // }).catch(() => {
            //     this.$message({
            //         type: 'info',
            //         message: '已取消操作'
            //     });          
            // });
            
        },
        orderTeam() {
			const id = this.chooseId
            this.getTeamData(id)
            MessageBox.prompt('数字越大，位置越靠前').then(({ value }) => {
                this.editForm.seq = value
                this.saveTeamData()
                let item = this.tableData.find(item => item.id === id)
                item.seq = value
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消设置'
                });       
            });
        },
        del() {
			const id = this.chooseId
            MessageBox.confirm('确定要将此球队删除吗?').then(() => {
               this.$api.get(`api/Team/DelTeam/${id}`)
               .then(response => {
                   if(response.data.code == 1) {
                       
                       this.tableData = this.tableData.filter(item => item.id !== id)
                       
                       this.$message({
                           type: 'success',
                           message: '删除成功!'
                       });
                       this.fetchData()
                   } else {
                       this.$message({
                           type: 'error',
                           message: response.data.data
                       });
                   }
                 
               })
               .catch(error => {
                 console.error("There was an error!", error);
               });
               
               
           }).catch(() => {
               this.$message({
                   type: 'info',
                   message: '已取消删除'
               });          
           }); 
        },
        edit() {
			const id = this.chooseId
            this.$router.push({ path: '/mcreate', query: { id:  id,group: this.activeTag} });
        },
        setActiveTag(tag) {
			console.log("标签：",tag)
            this.activeTag = tag; // 设置当前激活的标签
            let tagarr = tag.split('/');
			console.log("标签2：",tagarr)
            this.teamtag = tagarr[0]
            this.teamgroup = tagarr[1]
            if (this.teamgroup == null) {
                this.teamtag = ""
                this.teamgroup = tag
            }
            if (tag === "全部") {
                this.teamtag = ""
                this.teamgroup = ""
            }
            this.pageIndex = 1
            this.fetchData()
        },
        showPre(url) {
            this.preimg = url
            this.is_show = true
        },
        onDragEnd() {
            localStorage.setItem('tagOrder', JSON.stringify(this.orderedTags));
        },
        initializeOrderedTags() {
            const savedOrder = this.loadOrderFromStorage();
            console.log("保存排序",savedOrder)
            if (savedOrder && savedOrder.length > 0) {
              // 先过滤出在savedOrder中存在的标签
              const ordered = savedOrder.filter(tag => this.uniqueTags.includes(tag));
              
              // 过滤出新标签列表中存在、但不在savedOrder中的标签
              const newTags = this.uniqueTags.filter(tag => !savedOrder.includes(tag));
              
              // 合并两个数组，保持旧标签的顺序，新标签排在后面
              this.orderedTags = [...ordered, ...newTags];
              console.log("有",ordered)
              console.log("有",newTags)
            } else {
              // 如果没有保存的顺序，直接使用uniqueTags的顺序
              this.orderedTags = this.uniqueTags;
              console.log("没有")
            }
            console.log("实际排序",this.orderedTags)
        },
        loadOrderFromStorage() {
            const order = localStorage.getItem('tagOrder');
            return order ? JSON.parse(order) : null;
        },
        onFinishStep() {
            console.log("dianjile")
        },
		changeSignup(t) {
		    let url = ""
		    if (t == 0) {
		        url = "api/Main/StopSignup"
		    } else {
		        url = "api/Main/StartSignup"
		    }
		    this.$api.post(url).then(response => {
		        if (response.data.code == 1) {
		            this.is_signup = Math.abs(this.is_signup - 1)
		            this.$message({
		              type: 'success',
		              message: response.data.data
		            });
		        } else {
		            this.$message({
		                type: 'error',
		                message: response.data.data
		           });
		        } 
		    })
		    .catch(error => {
		      console.error("There was an error!", error);
		    });
		},
		showmenu(id) {
			this.chooseId = id
			this.sheetVisible = true
			const team = this.tableData.find(item=>item.id === id)
			team.isJoin == 1 ? this.actions[0].name = '队伍开启报名' : this.actions[0].name = '队伍截止报名'
			console.log(this.actions)
		}
		
        
      }
    }
  </script>

<style scoped>
	
	.search-box {
	    position: absolute;
	    top: 20px;
	    right: 60px;
		width: 250px;
		height: 40px;
	}
	
	::v-deep .el-input__inner {
	  background: #f0f0f0;
	  border-radius: 40px;
	  border: 0px;
	}
	
    .team-logo-box {
        display: flex;
        padding-left: 20px;
    }
    
    .team-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        line-height: 30px;
        text-align: left;
    }
    
    .team-info-name {
        font-size: 18px;
        font-weight: bold;
    }
    
    .team-info-score {
        text-align: left;
        font-size: 14px;
    }
    
    .score-win {
        font-size: 18px;
        font-weight: bold;
        color: #039f2f;
        mmargin-right: 5px;
    }
    .score-lose {
        font-size: 18px;
        font-weight: bold;
        color: #e80000;
        mmargin-right: 5px;
    }
    
    .el-table .cell {
      font-size: 16px; /* 调整字体大小 */
    }
    
    .el-icon-more {
        font-size: 22px;
        cursor: pointer;
        margin-left: 20px;
    }
    
    .el-icon-more:hover {
        color: black;
    }
    
    .el-table__cell {
        height: 80px;
    }
    
    .slideBox {
        width: 90%;
        margin: 0 auto;
    }
    
    .el-scrollbar__wrap {
        overflow-x: hidden!important;
    }
    
    .footer-page {
        width: 100%;
        height: 60px;
        line-height: 60px;
        margin-top: 30px;
        text-align: center;
    }
    
    .members_box {
        height: 500px;
        overflow-y: scroll;
    }
    
    #app {
        text-align: left;
    }
    .redFont {
        color: red;
    }
    
    .greenFont {
        color: green;
    }
    
    #tags-container {
      display: flex;
      background: transparent; /* 设置父元素的背景色 */
      border-bottom: 1px solid #ddd; /* 父元素底部边框线 */
      padding-left: 0;
      margin: 0;
      list-style: none;
      position: relative;
      margin-top: 10px;
	  flex-wrap: wrap;
    }
    
    .tag {
      font-size: 14px;
      color: #333; /* 标签文字颜色 */
      background: #f0f0f0; /* 未选中的标签背景色 */
      padding: 8px 16px; /* 根据实际需要调整内间距 */
      cursor: pointer;
      transition: background-color 0.3s ease; /* 平滑背景色变化 */
      width: auto;
      height: 20px;
      position: relative;
      bottom: -6px;
	  z-index: 9990;
    }
    
    /* 未选中的标签样式 */
    .tag:not(.active) {
      transform: scale(1); /* 未选中的标签稍微缩小 */
      border: 1px solid transparent; /* 防止布局抖动 */
    }
    
    /* 选中的标签样式 */
    .tag.active {
      position: relative;
      background: #fff; /* 选中的标签背景色 */
      border-top: 1px solid #ddd; /* 选中标签上边框 */
      border-left: 1px solid #ddd; /* 选中标签左边框 */
      border-right: 1px solid #ddd; /* 选中标签右边框 */
      border-bottom: none; /* 移除选中标签底部边框 */
      transform: scale(1); /* 恢复标签正常大小 */
      z-index: 1; /* 确保边框不被其他元素覆盖 */
      bottom: -1px;
      height: 25px;
    }
    
    .draggable-container {
      display: flex;
      flex-wrap: wrap;
    }
	
	.exportExcel {
	    position: absolute;
	    top: 20px;
	    right: 20px;
	}
	
	.content {
	    position: relative;
	}
	
	/deep/ .el-table__row {
		cursor: pointer;
	}
	
	/deep/ .el-slider__marks-text:last-child {
		width: 50%!important;
	}
	
	.table-item {
		width: 100%;
		height: 100px;
		border-bottom: 1px solid #ccc;
		position: relative;
		padding-bottom: 10px;
		
	}
	
	.table-item-top {
		display: flex;
		justify-content: right;
		padding: 10px;
		min-height: 20px;
	}
	
	.table-item-top-tag {
		background-color: #559BFF;
		padding: 5px 10px;
		font-size: 12px;
		color: #fff;
		border-radius: 5px;
		margin-left: 5px;
	}
	
	.table-item-main {
		width: 100%;
		display: flex;
	}
	
	.table-item-team {
		display: flex;
		margin-left: 30px;
		margin-top: -10px;
	}
	
	.table-item-team-img {
		width: 60px;
	}
	
	.table-item-team-grade-home {
		width: 20px;
		height: 30px;
		background-color: #559BFF;
		color: #fff;
		text-align: center;
		line-height: 30px;
		
	}
	
	.table-item-team-grade-guest {
		width: 20px;
		height: 30px;
		background-color: red;
		color: #fff;
		text-align: center;
		line-height: 30px;
	}
	
	.table-item-team-info-main {
		display: flex;
		flex-direction: column;
		justify-content: left;
		align-items: left;
		text-align: left;
		margin-top: -10px;
		margin-left: 20px;
	}
	
	.table-item-team-name {
		margin-bottom: 10px;
		font-size: 16px;
		font-weight: bold;
		margin-top: 5px;
	}
	
	.table-item-team-info {
		font-size: 14px;
		color: #818181;
	}
	
	.icon-gengduo2 {
		position: absolute;
		right: 10px;
		top: 50px;
	}
	
	.table-item-team-group {
		position: absolute;
		right: 10px;
		top: 70px;
		font-size: 13px;
	}
	
	.scroll-box {
		height: calc(100vh - 120px);
		overflow-y: scroll;
	}
	
	.mint-header {
		background-color: #fff;
		color: #000;
	}
	
	.mint-header-title {
		color: #000;
	}
	
	
	
    







    
</style>