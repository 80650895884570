<template>
	<div class="content">
		<div class="nav-box">
			<div class="nav-box-header">
				<img :src="defaultLogo" alt="" />
			</div>
			<div class="nav-box-search">
				<i class="iconfont icon-sousuo"></i>
				<input placeholder="搜你想要的" type="text" />
			</div>
			<div class="nav-box-add">
				<i class="iconfont icon-add-s"></i>
				<span>发布</span>
			</div>
		</div>
		<div class="fans-box">
			<span class="fans-box-title">我的关注</span>
			<div class="fans-box-list">
				<div class="fans-box-list-item" v-for="(item,index) in fansList" :key="index">
					<img class="fans-box-list-item-face" :src="defaultLogo" alt="" />
					<span class="fans-box-list-item-name">{{item.name}}</span>
				</div>
			</div>
		</div>
		<div class="page-btns">
			<div class="page-btns-item" :class="{'choose-btn' : pageIdx == 0}" @click="pageIdx = 0">
				人气最高
			</div>
			<div class="page-btns-item" :class="{'choose-btn' : pageIdx == 1}" @click="pageIdx = 1">
				收藏最多
			</div>
			<div class="page-btns-item" :class="{'choose-btn' : pageIdx == 2}" @click="pageIdx = 2">
				最新发布
			</div>
		</div>
		<div class="page-main">
			<div class="bottom_view_box_item grid-container board-grid-container">
				<div class="bottom_view_like" v-for="(item,index) in boardList" :key="index">
					<img src="@/assets/images/board.jpg" alt="" />
					<div class="like-hot-box">
						<div class="like-box">
							<i class="iconfont icon-shoucang1"></i>
							<span>3.6万</span>
						</div>
						<div class="hot-box">
							<i class="iconfont icon-redu"></i>
							<span>1128</span>
						</div>
					</div>
					<div class="like_box-info">
						<img :src="item.face || defaultLogo" alt="">
						<div class="bottom_view_like_info">
							<span class="like-title">{{item.title}}</span>
							<span class="like-user">{{item.name || 制作者}}</span>
						</div>
						<i class="iconfont icon-gengduo1" @click.stop="isMyBoardMenu = true;"></i>
					</div>
				</div>
				<div class="empty-box" v-if="boardList.length == 0">
					还没有战术板~
				</div>
			</div>
		</div>
		<el-drawer title="我的战术版设置" :visible.sync="isMyBoardMenu" direction="btt" :with-header="false" size="15%"
			class="board_menu_box">
			<div class="board_menu_box_main">
				<div class="board_menu_box_item" @click="delTatic()">
					<i class="iconfont icon-shoucang2"></i>
					<span>收藏</span>
				</div>
				<div class="board_menu_box_item">
					<i class="iconfont icon-fenxiang"></i>
					<span>分享</span>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isMyBoardMenu: false,
				defaultLogo: require('@/assets/images/defaultlogo.jpg'),
				pageIdx: 0,
				fansList: [
					{
						id: 1,
						name: "粉丝一",
					},
					{
						id: 2,
						name: "粉丝二",
					},
					{
						id: 3,
						name: "粉丝三粉丝三粉丝三",
					},
					{
						id: 4,
						name: "粉丝四",
					},
					{
						id: 5,
						name: "粉丝五粉丝五粉丝五",
					},
					{
						id: 6,
						name: "粉丝六",
					}
				],
				boardList: [
					{
						id: 1,
						title: '战术板1',
						name: "粉丝一",
					},
					{
						id: 2,
						title: '战术板2',
						name: "粉丝二",
					},
					{
						id: 3,
						title: '战术板3',
						name: "粉丝三粉丝三粉丝三",
					},
					{
						id: 4,
						title: '战术板4',
						name: "粉丝四",
					},
					{
						id: 5,
						title: '战术板5',
						name: "粉丝五粉丝五粉丝五",
					},
					{
						id: 6,
						title: '战术板6',
						name: "粉丝六",
					}
				]
			}
		}
	}
</script>

<style scoped>
	.nav-box {
		width: 100%;
		display: flex;
		height: 60px;
		background-color: #141414;
		align-items: center;
	}
	
	.nav-box img {
		width: 40px;
		height: 40px;
		margin-left: 10px;
		border-radius: 40px;
	}
	
	.nav-box-search {
		position: relative;
		width: calc(100vw - 80px);
	}
	
	.nav-box-search input {
		height: 40px;
		border-radius: 40px;
		border: 0;
		width: calc(100% - 50px);
		padding-left: 50px;
		margin-left: 10px;
		outline: none;
	}
	
	.nav-box-search i {
		position: absolute;
		left: 20px;
		font-size: 20px;
		top: 10px;
	}
	
	.nav-box-add {
		display: flex;
		flex-direction: column;
		margin-left: 20px;
	}
	
	.nav-box-add i {
		background-color: #fff;
		width: 24px;
		height: 24px;
		line-height: 24px;
		border-radius: 24px;
		font-size: 15px;
	}
	
	.nav-box-add span {
		font-size: 12px;
		color: #fff;
		margin-top: 5px;
	}
	
	.fans-box-title {
		font-size: 16px;
		text-align: left;
		display: block;
		margin: 10px;
	}
	
	.fans-box-list {
		display: flex;
		justify-content: left;
		flex-wrap: nowrap;
		overflow-x: scroll;
		overflow-x: scroll;
		scrollbar-width: none;
		-ms-overflow-style: none;
	}
	
	.fans-box-list::-webkit-scrollbar {
	  display: none;
	}
	
	.fans-box-list-item {
		display: flex;
		flex-direction: column;
		padding-bottom: 20px;
		justify-content: center;
		align-items: center;
	}
	
	.fans-box-list-item-face {
		width: 60px;
		height: 60px;
		border-radius: 60px;
		margin: 15px;
	}
	
	.fans-box-list-item-name {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 70px;
		font-weight: bold;
		color: #fff;
	}
	
	.content {
		background-color: #1c1c1c;
		color: #c0c0c0;
	}
	
	.fans-box {
		background-color: #1c1c1c;
	}
	
	.page-btns {
		background-color: #141414;
		display: flex;
		justify-content: space-around;
		padding: 20px 0px;
	}
	
	.page-btns-item {
		width: 28%;
		height: 80px;
		border-radius: 10px;
		background-color: #1c1c1c;
		text-align: center;
		line-height: 80px;
		color: #fff;
		transition: 0.3s;
	}
	
	.choose-btn {
		background-color: #fff;
		color: #141414;
	}
	
	.grid-container {
	  display: grid;
	  grid-template-columns: repeat(2, 1fr); /* 每列4个 */
	  gap: 5px; /* 间隔5px */
	}
	
	.board-grid-container {
		width: 92%;
		margin: 20px auto;
		gap: 25px;
	}
	
	.bottom_view_like {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  flex-direction: column;
	  color: #fff;
	  font-size: 12px;
	  border-radius: 10px;
	  overflow: hidden;
	  position: relative;
	}
	
	.bottom_view_like img {
	  width: 100%;
	  height: 100%;
	}
	
	.like-hot-box {
		background-color: #000;
		width: 100%;
		display: flex;
		justify-content: space-between;
		height: 40px;
		align-items: center;
		position: absolute;
		bottom: 60px;
		background: linear-gradient(to top,rgba(0,0,0,0.4),rgba(0,0,0,0));
	}
	
	.hot-box {
		margin-right: 10px;
	}
	
	
	
	.board_menu_box_main {
		display: flex;
		justify-content: space-around;
		padding-top: 20px;
		background-color: rgba(42, 42, 42, 1);
		height: 100%;
	}
	
	.board_menu_box_item {
		display: flex;
		flex-direction: column;
	}
	
	.like_box-info {
		display: flex;
		justify-content: start;
		text-align: left;
		align-items: left;
		width: 100%;
		padding-top: 5px;
		background-color: #e5e5e5;
		position: relative;
	}
	
	.like_box-info i {
		color: #000;
		position: absolute;
		bottom: 10px;
		right: 0px;
	}
	
	.like_box-info img {
		width: 40px;
		height: 40px;
		border-radius: 35px;
		margin: 5px;
	}
	
	.bottom_view_like_info {
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: left;
		text-align: left;
		position: relative;
	}
	
	.like-title {
		font-size: 14px;
		font-weight: bold;
		margin: 5px;
		color: #000;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 120px;
	}
	
	.like-user {
		color: #666666;
		margin-left: 5px;
		margin-bottom: 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100px;
	}
	
	.page-main {
		overflow-y: scroll;
		height: calc(100vh - 350px);
	}
	
	.board_menu_box_main {
		display: flex;
		justify-content: space-around;
		padding-top: 20px;
		background-color: rgba(42, 42, 42, 1);
		height: 100%;
	}
	
	.board_menu_box_item {
		display: flex;
		flex-direction: column;
	}
	
	.board_menu_box_item span {
		color: #fff;
		font-size: 14px;
		margin-top: 5px;
	}
	
	.board_menu_box_item i {
		font-size:35px;
		margin-bottom: 5px;
		color: #fff;
	}
	
	.empty-box {
		width: 90vw;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		margin-top: 40%;
		font-size: 14px;
		color: #686868;
		
	}
</style>