<template>
	<div class="content" @click="playerMenu = false;selectVideo = false;showVoice = false">
		<div :class="{'loading-screen': true, 'fade-out': !loading}">
			<img src="@/assets/images/load.gif" alt="Loading..." />
		</div>
		<!-- <div class="board-box">
			<div class="player-item" v-for="(item,index) in player" :key="index">
				{{item.num}}
			</div>
		</div> -->
		<div class="top_menu_nav">
			<div class="btns-box">
				<div class="playerInfo_box" v-if="isfans">
					<div class="playerInfo_box_face">
						<img :src="userInfo.realportrait || defaultLogo" alt="" />
					</div>
					<div class="playerInfo_box_name" @click.stop="playerMenu = true">{{userInfo.realname || 队员名称}}</div>
					<i class="el-icon--right el-icon-caret-bottom"></i>
					<div class="playerInfo_box_list" v-if="playerMenu">
						<div class="playerInfo_box_list_item" @click="newBoard()">新建战术板</div>
						<div class="playerInfo_box_list_item" @click="openMyBoard()">我的战术板</div>
						<!-- <div class="menu-scroll-div" v-if="taticList.length > 0">
							<div class="playerInfo_box_list_item_tatic" v-for="(item,index) in taticList" :key="index" @click="toTatic(item.id)">
								{{item.title}}
							</div>
						</div>
						<div class="menu-scroll-div" v-else style="line-height: 120px;color: #969696;font-size: 12px;">
							还没有制作过喔~
						</div> -->
						<div class="playerInfo_box_list_item" @click="openMyLike()">
							我的收藏</div>
						<div class="playerInfo_box_list_item" @click="toBBS()">
							进入战术论</div>
					</div>
				</div>
				<div class="playerInfo_box" v-else>
					<el-button class="menu_btn registerBtn">注册</el-button>
				</div>
				<!-- <el-button class="menu_btn saveBtn">保存</el-button> -->
				<div>
					<!-- <el-button class="menu_btn my_tactic">我的战术<i class="el-icon--right el-icon-caret-bottom"></i></el-button> -->
					<div v-show="!isSharePage">
						<el-button class="menu_btn saveBtn" @click="updateBoard" v-show="isEditPage && todoList.length > 1">更新</el-button>
						<el-button class="menu_btn saveBtn" @click="issave = true">{{isEditPage ? '另存为' : '保存'}}</el-button>
						<el-button class="menu_btn export" @click="isshare = true" v-if="taticid > 0">分享</el-button>
					</div>
					<el-button class="menu_btn export" @click="isshare = true" v-show="isSharePage">分享</el-button>
				</div>
			</div>
		</div>
		<div class="danmu-layer" ref="danmuLayer" :style="{ width: canvasWidth + 'px', height: canvasHeight + 'px' }" v-show="isDanmuActive && isSharePage">
		      <div
		        v-for="(danmu, index) in danmuList"
		        :key="index"
		        class="danmu"
		        :style="{ top: danmu.y + 'px', left: danmu.x + 'px', animationDuration: danmu.speed + 's' }"
		      >
		        {{ danmu.text }}
		      </div>
		</div>
		<canvas class="canvas-board" id="Mycanvas" ref="canvasBoard"
			:style="{ backgroundImage: `url(${backgroundPath})` }">

		</canvas>
		<!-- <div ref="ball" class="ball" @mousedown="startDrag" @mouseup="stopDrag"></div> -->
		<div v-show="if_home">
			<div v-for="(ball, index) in homePlayers" :key="ball.id" class="ball"
				:style="'left: '+ball.currentPos.x+'px; top: '+ball.currentPos.y+'px;'+ball.style"
				@touchstart="startDrag($event, index)" @touchend="stopDrag($event, index)"
				@touchmove="frameList.length == 0 ? setBallPos($event, index) : drawPath($event, index)">
				{{ball.num}}
				<span class="basketball_icon iconfont icon-lanqiu"
					v-if="ball.hasBall && isbasketFrame && frameList.length > 0"
					:style="basketdragging ? 'opacity: 0;' : 'opacity: 1;'" @touchstart.stop="setBasketball($event)"
					@touchmove.stop="onBasketballDrag($event)" @touchend.stop="stopBasketballDrag()"></span>
			</div>
		</div>
		<div v-show="if_guest">
			<div v-for="(ball, index) in guestPlayers" :key="ball.id" class="ball balldefined"
				:style="'left: '+ball.currentPos.x+'px; top: '+ball.currentPos.y+'px;'+ball.style"
				@touchstart="startDrag($event, index+5)" @touchend="stopDrag($event, index+5)"
				@touchmove="frameList.length == 0 ? setBallPos($event, index+5) : drawPath($event, index+5)">
				{{ball.num}}
				<span class="basketball_icon iconfont icon-lanqiu" v-if="ball.hasBall"></span>
			</div>
		</div>
		<div class="share-title" v-if="isSharePage">{{taticName || 战术板名称}}</div>
		<span class="backetball_move iconfont icon-lanqiu" v-if="basketdragging"
			:style="'left: '+basketball.x+'px; top: '+basketball.y+'px;'"></span>
		<div class="middle_menu_nav" v-show="!isSharePage">
			<div class="middle_menu_item" @click="if_home = !if_home">
				<i class="iconfont icon-attacker"></i>
				<span>攻方</span>
			</div>
			<div class="middle_menu_item" @click="if_guest = !if_guest">
				<i class="iconfont icon-fangshou"></i>
				<span>守方</span>
			</div>
			<div class="middle_menu_item">
				<i class="iconfont icon-lanqiu1" @click="showBasketFrame()"></i>
				<span>篮球</span>
			</div>
			<div class="middle_menu_item">
				<i class="iconfont" :class="playerLine ? 'icon-orbit-full' : 'icon-yincangguijiqiu'"
					@click="setLine()"></i>
				<span>轨迹</span>
			</div>
			<div class="middle_menu_item" @click="attackMode = !attackMode">
				<i class="iconfont icon-zhuanhuan"></i>
				<span>攻防转换</span>
			</div>
			<div class="middle_menu_item" @click="setPlayer">
				<i class="iconfont icon-gongxiao"></i>
				<span>{{playernum}}X{{playernum}}</span>
			</div>
		</div>
		<div class="middle_time_nav" v-show="!isSharePage">
			<div class="time_box">
				{{timeline[timeline.length - 1] || "00:00"}} / {{timeline[timeIndex] ||  "00:00"}}
			</div>
			<div class="play_box">
				<i class="iconfont icon-shuanglie" v-if="isPlay" @click="isPlay = false"></i>
				<i class="iconfont icon-bofang1" @click="moveBallAlongPath()" v-else></i>
			</div>
			<div class="tool_box">
				<i class="iconfont icon-chexiao" :class="{'noneSelect' : todoList.length <= 1}"
					@click="toBackStep()"></i>
				<i class="iconfont icon-zhongzuo" :class="{'noneSelect' : redoList.length == 0}" @click="reDo()"></i>
			</div>
		</div>
		<div v-show="isSharePage" class="middle_time_nav">
			<div class="time_box">
				{{timeline[timeline.length - 1] || "00:00"}} / {{timeline[timeIndex] ||  "00:00"}}
			</div>
			<div class="shareBtn_div">
				<i class="iconfont icon-shuanglie" v-if="isPlay" @click="isPlay = false"></i>
				<i class="iconfont icon-bofang1" @click="moveBallAlongPath()" v-else></i>
			</div>
			<div class="tool_box">
				<i style="font-size: 18px;" class="iconfont"
					:class="isDanmuActive  ? 'icon-danmu1' : 'icon-danmu2'" @click="toggleDanmu()"></i>
				<i style="font-size: 18px;" class="iconfont"
					:class="isCollect ? 'icon-icon_collect_selected' : 'icon-icon_collect'" @click="addCollect(taticid)"></i>
				<i style="font-size: 18px;" class="iconfont"
					:class="playerLine ? 'icon-orbit-full' : 'icon-yincangguijiqiu'" @click="setLine()"></i>
			</div>

		</div>
		<div class="bottom_time_nav" ref="scrollContainer"
			:style="{marginTop: isSharePage ? '-500px' : '0px',zIndex: isSharePage ? '-1' : '9'}">
			<div class="bottom_time_nav_inner" :style="{width: fullSec+'px'}" ref="scrollContent">
				<div class="time_line">
					<div class="time_item" v-for="(item,index) in timeline" :key="item">{{index % 2 == 0 ? item : '•'}}
					</div>
				</div>
				<div class="scroll_box">
					<div v-for="(item,index) in frameList" :key="index">
						<div>
							<div class="frame_scroll" :style="{width: 60*item - 4+'px'}"
								:class="{'isChoose' : playerPlayIndex == index}" @click.stop="chooseFrame(index)">
								{{item}}s
								<i class="iconfont icon-suoding" v-if="frameList.length - 1 != index"></i>
							</div>
							<div class="basketFrame">
								<div class="basketFrame_item front_frame"
									:class="{'isbasketBallChoose' : playerPlayIndex == index && basketSite == 'front'}"
									v-show="checkBasketball(index,'front')" :style="{ order: 1 }"
									@click="setBasketballSite('front',index)">
									<img class="" src="@/assets/images/ball_icon.png" alt="" />
								</div>
								<div class="basketFrame_item after_frame"
									:class="{'isbasketBallChoose' : playerPlayIndex == index && basketSite == 'after'}"
									v-show="checkBasketball(index,'after')" :style="{ order: 2 }"
									@click="setBasketballSite('after',index)">
									<img class="" src="@/assets/images/ball_icon.png" alt="" />
								</div>
							</div>
							<div class="voice_scroll" :style="{width: 60*item - 4+'px'}"
								:class="{'isVoiceChoose' : playerPlayIndex == index && selectVideo}"
								v-show="checkBasketVoice(index)" @click.stop="setBasketVoice(index)">
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="bottom_time_float" @click="basketSite = null;basketDrawer  = false" v-show="!isSharePage">
			<div class="scroll_line"></div>
			<span class="scroll_text" style="color: #fff;" v-if="frameList.length == 0">开始制作动画</span>
			<div class="addFrame" @click="addNewFrame()">
				<div class="addFrame_btn">
					<i class="iconfont icon-add-s"></i>

				</div>
			</div>
		</div>
		<div class="bottom_menu_menu"
			v-if="showDrawer && !showLineDrawer && !showcolorDrawer && !basketDrawer && !showVoice && frameList.length > 0">
			<div @click="setSec(2)" :class="{'bold_text' : frameList[playerPlayIndex] == 2}">
				2s
			</div>
			<div @click="setSec(3)" :class="{'bold_text' : frameList[playerPlayIndex] == 3}">
				3s
			</div>
			<div @click="setSec(4)" :class="{'bold_text' : frameList[playerPlayIndex] == 4}">
				4s
			</div>
			<div @click="setSec(5)" :class="{'bold_text' : frameList[playerPlayIndex] == 5}">
				5s
			</div>
		</div>
		<div class="bottom_menu_menu"
			v-if="!showDrawer && showLineDrawer && !showcolorDrawer && !basketDrawer && !showVoice && frameList.length > 0">
			<div class="bottom_menu_menu_item"
				:class="{'bold_text' : checkLineVisib() && frameLine[playerPlayIndex].show}" @click="showPath">
				<i class="iconfont icon-yanjing_xianshi"></i>
				显示
			</div>
			<div class="bottom_menu_menu_item"
				:class="{'bold_text' : !checkLineVisib() && frameLine[playerPlayIndex].show == false}"
				@click="hidePath">
				<i class="iconfont icon-yanjing_yincang"></i>
				隐藏
			</div>
		</div>

		<div class="bottom_menu_menu"
			v-if="!showDrawer && !showLineDrawer && showcolorDrawer && !basketDrawer && !showVoice && frameList.length > 0">
			<div class="bottom_menu_menu_item">
				<div class="color_block" style="background-color: #f00;"
					:style="{border: linecolor == 0 ? '4px solid #fff' : '1px solid #c0c0c0'}" @click="setLineColor(0)">
				</div>
			</div>
			<div class="bottom_menu_menu_item">
				<div class="color_block" style="background-color: #00f;"
					:style="{border: linecolor == 1 ? '4px solid #fff' : '1px solid #c0c0c0'}" @click="setLineColor(1)">
				</div>
			</div>
			<div class="bottom_menu_menu_item">
				<div class="color_block" style="background-color: #0f0;"
					:style="{border: linecolor == 2 ? '4px solid #fff' : '1px solid #c0c0c0'}" @click="setLineColor(2)">
				</div>
			</div>
			<div class="bottom_menu_menu_item">
				<div class="color_block" style="background-color: #ff0;"
					:style="{border: linecolor == 3 ? '4px solid #fff' : '1px solid #c0c0c0'}" @click="setLineColor(3)">
				</div>
			</div>
			<div class="bottom_menu_menu_item">
				<div class="color_block" style="background-color: #000;"
					:style="{border: linecolor == 4 ? '4px solid #fff' : '1px solid #c0c0c0'}" @click="setLineColor(4)">
				</div>
			</div>
		</div>

		<div class="bottom_menu_menu"
			v-if="!showDrawer && !showLineDrawer && !showcolorDrawer && basketDrawer && !showVoice && frameList.length > 0">
			<div class="bottom_menu_menu_item" :class="{'bold_text' : basketSite == 'front'}"
				@click="updateSite('front')">
				<i class="iconfont icon-triangle-left"></i>
				先传球
			</div>
			<div class="bottom_menu_menu_item" :class="{'bold_text' : basketSite == 'after'}"
				@click="updateSite('after')">
				<i class="iconfont icon-triangle-right"></i>
				后传球
			</div>
			<div class="bottom_menu_menu_item" @click="deleteSite()">
				<i class="iconfont icon-shanchu"></i>
				删除
			</div>
		</div>

		<div class="bottom_menu_menu"
			v-if="!showDrawer && !showLineDrawer && !showcolorDrawer && !basketDrawer && showVoice && frameList.length > 0">
			<div class="bottom_menu_menu_item" @click="deleteVoice()">
				<i class="iconfont icon-shanchu"></i>
				删除
			</div>
		</div>

		<div class="bottom_menu" v-if="frameList.length > 0 && !isSharePage">
			<div class="bottom_menu_btn" @click="showSecMenu(0)">
				<i class="iconfont icon-lishibisai" :class="{'bold_text' : showDrawer}"></i>
				<span class="bottom_text" :class="{'bold_text' : showDrawer}">秒数</span>
			</div>
			<!-- <div class="bottom_menu_btn" @click="showSecMenu(1)" :class="{'bold_text' : showLineDrawer}">
				<i class="iconfont icon-jiantouarrow482" :class="{'bold_text' : showLineDrawer}"></i>
				<span class="bottom_text" :class="{'bold_text' : showLineDrawer}">显示轨迹</span>
			</div> -->
			<div class="bottom_menu_btn" @click="showSecMenu(2)" :class="{'bold_text' : showcolorDrawer}">
				<i class="iconfont icon-yanse2" :class="{'bold_text' : showcolorDrawer}"></i>
				<span class="bottom_text" :class="{'bold_text' : showcolorDrawer}">轨迹颜色</span>
			</div>
			<div class="bottom_menu_btn" @click="isVoice = true;touchSecond = 0;requestMicrophonePermission()">
				<i class="iconfont icon-bofangzhong"></i>
				<span class="bottom_text">解说录制</span>
			</div>
			<!-- <div class="bottom_menu_btn" @click="playRecording">
				<i class="iconfont icon-bofang"></i>
				<span class="bottom_text">播放录音</span>
			</div> -->
			<div class="bottom_menu_btn" @click="deleteframe">
				<i class="iconfont icon-shanchu"></i>
				<span class="bottom_text">删除</span>
			</div>
			<div class="bottom_menu_btn" @click="ismessage = true">
				<i class="iconfont icon-message"></i>
				<span class="bottom_text">留言人气</span>
			</div>
		</div>
		<div class="bottom_menu" v-else style="margin-top: 490px;justify-content: left;">
			<div class="bottom_menu_btn share_mod">
				<span class="bottom_text" :class="{'selectPage' : MenuIdx == 0}" @click="MenuIdx = 0">评论</span>
			</div>
			<div class="bottom_menu_btn share_mod">
				<span class="bottom_text" :class="{'selectPage' : MenuIdx == 1}" @click="MenuIdx = 1">收藏</span>
			</div>
			<div class="bottom_menu_btn share_mod">
				<span class="bottom_text" :class="{'selectPage' : MenuIdx == 2}" @click="MenuIdx = 2">
					{{shareInfo.username}}
				</span>
			</div>
		</div>
		<div class="bottom_view_box" v-if="isSharePage">
			<div class="bottom_view_box_item" v-if="MenuIdx == 0">
				<div class="message-container-nav" style="color: #fff;">
					{{messageList.length}}条评论
				</div>
				<div class="message-item" v-for="(item,index) in messageList" :key="index" style="color: #fff;">
					<div class="message-item-left">
						<img :src="item.micon || defaultLogo" alt="" />
					</div>
					<div class="message-item-right">
						<span class="message-item-right-name">{{item.mname}}</span>
						<div class="message-item-right-content">{{item.content}}</div>
						<div class="message-item-right-btns">
							<div class="message-item-right-btns-left">
								<span class="message-item-time">{{timeAgo(item.addtime)}}</span>
								<!-- <div class="message-item-report-btn">回复</div> -->
							</div>
							<!-- <div class="message-item-right-btns-right">
								<div class="like-btn">
									<i class="iconfont icon-heart-fill"></i> 0
								</div>
								<div class="unlike-btn">
									<i class="iconfont icon-dislike-fill"></i> 0
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
			<div class="bottom_view_box_item" v-if="MenuIdx == 1">
				<div class="bottom_view_like share_like_item" v-for="(item,index) in taticLikeList" :key="index" @click="toTatic(item.id)">
					<div class="like_box-info">
						<img :src="item.avatarUrl || defaultLogo" alt="">
						<div class="bottom_view_like_info">
							<span class="like-title" style="color: #fff;">{{item.name}}</span>
							<span class="like-user" style="color: #b5b5b5;">{{item.title}}</span>
						</div>
					</div>
					<div class="share-like-tool">
						<div>
							<i class="iconfont icon-liwu"></i>
							<span>0K</span>
						</div>
						<div>
							<i class="iconfont icon-heart-fill" style="color: #ff1741;"></i>
							<span>0</span>
						</div>
						<div>
							<i class="iconfont icon-gengduo1" @click.stop="showMyLikeMenu(item.id)"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom_view_box_item" v-if="MenuIdx == 2">
				<div class="bottom_view_like share_like_item" v-for="(item,index) in sharetaticList" :key="index" :class="{'redborder': index === 0}" @click.stop="toTatic(item.id)">
					<i class="iconfont icon-dangqian" v-show="index === 0"></i>
					<div class="like_box-info">
						<img :src="item.avatarUrl || defaultLogo" alt="">
						<div class="bottom_view_like_info">
							<span class="like-title" style="color: #fff;">{{item.name}}</span>
							<span class="like-user" :class="{'selectText': index === 0}" style="color: #b5b5b5;">{{item.title}}</span>
						</div>
					</div>
					<div class="share-like-tool">
						<div>
							<i class="iconfont icon-liwu"></i>
							<span>1.4K</span>
						</div>
						<div>
							<i class="iconfont icon-heart-fill" style="color: #ff1741;"></i>
							<span>0</span>
						</div>
						<div>
							<i class="iconfont icon-gengduo1" @click.stop="showMyLikeMenu(item.id)" style="z-index: 2;position: relative;"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="repair_box">
				<input type="text" ref="inputElement" class="repair_box_input" v-model="repairText" placeholder="说点什么吧" @keydown.enter="sendRepair">
				<i class="iconfont icon-fabu" @click="sendRepair"></i>
				<i class="iconfont icon-liwu"></i>
			</div>
		</div>
		<audio v-if="audioUrl" :src="audioUrl" controls style="position: absolute;left: 0;bottom: 60px;"></audio>
		<div style="position: absolute;bottom: 10px;left: 0;right: 0;" v-show="!isSharePage">
			<span style="font-size: 12px;color: #c0c0c0;margin-top: 20px;height: 50px;line-height: 50px;"
				v-if="this.frameList.length == 0">提示：摆好初始位置，开始制作动画，拖动按钮即可</span>
		</div>

		<div class="maskdiv" v-if="(issave && !isSharePage)  || isfans === false">
			<div class="saveDialog" v-if="issave">	
				<div v-if="isPlayer === false && !isSharePage">
					<span class="">保存战术板需要先注册成为球员</span>
					<div style="saveDialog_btns">
						
						<el-button class="registerBtn" @click="issave = false">先试用</el-button>
						<el-button style="background-color: #fe2b54;color: #fff;border:2px solid #fe2b54"
							@click="register()">去注册</el-button>
					</div>
				</div>
				<div  v-if="isPlayer && !isSharePage">
					<input class="saveDialog_input" v-model="taticName" placeholder="请输入战术名称" maxlength="10" />
					<div style="saveDialog_btns">

						<el-button class="registerBtn" @click="issave = false">取消</el-button>
						<el-button style="background-color: #fe2b54;color: #fff;border:2px solid #fe2b54"
							@click="saveTatic()">确认</el-button>
					</div>
				</div>
			</div>
			<div class="fansDialog" v-if="isfans === false">
				<img src="@/assets/images/wm_weixin.jpg" alt="" />
				<div style="saveDialog_btns">
					<span>请先关注微信公众号</span>
				</div>
			</div>
		</div>
		<el-drawer title="分享" :visible.sync="isshare" direction="btt" :with-header="false" size="15%">
			<div class="picker-container">
				<div class="share_item" @click="showShare = true">
					<i class="share-icon iconfont icon-sign_wechat"></i>
					<span class="share_text">分享给好友</span>
				</div>
				<!-- <div class="share_item">
					<i class="share-icon iconfont icon-post_video"></i>
					<span class="share_text">生成视频</span>
				</div> -->
			</div>
		</el-drawer>
		<el-drawer title="录音" :visible.sync="isVoice" direction="btt" :with-header="false" size="40%">
			<div class="picker-container">
				<div class="share_item">
					<span class="secondTips" style="margin-bottom: 50%">{{touchSecond}}</span>
					<div class="microphone-animation" @touchstart="startAnimation" @touchend="stopAnimation">
						<i class="iconfont icon-maikefeng voice-icon"></i>
						<div class="circle" v-for="(circle, index) in circles" :key="index"></div>
					</div>
					<span class="share_text" style="margin-top: 40px;">按住录音</span>
				</div>
				<!-- <div class="share_item">
					<i class="share-icon iconfont icon-post_video"></i>
					<span class="share_text">生成视频</span>
				</div> -->
			</div>
		</el-drawer>

		<el-drawer title="留言板" :visible.sync="ismessage" direction="btt" :with-header="false" :size="messageSize+'%'"
			class="message_box">
			<div class="message-container">
				<div class="message-container-btns">
					<i class="iconfont icon-quanping" @click="setMessageSize()"></i>
					<i class="iconfont icon-guanbi1" @click="ismessage = false"></i>
				</div>
				<div class="message-tab-btns">
					<div class="message-container-btn" :class="{'messageChoose' : MessageIdx == 0}" @click="MessageIdx = 0">留言评论</div>
					<div class="message-container-btn" :class="{'messageChoose' : MessageIdx == 1}" @click="MessageIdx = 1">人气打赏</div>
				</div>
				<div v-if="MessageIdx == 0">
					<div class="message-container-nav">
						{{messageList.length}}条评论
					</div>
					<div class="message-scroll" :style="{height: 'calc('+scrollHeight+' - 150px)'}">
						<div class="message-item" v-for="(item,index) in messageList" :key="index">
							<div class="message-item-left">
								<img :src="item.micon || defaultLogo" alt="" />
							</div>
							<div class="message-item-right">
								<span class="message-item-right-name">{{item.mname}}</span>
								<div class="message-item-right-content">{{item.content}}</div>
								<div class="message-item-right-btns">
									<div class="message-item-right-btns-left">
										<span class="message-item-time">{{timeAgo(item.addtime)}}</span>
										<!-- <div class="message-item-report-btn">回复</div> -->
									</div>
	
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="MessageIdx == 1">
					<div class="message-container-nav-float">
						<div class="num-box">
							<span class="num-box-number">0</span>
							<span class="num-box-text">人气值</span>
							
						</div>
						<div class="num-box">
							<span class="num-box-number">0</span>
							<span class="num-box-text">收藏数量</span>
						</div>
					</div>
					<div class="message-scroll" :style="{height: 'calc('+scrollHeight+' - 215px)'}">
						<div class="message-item" v-for="(item,index) in giftList" :key="index">
							<div class="message-item-left" style="width: 50px;">
								<img :src="item.icon" alt="" />
							</div>
							<div class="message-item-right">
								<span class="message-item-right-name" style="font-size: 18px;color: #000;"><span style="color: #077fff">{{item.name}}</span> 送给 <span style="color: #077fff">{{userInfo.realname}}</span> 1个 <i class="iconfont icon-missile" style="font-size: 30px;"></i> X {{item.num}}</span>
								<div class="message-item-right-content" style="font-size: 14px;color: #ccc;margin-top: 0px;">11-28</div>
								<!-- <div class="message-item-right-btns">
									<div class="message-item-right-btns-left">
										<span class="message-item-time"></span>
										<div class="message-item-report-btn">回复</div>
									</div>
									<div class="message-item-right-btns-right">
										<div class="like-btn">
											<i class="iconfont icon-heart-fill"></i> 0
										</div>
										<div class="unlike-btn">
											<i class="iconfont icon-dislike-fill"></i> 0
										</div>
									</div>
								</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-drawer>
		<el-drawer title="我的战术版" :visible.sync="isMyBoard" direction="btt" :with-header="false" size="100%"
			class="message_box">
			<div class="message-container">
				<div class="message-container-btns board-container-btns">
					<div class="message-container-nav">
						我的战术板
					</div>
					<i class="iconfont icon-guanbi1" @click="isMyBoard = false"></i>
				</div>
				<div class="board-container-search-input">
					<input v-model="myBoardSearch" class="" placeholder="搜索我的战术板" type="text" />
					<i class="iconfont icon-sousuo"></i>
				</div>
				<div class="bottom_view_box_item grid-container board-grid-container">
					<div class="bottom_view_like" v-for="(item,index) in filteredTaticList" :key="index" @click.stop="toTatic(item.id)">
						<img src="@/assets/images/board.jpg" alt="" />
						<div class="like-hot-box">
							<div class="like-box">
								<i class="iconfont icon-shoucang1"></i>
								<span>3.6万</span>
							</div>
							<div class="hot-box">
								<i class="iconfont icon-redu"></i>
								<span>1128</span>
							</div>
						</div>
						<div class="like_box-info">
							<img :src="item.avatarUrl || defaultLogo" alt="">
							<div class="bottom_view_like_info">
								<span class="like-title">{{item.title}}</span>
								<span class="like-user">{{item.name}}</span>
							</div>
							<i class="iconfont icon-gengduo1" @click.stop="showMyLikeMenu(item.id)"></i>
						</div>
					</div>
					<div class="empty-box" v-if="taticList.length == 0">
						你还没有战术板喔，快去制作一个吧！
					</div>
				</div>
				
			</div>
		</el-drawer>
		<el-drawer title="我的收藏" :visible.sync="isMyLike" direction="btt" :with-header="false" size="100%"
			class="message_box">
			<div class="message-container">
				<div class="message-container-btns board-container-btns">
					<div class="message-container-nav">
						我的收藏
					</div>
					<i class="iconfont icon-guanbi1" @click="isMyLike = false"></i>
				</div>
				<div class="board-container-search-input">
					<input v-model="myLikeSearch" class="" placeholder="搜索我的收藏" type="text" />
					<i class="iconfont icon-sousuo"></i>
				</div>
				<div class="bottom_view_box_item grid-container board-grid-container">
					<div class="bottom_view_like" v-for="(item,index) in filteredTaticLikeList" :key="index" @click.stop="toTatic(item.id)">
						<img src="@/assets/images/board.jpg" alt="" />
						<div class="like-hot-box">
							<div class="like-box">
								<i class="iconfont icon-shoucang1"></i>
								<span>0</span>
							</div>
							<div class="hot-box">
								<i class="iconfont icon-redu"></i>
								<span>0</span>
							</div>
						</div>
						<div class="like_box-info">
							<img :src="item.avatarUrl || defaultLogo" alt="">
							<div class="bottom_view_like_info">
								<span class="like-title">{{item.title}}</span>
								<span class="like-user">{{item.name || 制作者}}</span>
							</div>
							<i class="iconfont icon-gengduo1" @click.stop="showMyLikeMenu(item.id)"></i>
						</div>
					</div>
					<div class="empty-box" v-if="taticLikeList.length == 0">
						你还没有收藏战术板喔~
					</div>
				</div>
				
			</div>
		</el-drawer>
		<el-drawer title="我的战术版设置" :visible.sync="isMyBoardMenu" direction="btt" :with-header="false" size="15%"
			class="board_menu_box">
			<div class="board_menu_box_main">
				<div class="board_menu_box_item" @click="toTatic()">
					<i class="iconfont icon-bianji"></i>
					<span>编辑</span>
				</div>
				<div class="board_menu_box_item" @click="delTatic()">
					<i class="iconfont icon-shanchu1"></i>
					<span>删除</span>
				</div>
				<div class="board_menu_box_item">
					<i class="iconfont icon-fenxiang"></i>
					<span>分享</span>
				</div>
			</div>
		</el-drawer>
		<el-drawer title="我的收藏设置" :visible.sync="isMyLikeMenu" direction="btt" :with-header="false" size="15%"
			class="board_menu_box">
			<div class="board_menu_box_main">
				<div class="board_menu_box_item">
					<i class="iconfont" :class="isChooseCollect ? 'icon-icon_collect_selected' : 'icon-icon_collect'" @click="addCollect(nowChooseId)"></i>
					<span>{{isChooseCollect ? '取消收藏' : '收藏'}}</span>
				</div>
				<div class="board_menu_box_item">
					<i class="iconfont icon-fenxiang"></i>
					<span>分享</span>
				</div>
			</div>
		</el-drawer>
		<div class="mask-share" v-show="showShare">
			<img src="@/assets/images/share.png" alt="" />
			<button class="share-button" @click="showShare = false;drawer = false">我知道了</button>
		</div>
	</div>

</template>

<script>
	import wx from 'weixin-js-sdk';
	import Vue from 'vue';
	import OSS from 'ali-oss';
	import Recorder from 'recorder-js';
	export default {
		data() {
			return {
				recorder: null,
				audioContext: null,
				client: null,
				isPlayer: false,
				isMyBoard: false,
				isMyLike: false,
				isMyBoardMenu: false,
				isMyLikeMenu: false,
				MenuIdx: 0,
				isSharePage: false,
				animationActive: false,
				circles: [1, 2, 3],
				mediaRecorder: null,
				localId: null,
				audioChunks: [],
				audioUrl: '',
				recordingTimeout: null,
				messageSize: 60,
				messageList: [
				],
				loading: true,
				taticList: [
				],
				sharetaticList: [
					
				],
				giftList: [
					{
						id: 0,
						name: '榜一大哥',
						gift: 'huojian',
						num: 3,
						icon: 'https://pic.rmb.bdstatic.com/bjh/news/71bd8db72fb366e18762c8b8ffb1eff7.jpeg'
					}
				],
				taticLikeList: [
				],
				MessageIdx: 0,
				nowChooseId: 0,
				taticName: "",
				basketSite: "",
				selectedSecond: null,
				seconds: Array.from({
					length: 10
				}, (_, i) => i + 1),
				showDrawer: false,
				timeIndex: 0,
				if_home: true,
				if_guest: false,
				attackMode: true,
				showLine: true,
				showLineDrawer: false,
				showcolorDrawer: false,
				showShare: false,
				basketDrawer: false,
				linecolor: 0,
				playerMenu: false,
				isfans: true,
				issave: false,
				isshare: false,
				ismessage: false,
				isVoice: false,
				showVoice: false,
				myBoardSearch: '',
				myLikeSearch: '',
				player: [
					[{
							id: 1,
							num: 1,
							playerSite: "PG",
							posX: 120,
							posY: 240,
							path: [],
							dragging: false,
							style: '',
							currentPos: {
								x: 181,
								y: 260
							},
							pathMode: 1,
							passMode: 1,
							hasBall: true,
							isBasket: false,
							ishome: true,
							isguest: false
						},
						{
							id: 2,
							num: 2,
							playerSite: "SG",
							posX: 240,
							posY: 120,
							path: [],
							dragging: false,
							style: '',
							currentPos: {
								x: 312,
								y: 185
							},
							pathMode: 1,
							passMode: 1,
							hasBall: false,
							isBasket: false,
							ishome: true,
							isguest: false
						},
						{
							id: 3,
							num: 3,
							playerSite: "SF",
							posX: 300,
							posY: 160,
							path: [],
							dragging: false,
							style: '',
							currentPos: {
								x: 37,
								y: 190
							},
							pathMode: 1,
							passMode: 1,
							hasBall: false,
							isBasket: false,
							ishome: true,
							isguest: false
						},
						{
							id: 4,
							num: 4,
							playerSite: "PF",
							posX: 300,
							posY: 160,
							path: [],
							dragging: false,
							style: '',
							currentPos: {
								x: 182,
								y: 168
							},
							pathMode: 1,
							passMode: 1,
							hasBall: false,
							isBasket: false,
							ishome: true,
							isguest: false
						},
						{
							id: 5,
							num: 5,
							playerSite: "C",
							posX: 200,
							posY: 160,
							path: [],
							dragging: false,
							style: '',
							currentPos: {
								x: 245,
								y: 90
							},
							pathMode: 1,
							passMode: 1,
							hasBall: false,
							isBasket: false,
							ishome: true,
							isguest: false
						},
						{
							id: 6,
							num: 1,
							playerSite: "PG",
							posX: 120,
							posY: 240,
							path: [],
							dragging: false,
							style: '',
							currentPos: {
								x: 111,
								y: 198
							},
							pathMode: 1,
							passMode: 1,
							hasBall: false,
							isBasket: false,
							ishome: false,
							isguest: true
						},
						{
							id: 7,
							num: 2,
							playerSite: "SG",
							posX: 240,
							posY: 120,
							path: [],
							dragging: false,
							style: '',
							currentPos: {
								x: 246,
								y: 201
							},
							pathMode: 1,
							passMode: 1,
							hasBall: false,
							isBasket: false,
							ishome: false,
							isguest: true
						},
						{
							id: 8,
							num: 3,
							playerSite: "SF",
							posX: 300,
							posY: 160,
							path: [],
							dragging: false,
							style: '',
							currentPos: {
								x: 77,
								y: 96
							},
							pathMode: 1,
							passMode: 1,
							hasBall: false,
							isBasket: false,
							ishome: false,
							isguest: true
						},
						{
							id: 9,
							num: 4,
							playerSite: "PF",
							posX: 300,
							posY: 160,
							path: [],
							dragging: false,
							style: '',
							currentPos: {
								x: 288,
								y: 98
							},
							pathMode: 1,
							passMode: 1,
							hasBall: false,
							isBasket: false,
							ishome: false,
							isguest: true
						},
						{
							id: 10,
							num: 5,
							playerSite: "C",
							posX: 200,
							posY: 160,
							path: [],
							dragging: false,
							style: '',
							currentPos: {
								x: 181,
								y: 88
							},
							pathMode: 1,
							passMode: 1,
							hasBall: false,
							isBasket: false,
							ishome: false,
							isguest: true
						},
						{
							id: 11,
							num: 6,
							playerSite: "框",
							posX: 200,
							posY: 160,
							path: [],
							dragging: false,
							style: 'opacity: 0;',
							currentPos: {
								x: 170,
								y: 60
							},
							pathMode: 1,
							passMode: 1,
							hasBall: false,
							isBasket: true,
							ishome: false,
							isguest: false
						}
					]
				],
				playerPlayIndex: 0,
				formationList: [{
						id: 0,
						name: "默认落位",
						pos: [{
								x: 181,
								y: 260
							},
							{
								x: 312,
								y: 185
							},
							{
								x: 37,
								y: 190
							},
							{
								x: 182,
								y: 168
							},
							{
								x: 245,
								y: 90
							},
						]
					},
					{
						id: 1,
						name: "牛角",
						pos: [{
								x: 187,
								y: 229
							},
							{
								x: 306,
								y: 155
							},
							{
								x: 141,
								y: 145
							},
							{
								x: 295,
								y: 48
							},
							{
								x: 93,
								y: 54
							},
						]
					},

				],
				canvasWidth: 0,
				canvasHeight: 0,
				ctx: null,
				initialized: false,
				pathBtn: false,
				pathBtnPos: {
					x: 0,
					y: 0
				},
				buttonPos: 'left: 0px; top: 0px;',
				buttonShow: false,
				pathNowIndex: 0,
				showPass: false,
				showThrow: false,
				setStep: 2,
				isPlay: false,
				timer: null,
				playernum: 5,
				basketdragging: false,
				basketFrame: [],
				clonebasketFrame: [],
				voiceList: [],
				isbasketFrame: false,
				frameTime: 0,
				basketball: {
					x: 181,
					y: 260
				},
				dragOffset: {
					x: 0,
					y: 0
				},
				playerLine: true,
				tmpLine: [],
				frameIndex: 0,
				defaultLogo: require('@/assets/images/defaultlogo.jpg'),
				frameList: [],
				frameLine: [],
				todoList: [],
				redoList: [],
				isEnd: true,
				currentScrollLeft: 0,
				scrollindex: 0,
				pauseTime: 0,
				pauseScrollLeft: 0,
				totalDistance: 0,
				onMove: false,
				pausedTime: 0,
				touchSecond: 0,
				touchTimer: null,
				startTime: 0,
				selectVideo: false,
				mid: '',
				userInfo: {
					realportrait: '',
					realname: '123 '
				},
				taticid: 34,
				shareInfo: {
					username: '',
					taticName: '',
					userid: ''
				},
				isRecording: false,
				isEditPage: false,
				repairText: '',
				isCollect: false,
				shareUserid: 0,
				isChooseCollect: false,
				danmuList: [],
				isDanmuActive: false,
				animationTimers: [],
			}
		},
		watch: {
		    // 深度监听 taticList 的变化
		    taticList: {
		      handler(newList) {
		        this.updatePlayerInfo(newList);
		      },
		      deep: true // 深度监听数组内对象的变化
		    }
		},
		computed: {
			filteredTaticList() {
			      if (!this.myBoardSearch) {
			        // 如果搜索关键词为空，返回完整列表
			        return this.taticList;
			      }
			      // 根据 title 进行模糊匹配，返回匹配的项
			      return this.taticList.filter(item =>
			        item.title.toLowerCase().includes(this.myBoardSearch.toLowerCase())
			      );
			},
			filteredTaticLikeList() {
			      if (!this.myLikeSearch) {
			        // 如果搜索关键词为空，返回完整列表
			        return this.taticLikeList;
			      }
			      // 根据 title 进行模糊匹配，返回匹配的项
			      return this.taticLikeList.filter(item =>
			        item.title.toLowerCase().includes(this.myLikeSearch.toLowerCase())
			      );
			},
			homePlayers() {
				// 返回过滤后的数组
				const filteredPlayers = this.player[this.playerPlayIndex].filter(ball => ball.ishome === true);
				return filteredPlayers.slice(0, this.playernum);
			},
			guestPlayers() {
				// 返回过滤后的数组
				const filteredPlayers = this.player[this.playerPlayIndex].filter(ball => ball.isguest === true);
				return filteredPlayers.slice(0, this.playernum);
			},
			backgroundPath() {
				return this.attackMode ?
					require('../../assets/images/board.jpg') :
					require('../../assets/images/board2.jpg');
			},
			timeline() {
				let timeLine = []
				let j = 0
				for (let k = 0; k < this.frameList.length; k++) {
					j += this.frameList[k]
				}


				for (let i = 0; i <= j; i++) {
					let minutes = Math.floor(i / 60);
					let seconds = i % 60;
					let formattedMinutes = String(minutes).padStart(2, '0');
					let formattedSeconds = String(seconds).padStart(2, '0');
					timeLine.push(`${formattedMinutes}:${formattedSeconds}`)
				}

				return timeLine
			},
			fullSec() {
				let j = 0
				for (let k = 0; k < this.frameList.length; k++) {
					j += this.frameList[k]
				}
				return j * 60 + window.innerWidth
			},
			getColor() {
				let c = this.linecolor
				switch (c) {
					case 0:
						return "#f00";
					case 1:
						return "#00f";
					case 2:
						return "#0f0";
					case 3:
						return "#ff0";
					case 4:
						return "#000";
					default:
						return "#f00";
				}

			},
			scrollHeight() {
				return `${this.messageSize}vh`;
			}
		},
		mounted() {
			document.body.style.overflow = 'hidden';
			this.simulateLoading();
			document.title = '斗战—战术板';
			this.adjustCanvasSize();
			window.addEventListener('resize', this.adjustCanvasSize);
			document.body.addEventListener('touchmove', this.preventPullToRefresh, {
				passive: false
			});
			this.mid = this.$route.query.mid;
			this.taticid = this.$route.query.id;
			this.toDo()
			
			this.checkUserFllow()
			this.getTaticList()
			this.getRepair()
			this.getCollect()
			this.getUserTaticlist()
			this.getTaticInfo()
			
			
			
		},
		beforeDestroy() {
		    document.body.style.overflow = '';
		},
		destroyed() {
			document.body.removeEventListener('touchmove', this.preventPullToRefresh);
		},
		
		methods: {	
			async updatePlayerInfo(list) {
				for (const item of list) {
					if (item.playerid && !item.name) {
						// 如果 playerid 存在，并且尚未获取到用户信息，则发起请求
						const playerInfo = await this.fetchPlayerInfo(item.playerid);
						// 动态更新数组项
						this.$set(item, 'name', playerInfo.name);
						this.$set(item, 'face', playerInfo.face);
					}
				}
			},
			async fetchPlayerInfo(playerid) {
			      try {
			        const response = await this.$api.post('/app/user/info', { memberid: playerid });
			        const data = response.data.data;
			        return {
						name: data.realname,
						face: data.realportrait
			        };
			    } catch (error) {
			        console.error('获取用户信息失败:', error);
			        return {
						name: '未知用户',
						face: ''
			        };
			    }
			},
			checkUserFllow() {
				let formData = new FormData();
				formData.append('mid', this.mid);
				this.$api.post('/app/user/focus', formData).then(response => {
					this.isfans = response.data.data.isFollow
					if (this.isfans) {
						this.getUserInfo()
					}
				}).catch(error => {
					console.error("There was an error!", error);
				})
			},
			getUserInfo() {
				this.$api.post('/app/user/info', {}).then(response => {
					this.userInfo = response.data.data
					if (this.userInfo.realname != ""  && this.userInfo.realname != null) {
						this.isPlayer = true
					}else {
						this.userInfo.realname = this.userInfo.usernick
						if (this.userInfo.usernick == "") {
							this.userInfo.realname = "游客"
						}
						this.isPlayer = false
						this.issave = true
					}
					if (this.userInfo.realportrait != ""  && this.userInfo.realportrait != null) {
						this.isPlayer = true
					}else {
						this.userInfo.realportrait = this.userInfo.icon
						this.isPlayer = false
						this.issave = true
					}
					this.shareInfo.username = this.userInfo.realname
					
					this.share()
					if (this.taticid > 0) {
						this.getTaticInfo()
					}
				}).catch(error => {
					console.error("There was an error!", error);
				})
			},
			simulateLoading() {
				console.log("loading执行")
				setTimeout(() => {
					this.loading = false;
				}, 3000);
			},
			share() {
				let formData = new FormData();
				formData.append('url', location.href.split('#')[0]);
				this.$api.post('/m/dz/getWxConfig', formData).then(response => {
					this.wechatConfig = response.data
					this.initWechatJsSDK()
				}).catch(error => {
					console.error("There was an error!", error);
				})
			},
			initWechatJsSDK() {
				wx.config({
					debug: false,
					appId: this.wechatConfig.appId,
					timestamp: this.wechatConfig.timestamp,
					nonceStr: this.wechatConfig.noncestr,
					signature: this.wechatConfig.signature,
					jsApiList: [
						'updateAppMessageShareData',
						'startRecord',
						'stopRecord',
						'onVoiceRecordEnd',
						'playVoice'
					]
				})

				wx.ready(() => {
					wx.updateAppMessageShareData({
						title: this.shareInfo.username+"的战术板",
						desc: '《'+this.shareInfo.taticName+'战术》\r\n战术谋局，赛场制敌，智慧战术，掌控全局！',
						link: 'https://www.douzhan.club/m/dz/warboard?id='+this.taticid,
						imgUrl: "http://www.douzhan.club/resources/articlelogo/2f105388-1972-4193-9273-e3546bbcaa94.jpg",
						success: () => {
							// alert("分享成功")
						},
						cancel: () => {
							// alert("取消成功")
						}
					})
				})

				// 在配置出错的情况下调用错误处理函数
				wx.error((err) => {
					console.error(err)
				})
			},
			onConfirm() {
				this.showDrawer = false;
				this.frameTime = this.selectedSecond
			},
			onCancel() {
				this.showDrawer = false;
			},
			adjustCanvasSize() {
				if (this.initialized) return;
				const canvas = this.$refs.canvasBoard;
				if (canvas) {
					const rect = canvas.getBoundingClientRect();
					this.canvasWidth = rect.width;
					this.canvasHeight = rect.height;

					canvas.width = this.canvasWidth;
					canvas.height = this.canvasHeight;
					const ctx = canvas.getContext('2d');

					this.ctx = ctx;
					ctx.strokeStyle = this.getColor;
					ctx.lineWidth = 2;
					ctx.stroke(); // 初始化画布

				}
				this.initialized = true;
			},
			startDrag(event, index) {

				this.player[this.playerPlayIndex][index].dragging = true;
				this.player[this.playerPlayIndex][index].pathMode = 1
				this.pathNowIndex = index
				this.player[this.playerPlayIndex][index].path = [];

			},
			stopDrag(event, index) {
				if ((this.frameList.length > 0 && this.playerPlayIndex != this.frameList.length - 1)) {
					return;
				}
				document.body.style.touchAction = 'auto';
				this.player[this.playerPlayIndex][index].dragging = false;
				this.toDo()

			},
			setBallPos(event, index) {
				if (this.isSharePage) {
					return;
				}
				document.body.style.touchAction = 'none';
				if (!this.player[this.playerPlayIndex][index].dragging || this.player[this.playerPlayIndex][index]
					.isBasket) return;
				const touch = event.touches[0];
				const mouseX = touch.clientX - 12;
				const mouseY = touch.clientY + 30;
				const canvas = this.$refs.canvasBoard;
				if (canvas) {
					const rect = canvas.getBoundingClientRect();
					const adjustedX = mouseX - rect.left;
					const adjustedY = mouseY - rect.top;
					if (touch.clientX < this.canvasWidth && touch.clientX > 0 && touch.clientY < this.canvasHeight + 50 &&
						touch.clientY > 50) {
						this.player[this.playerPlayIndex][index].currentPos = {
							x: adjustedX,
							y: adjustedY
						};
					}
					if (this.player[this.playerPlayIndex][index].hasBall) {
						this.basketball = {
							x: adjustedX,
							y: adjustedY
						}
					}
				}
			},
			drawPath(event, index) {
				if (this.isSharePage) {
					return;
				}
				document.body.style.touchAction = 'none';
				if (!this.player[this.playerPlayIndex][index].dragging || this.player[this.playerPlayIndex][index]
					.isBasket || (this.frameList.length > 0 && this.playerPlayIndex != this.frameList.length - 1)) return;

				const touch = event.touches[0];


				const mouseX = touch.clientX;
				const mouseY = touch.clientY;
				const canvas = this.$refs.canvasBoard;
				if (canvas) {
					const rect = canvas.getBoundingClientRect();
					const adjustedX = mouseX - rect.left;
					const adjustedY = mouseY - rect.top;

					const ctx = this.ctx;
					ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

					this.player[this.playerPlayIndex][index].path.push({
						x: adjustedX,
						y: adjustedY
					});
					this.moveBall(adjustedX, adjustedY, index);

					this.player[this.playerPlayIndex].forEach((ball, Findex) => {
						ctx.strokeStyle = this.getColor;
						ctx.lineWidth = 2;
						if (ball.path.length > 0 && !ball.hasBall) {
							ctx.beginPath();
							ctx.moveTo(ball.path[0].x, ball.path[0].y);
							for (const point of ball.path) {
								ctx.lineTo(point.x, point.y);
							}
							ctx.stroke();

							if (ball.path.length > 1) {
								const arrowStart = ball.path[ball.path.length - 2];
								const arrowEnd = ball.path[ball.path.length - 1];

								if (ball.pathMode === 2) {
									this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								} else {
									this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								}
							}
						} else if (ball.path.length > 0 && ball.hasBall) {
							this.setBallLine(Findex);
						}
					});

					this.buttonPos = `left: ${adjustedX - 25}px; top: ${adjustedY + 60}px;`;
					this.buttonShow = true;
				}

			},
			drawAllPath() {

				const canvas = this.$refs.canvasBoard;
				if (canvas) {
					const ctx = this.ctx;
					ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
					this.player[this.playerPlayIndex].forEach((ball, Findex) => {
						ctx.strokeStyle = this.getColor;
						ctx.lineWidth = 2;
						if (ball.path.length > 0 && !ball.hasBall) {
							ctx.beginPath();
							ctx.moveTo(ball.path[0].x, ball.path[0].y);
							for (const point of ball.path) {
								ctx.lineTo(point.x, point.y);
							}
							ctx.stroke();

							if (ball.path.length > 1) {
								const arrowStart = ball.path[ball.path.length - 3];
								const arrowEnd = ball.path[ball.path.length - 1];

								if (ball.pathMode === 2) {
									this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								} else {
									this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								}
							}
						} else if (ball.path.length > 0 && ball.hasBall) {
							this.setBallLine(Findex);
						}
					});
				}

			},
			deletePath(index) {

				this.buttonShow = false;
				const canvas = this.$refs.canvasBoard;
				if (canvas) {
					// const rect = canvas.getBoundingClientRect();
					const ctx = this.ctx;
					ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
					this.player[this.playerPlayIndex][index].path = [];
					this.player[this.playerPlayIndex].forEach((ball, ballidx) => {
						if (ball.path.length > 0) {
							if (ball.hasBall) {
								this.setBallLine(ballidx);
							} else {
								ctx.beginPath();
								ctx.moveTo(ball.path[0].x, ball.path[0].y);
								for (const point of ball.path) {
									ctx.lineTo(point.x, point.y);
								}
								ctx.stroke();
								if (ball.path.length > 1) {
									const arrowStart = ball.path[ball.path.length - 2];
									const arrowEnd = ball.path[ball.path.length - 1];
									if (ball.pathMode === 2) {
										this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd
										.y);
									} else {
										this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
									}
								}
							}
						}
					});
				}
			},
			hidePath() {
				let item = this.frameLine.find(item => item.frameIdx === this.playerPlayIndex)
				item.show = false
				const ctx = this.ctx;
				ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

			},
			showPath() {
				let item = this.frameLine.find(item => item.frameIdx === this.playerPlayIndex)
				item.show = true
				this.drawAllPath()
			},
			moveBall(x, y, index) {
				const style = `left: ${x - 12}px; top: ${y + 30}px;`;

				x = x - 12
				y = y + 30
				if (x < this.canvasWidth && x > 0 && y < this.canvasHeight + 50 && y > 50) {
					this.$set(this.player[this.playerPlayIndex][index], 'style', style);
					this.player[this.playerPlayIndex][index].currentPos = {
						x,
						y
					};
				}

			},
			sleep(ms) {
				return new Promise(resolve => setTimeout(resolve, ms));
			},
			async moveBallAlongPath() {
				let basketFrame = this.basketFrame;
				if (this.isEnd) {
					this.chooseFrame(0)
					const voiceItem = this.voiceList.find(item => item.frameIdx === 0)
					if (voiceItem) {
						this.playRecording()
					}

					this.frameTime = 0
					this.timeIndex = 0
					this.playerPlayIndex = 0;
					this.scrollindex = 0;
					this.currentScrollLeft = 0; // 初始化当前滚动位置
					this.pauseScrollLeft = 0;
					let currentFrameConfig = basketFrame.find(frame => frame.frameIdx === 0);
					if (currentFrameConfig.front.startIndex !== currentFrameConfig.front.endIndex) {
						currentFrameConfig.front.hasPaused = true
						this.basketBallMoveCore('front');
						await this.sleep(1000);
					}
				}
				this.isPlay = true
				this.isEnd = false
				const frameList = this.frameList;

				const totalFrames = frameList.length;
				this.basketSite = null
				this.basketDrawer = false



				const scrollNext = () => {
					if (!this.isEnd) {
						const duration = this.frameList.reduce((accumulator, currentValue) => accumulator +
							currentValue, 0);
						const distance = duration * 60;
						this.moveCore()
						this.scrollLeft(duration, distance, this.pauseScrollLeft, () => {
							this.scrollindex++;
							if (this.playerPlayIndex < totalFrames - 1) {
								this.playerPlayIndex++;
							}
							this.currentScrollLeft += distance; // 更新当前滚动位置
							scrollNext();

						});
					} else {
						this.isPlay = false
						clearInterval(this.timer);
						this.isEnd = true
						this.pauseScrollLeft = 0
					}
				};

				scrollNext(); // 开始第一个滚动

			},

			scrollLeft(duration, distance, startScrollLeft, callback) {
				this.basketFrame.forEach(item => {
					item.front.hasPaused = false
					item.after.hasPaused = false
				})

				this.voiceList.forEach(item => {
					item.hasPaused = false
				})

				const container = this.$refs.scrollContainer;
				let startTime = performance.now()
				let initialScrollLeft = startScrollLeft;

				if (this.totalDistance == 0) {
					this.totalDistance = this.frameList[0] * 60
				}
				const animateScroll = (currentTime) => {
					if (!this.isPlay) {
						this.pauseTime += currentTime - startTime;
						this.pauseScrollLeft = container.scrollLeft;
						return;
					}
					const elapsed = currentTime - startTime;
					const progress = Math.min(elapsed / (duration * 1000), 1);
					const scrollDistance = progress * distance;



					container.scrollLeft = initialScrollLeft + scrollDistance; // 累加之前的滚动距离
					this.timeIndex = Math.floor(container.scrollLeft / 60)
					if (container.scrollLeft >= this.totalDistance) {
						if (this.playerPlayIndex + 1 <= this.frameList.length - 1) {
							let currentBasketFrame = this.basketFrame.find(item => item.frameIdx === this
								.playerPlayIndex);
							let currentVoiceFrame = this.voiceList.find(item => item.frameIdx === this
								.playerPlayIndex && item.hasPaused === false);

							let gapTime = 0
							if (currentBasketFrame.after.hasPaused === false || currentVoiceFrame) {

								this.isPlay = false;
								if (currentBasketFrame.after.startIndex !== currentBasketFrame.after.endIndex &&
									currentBasketFrame.after.hasPaused === false) {
									this.basketBallMoveCore('after');
									gapTime += 1000
								}
								if (currentVoiceFrame) {
									if (currentVoiceFrame.time > this.frameList[this.playerPlayIndex] &&
										currentVoiceFrame.hasPaused === false) {
										gapTime += (currentVoiceFrame.time - this.frameList[this.playerPlayIndex]) *
											1000
									}
								}


								setTimeout(() => {
									startTime = performance.now();
									initialScrollLeft = container.scrollLeft;
									this.isPlay = true;
									requestAnimationFrame(animateScroll);
								}, gapTime);

								currentBasketFrame.after.hasPaused = true;
								if (currentVoiceFrame) {
									currentVoiceFrame.hasPaused = true;
								}

								return;
							}
							this.playerPlayIndex++;
							this.chooseFrame(this.playerPlayIndex);
							const voiceItem = this.voiceList.find(item => item.frameIdx === this.playerPlayIndex)
							if (voiceItem) {
								this.playRecording()
							}

							this.totalDistance += this.frameList[this.playerPlayIndex] * 60;
							let currentBasketFrame1 = this.basketFrame.find(item => item.frameIdx === this
								.playerPlayIndex);
							if (currentBasketFrame1) {
								// 检查并暂停以执行动作
								if (currentBasketFrame1.front.startIndex !== currentBasketFrame1.front.endIndex &&
									currentBasketFrame1.front.hasPaused === false) {
									this.isPlay = false;
									this.chooseFrame(this.playerPlayIndex);
									this.basketBallMoveCore('front');
									setTimeout(() => {
										startTime = performance.now();
										initialScrollLeft = container.scrollLeft;
										this.isPlay = true;
										requestAnimationFrame(animateScroll);
										this.onMove = false

									}, 1000); // 暂停 1 秒

									currentBasketFrame1.front.hasPaused = true;


									return;
								}
							}

						} else {
							const currentBasketFrame = this.basketFrame.find(item => item.frameIdx === this
								.playerPlayIndex);
							if (currentBasketFrame) {

								if (currentBasketFrame.after.startIndex !== currentBasketFrame.after.endIndex) {
									this.basketBallMoveCore('after');
								}
							}
						}

						if (this.playerPlayIndex < this.frameList.length) {
							this.onMove = false
						}
					}


					if (container.scrollLeft < this.frameList.reduce((accumulator, currentValue) => accumulator +
							currentValue, 0) * 60) {

						requestAnimationFrame(animateScroll);
						this.moveCore()


					} else {
						this.timeIndex++
						this.isEnd = true
						this.totalDistance = 0
						if (callback) {
							callback();
						}
					}
				};

				requestAnimationFrame(animateScroll); // 开始动画
			},

			moveCore() {
				if (this.onMove === true) {
					return;
				}
				this.onMove = true
				this.player[this.playerPlayIndex].forEach((ball, index) => {
					let i = 0;
					const path = ball.path;
					if (!path || path.length < 2) {
						return;
					}

					const totalSegments = path.length - 1;
					let totalLength = 0;
					const segmentLengths = [];

					for (let j = 0; j < totalSegments; j++) {
						const start = path[j];
						const end = path[j + 1];
						if (start && end) {
							const dx = end.x - start.x;
							const dy = end.y - start.y;
							const segmentLength = Math.sqrt(dx * dx + dy * dy);
							segmentLengths.push(segmentLength);
							totalLength += segmentLength;
						}
					}

					let startTime = null;
					let pausedTime = 0;
					const move = (timestamp) => {
						if (!this.isPlay) {
							if (!pausedTime) pausedTime = timestamp;
							requestAnimationFrame(move);
							return;
						}

						if (pausedTime) {
							startTime += (timestamp - pausedTime);
							pausedTime = 0;
						}

						if (!startTime) startTime = timestamp;
						const elapsedTime = (timestamp - startTime) / 1000; // 转换为秒
						const progress = elapsedTime / this.frameList[this.playerPlayIndex]; // 计算进度	
						if (progress < 1) {
							let currentLength = 0;
							for (i = 0; i < totalSegments; i++) {
								if (currentLength + segmentLengths[i] >= totalLength * progress) {
									break;
								}
								currentLength += segmentLengths[i];
							}

							const segmentStart = path[i];
							const segmentEnd = path[i + 1];
							if (segmentStart && segmentEnd) {
								const segmentProgress = (totalLength * progress - currentLength) /
									segmentLengths[i];
								const moveX = segmentStart.x + (segmentEnd.x - segmentStart.x) *
									segmentProgress;
								const moveY = segmentStart.y + (segmentEnd.y - segmentStart.y) *
									segmentProgress;

								ball.currentPos = {
									x: moveX,
									y: moveY
								};
								this.moveBall(moveX, moveY, index);

								requestAnimationFrame(move);
							}
						} else {
							ball.currentPos = path[totalSegments];
							this.moveBall(path[totalSegments].x, path[totalSegments].y, index);

							if (index === this.player[this.playerPlayIndex].length - 1) {
								this.isPlay = false;
								this.onMove = false
							}
						}
					};

					requestAnimationFrame(move);

				});
			},
			basketBallMoveCore(m) {
				if (this.timer) {
					clearInterval(this.timer);
				}
				const basketballItem = this.basketFrame.find(item => item.frameIdx === this.playerPlayIndex);
				if (basketballItem && !this.basketdragging) {
					this.basketdragging = true;
					let startPos = null
					let endPos = null
					let playerIndex = 0
					if (this.playerPlayIndex != 0) {
						playerIndex = this.playerPlayIndex
					}
					let sp = this.player[playerIndex][basketballItem[m].startIndex]
					if (sp.path.length > 0 && m == 'after') {
						startPos = sp.path[sp.path.length - 1]
						let x = sp.path[sp.path.length - 1].x - 12
						let y = sp.path[sp.path.length - 1].y + 30
						startPos = {
							x: x,
							y: y
						}
					} else {
						startPos = sp.currentPos
					}
					let ep = this.player[playerIndex][basketballItem[m].endIndex]
					if (ep.path.length > 0 && m == 'after') {
						let x = ep.path[ep.path.length - 1].x - 12
						let y = ep.path[ep.path.length - 1].y + 30
						endPos = {
							x: x,
							y: y
						}
					} else {
						if (ep.path.length > 0) {
							let x = ep.path[0].x - 12
							let y = ep.path[0].y + 30
							endPos = {
								x: x,
								y: y
							}
						} else {
							endPos = ep.currentPos
						}


					}

					const x1 = startPos.x;
					const y1 = startPos.y;
					const x2 = endPos.x;
					const y2 = endPos.y;
					const duration = 900;
					const startTime = performance.now();
					const moveBall = () => {
						const elapsed = performance.now() - startTime;
						const progress = elapsed / duration;
						if (progress < 1) {
							const x = x1 + (x2 - x1) * progress;
							const y = y1 + (y2 - y1) * progress;
							// 更新小球位置
							this.basketball.x = x;
							this.basketball.y = y;

							requestAnimationFrame(moveBall);
						} else {
							// 动画结束时设置最终位置
							this.basketball.x = x2;
							this.basketball.y = y2;
							this.basketdragging = false;
							this.setHasballPlayer(basketballItem[m].endIndex)
						}
					};

					moveBall();
				}
			},
			setHasballPlayer(index) {
				let player = this.player[this.playerPlayIndex]
				player.forEach((item, i) => {
					item.hasBall = false
					if (i === index) {
						item.hasBall = true
					}
				})
			},
			drawArrow(ctx, fromx, fromy, tox, toy) {
				const headlen = 10;
				const angle = Math.atan2(toy - fromy, tox - fromx);
				ctx.beginPath();
				ctx.moveTo(tox, toy);
				ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
				ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
				ctx.closePath();
				ctx.fillStyle = this.getColor;
				ctx.fill();


			},
			setLineMode(index) {
				this.player[this.playerPlayIndex][index].pathMode == 1 ? this.player[this.playerPlayIndex][index]
					.pathMode = 2 : this.player[this.playerPlayIndex][index].pathMode = 1
				let ctx = this.ctx
				ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
				this.player[this.playerPlayIndex].forEach((ball, index) => {
					ctx.strokeStyle = this.getColor;
					ctx.lineWidth = 2;
					if (ball.path.length > 0 && ball.hasBall === false) {
						ctx.beginPath();
						ctx.moveTo(ball.path[0].x, ball.path[0].y);
						for (const point of ball.path) {
							ctx.lineTo(point.x, point.y);
						}
						ctx.stroke();
						if (ball.path.length > 1) {
							const arrowStart = ball.path[ball.path.length - 2];
							const arrowEnd = ball.path[ball.path.length - 1];
							if (ball.pathMode == 2) {
								this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
							} else {
								this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
							}

						}
					} else if (ball.path.length > 0 && ball.hasBall) {
						this.setBallLine(index)
					}
				});


			},
			drawShortLine(ctx, fromx, fromy, tox, toy) {
				const headlen = 10;
				const angle = Math.atan2(toy - fromy, tox - fromx);
				ctx.beginPath();
				const lineAngle = angle + Math.PI / 2; // 垂直于线的角度
				const tx1 = tox - headlen * Math.cos(lineAngle);
				const ty1 = toy - headlen * Math.sin(lineAngle);
				const tx2 = tox + headlen * Math.cos(lineAngle);
				const ty2 = toy + headlen * Math.sin(lineAngle);
				ctx.moveTo(tx1, ty1);
				ctx.lineTo(tx2, ty2);
				ctx.strokeStyle = this.getColor;
				ctx.lineWidth = 2;
				ctx.stroke();
			},
			drawWavyPath(event, index) {
				document.body.style.touchAction = 'none';
				if (!this.player[this.playerPlayIndex][index].dragging) return;

				const touch = event.touches[0];
				const mouseX = touch.clientX;
				const mouseY = touch.clientY;

				const canvas = this.$refs.canvasBoard;
				if (canvas) {
					const rect = canvas.getBoundingClientRect();
					const adjustedX = mouseX - rect.left;
					const adjustedY = mouseY - rect.top;
					const ctx = this.ctx;

					ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

					let path = this.player[this.playerPlayIndex][index].path;
					this.player[this.playerPlayIndex].forEach((ball) => {
						ctx.strokeStyle = this.getColor;
						ctx.lineWidth = 2;

						if (ball.path.length > 0 && ball.hasBall === false) {
							ctx.beginPath();
							ctx.moveTo(ball.path[0].x, ball.path[0].y);
							for (const point of ball.path) {
								ctx.lineTo(point.x, point.y);
							}
							ctx.stroke();

							if (ball.path.length > 1) {
								const arrowStart = ball.path[ball.path.length - 2];
								const arrowEnd = ball.path[ball.path.length - 1];
								if (ball.pathMode === 2) {
									this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								} else {
									this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								}
							}
						}
					});

					path.push({
						x: adjustedX,
						y: adjustedY
					});

					ctx.beginPath();
					let currentStart = path[0];
					for (let i = 0; i < path.length; i++) {
						const currentPoint = path[i];
						const dx = currentPoint.x - currentStart.x;
						const dy = currentPoint.y - currentStart.y;
						const distance = Math.sqrt(dx * dx + dy * dy);

						if (distance > 20) {
							this.drawSineCurve(currentStart, currentPoint);
							currentStart = currentPoint;
						}
					}

					if (path.length > 1) {
						const lastPoint = path[path.length - 1];
						const secondLastPoint = path[path.length - 2];
						this.drawArrow(ctx, secondLastPoint.x, secondLastPoint.y, lastPoint.x, lastPoint.y);
					}

					const lastPoint = path[path.length - 1];
					const ballRadius = 25;

					for (let i = 0; i < this.player[this.playerPlayIndex].length; i++) {
						const ball = this.player[this.playerPlayIndex][i];
						const dx = ball.currentPos.x + ballRadius - lastPoint.x;
						const dy = ball.currentPos.y - ballRadius - lastPoint.y;
						const distance = Math.sqrt(dx * dx + dy * dy);

						if (distance <= ballRadius && i !== this.pathNowIndex && !ball.isBasket) {
							this.showPass = true;
							this.showThrow = false;
							break;
						} else if (distance <= ballRadius && i !== this.pathNowIndex && ball.isBasket) {
							this.showPass = false;
							this.showThrow = true;
							break;
						} else if (distance > ballRadius && i !== this.pathNowIndex) {
							this.showPass = false;
							this.showThrow = false;
						}
					}

					ctx.stroke();
					this.buttonPos = `left: ${adjustedX + 25}px; top: ${adjustedY + 60}px;`;

					this.buttonShow = true;
					this.player[this.playerPlayIndex][index].passMode = 1;
				}
			},
			drawPassline(index, ev = true) {

				if (this.player[this.playerPlayIndex][index].passMode == 1) {
					const path = this.player[this.playerPlayIndex][index].path
					const startPos = path[0]
					const endPos = path[path.length - 1]
					const ctx = this.ctx
					const lastPoint = path[path.length - 1];
					const secondLastPoint = path[path.length - 2];
					ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
					ctx.beginPath();
					ctx.setLineDash([5, 5]); // 设置虚线样式
					ctx.moveTo(startPos.x, startPos.y);
					ctx.lineTo(endPos.x, endPos.y);

					ctx.stroke();
					this.drawArrow(ctx, secondLastPoint.x, secondLastPoint.y, lastPoint.x, lastPoint.y);
					ctx.setLineDash([]);

					this.player[this.playerPlayIndex].forEach((ball) => {
						ctx.strokeStyle = this.getColor;
						ctx.lineWidth = 2;


						if (ball.path.length > 0 && ball.hasBall === false) {
							ctx.beginPath();
							ctx.moveTo(ball.path[0].x, ball.path[0].y);
							for (const point of ball.path) {
								ctx.lineTo(point.x, point.y);
							}
							ctx.stroke();

							if (ball.path.length > 1) {
								const arrowStart = ball.path[ball.path.length - 2];
								const arrowEnd = ball.path[ball.path.length - 1];
								if (ball.pathMode == 2) {
									this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								} else {
									this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								}

							}
						}
					});
					if (ev) {
						this.player[this.playerPlayIndex][index].passMode = 2
					}

				} else {
					const canvas = this.$refs.canvasBoard;
					if (canvas) {
						const ctx = this.ctx;
						ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
						let path = this.player[this.playerPlayIndex][index].path;

						this.player[this.playerPlayIndex].forEach((ball) => {
							ctx.strokeStyle = this.getColor;
							ctx.lineWidth = 2;

							if (ball.path.length > 0 && ball.hasBall === false) {
								ctx.beginPath();
								ctx.moveTo(ball.path[0].x, ball.path[0].y);
								for (const point of ball.path) {
									ctx.lineTo(point.x, point.y);
								}
								ctx.stroke();

								if (ball.path.length > 1) {
									const arrowStart = ball.path[ball.path.length - 2];
									const arrowEnd = ball.path[ball.path.length - 1];
									if (ball.pathMode === 2) {
										this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd
										.y);
									} else {
										this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
									}
								}
							}
						});

						ctx.beginPath();
						let currentStart = path[0];
						for (let i = 0; i < path.length; i++) {
							const currentPoint = path[i];
							const dx = currentPoint.x - currentStart.x;
							const dy = currentPoint.y - currentStart.y;
							const distance = Math.sqrt(dx * dx + dy * dy);

							if (distance > 12) {
								this.drawSineCurve(currentStart, currentPoint);
								currentStart = currentPoint;
							}
						}

						if (path.length > 1) {
							const lastPoint = path[path.length - 1];
							const secondLastPoint = path[path.length - 2];
							this.drawArrow(ctx, secondLastPoint.x, secondLastPoint.y, lastPoint.x, lastPoint.y);
						}

						const lastPoint = path[path.length - 1];
						const ballRadius = 12; // 假设每个小球的半径是 10

						for (let i = 0; i < this.player[this.playerPlayIndex].length; i++) {
							const ball = this.player[this.playerPlayIndex][i];
							const dx = ball.currentPos.x + 12 - lastPoint.x;
							const dy = ball.currentPos.y - 12 - lastPoint.y;
							const distance = Math.sqrt(dx * dx + dy * dy);

							if (distance <= ballRadius && i !== this.pathNowIndex && ball.isBasket === false) {
								this.showPass = true;
								this.showThrow = false;
								break;
							} else if (distance <= ballRadius && i !== this.pathNowIndex && ball.isBasket === true) {
								this.showPass = false;
								this.showThrow = true;
								break;
							} else if (distance > ballRadius && i !== this.pathNowIndex) {
								this.showPass = false;
								this.showThrow = false;
							}
						}

						ctx.stroke();
						this.buttonShow = true;
					}
					if (ev) {
						this.player[this.playerPlayIndex][index].passMode = 1
					}
				}

			},
			setBallLine(index) {
				if (this.player[this.playerPlayIndex][index].passMode == 2) {
					const path = this.player[this.playerPlayIndex][index].path
					const startPos = path[0]
					const endPos = path[path.length - 1]
					const ctx = this.ctx
					const lastPoint = path[path.length - 1];
					const secondLastPoint = path[path.length - 2];
					ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
					ctx.beginPath();
					ctx.setLineDash([5, 5]); // 设置虚线样式
					ctx.moveTo(startPos.x, startPos.y);
					ctx.lineTo(endPos.x, endPos.y);

					ctx.stroke();
					this.drawArrow(ctx, secondLastPoint.x, secondLastPoint.y, lastPoint.x, lastPoint.y);
					ctx.setLineDash([]);

					this.player[this.playerPlayIndex].forEach((ball) => {
						ctx.strokeStyle = this.getColor;
						ctx.lineWidth = 2;


						if (ball.path.length > 0 && ball.hasBall === false) {
							ctx.beginPath();
							ctx.moveTo(ball.path[0].x, ball.path[0].y);
							for (const point of ball.path) {
								ctx.lineTo(point.x, point.y);
							}
							ctx.stroke();

							if (ball.path.length > 1) {
								const arrowStart = ball.path[ball.path.length - 2];
								const arrowEnd = ball.path[ball.path.length - 1];
								if (ball.pathMode == 2) {
									this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								} else {
									this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
								}

							}
						}
					});

				} else {
					const canvas = this.$refs.canvasBoard;
					if (canvas) {
						// const rect = canvas.getBoundingClientRect();
						const ctx = this.ctx;

						ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

						// let path = this.player[index].path;

						this.player[this.playerPlayIndex].forEach((ball) => {
							ctx.strokeStyle = this.getColor;
							ctx.lineWidth = 2;

							if (ball.path.length > 0) {
								ctx.beginPath();
								ctx.moveTo(ball.path[0].x, ball.path[0].y);
								for (const point of ball.path) {
									ctx.lineTo(point.x, point.y);
								}
								ctx.stroke();

								if (ball.path.length > 1) {
									const arrowStart = ball.path[ball.path.length - 2];
									const arrowEnd = ball.path[ball.path.length - 1];
									if (ball.pathMode === 2) {
										this.drawShortLine(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd
										.y);
									} else {
										this.drawArrow(ctx, arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y);
									}
								}
							}
						});


						this.buttonShow = true;
					}
				}
			},

			drawSineCurve(start, end) {
				const startPoint = start;
				const endPoint = end;
				const waveHeight = 5; // 正弦波的振幅
				const waveLength = 1; // 每个波长的长度

				const ctx = this.ctx;

				const dx = endPoint.x - startPoint.x;
				const dy = endPoint.y - startPoint.y;
				const distance = Math.sqrt(dx * dx + dy * dy);
				const angle = Math.atan2(dy, dx);

				const steps = Math.ceil(distance / waveLength);

				ctx.beginPath();
				ctx.moveTo(startPoint.x, startPoint.y);

				for (let i = 1; i <= steps; i++) {
					const t = i / steps;
					const x = startPoint.x + t * dx;
					const yOffset = Math.sin(t * Math.PI * 2) * waveHeight;

					// 旋转偏移量
					const rotatedX = x + yOffset * Math.sin(angle);
					const y = startPoint.y + t * dy - yOffset * Math.cos(angle);

					ctx.lineTo(rotatedX, y);
					// const ball = this.player[this.pathNowIndex]
					// const arrowStart = ball.path[ball.path.length - 2];
					// const arrowEnd = ball.path[ball.path.length - 1];
					// 
				}
				ctx.stroke();
			},
			setFormation(index) {
				const item = this.formationList[index]
				const pos = item.pos
				for (let i = 0; i < pos.length; i++) {
					this.player[this.playerPlayIndex][i].currentPos = pos[i]
				}

			},
			setPlayer() {

				if (this.playernum == 3) {
					this.playernum = 5
				} else {
					this.playernum -= 1
				}
			},
			setBasketball(event) {
				if (this.isSharePage) {
					return;
				}
				if ((this.frameList.length > 0 && this.playerPlayIndex != this.frameList.length - 1)) {
					return;
				}
				event.stopPropagation();
				this.basketdragging = true;
				const touch = event.touches[0];

				const canvas = this.$refs.canvasBoard;
				const rect = canvas.getBoundingClientRect();
				const mouseX = touch.clientX;
				const mouseY = touch.clientY;
				this.basketball.x = mouseX - rect.left - 20;
				this.basketball.y = mouseY - rect.top + 35;
			},
			onBasketballDrag(event) {
				if (this.isSharePage) {
					return;
				}
				if (this.basketdragging) {
					const touch = event.touches[0];
					const mouseX = touch.clientX;
					const mouseY = touch.clientY;
					const canvas = this.$refs.canvasBoard;

					if (canvas) {
						const rect = canvas.getBoundingClientRect();
						const adjustedX = mouseX - rect.left - 20;
						const adjustedY = mouseY - rect.top + 35;
						this.basketball.x = adjustedX;
						this.basketball.y = adjustedY;
					}
				}
			},
			stopBasketballDrag() {
				if (this.isSharePage) {
					return;
				}
				if ((this.frameList.length > 0 && this.playerPlayIndex != this.frameList.length - 1)) {
					return;
				}
				this.basketdragging = false;

				this.player[this.playerPlayIndex].forEach((player, index) => {
					if (player.hasBall === false) {
						const distance = this.calculateDistance(this.basketball, player);

						if (distance < 30 && player.ishome) {
							this.executeAction(index);
						}
					}

				});
			},
			calculateDistance(ball, player) {
				const dx = ball.x - player.currentPos.x;
				const dy = ball.y - player.currentPos.y;
				return Math.sqrt(dx * dx + dy * dy);
			},
			executeAction(playerIndex) {
				let startIndex = null
				let pathLength = 0
				this.player[this.playerPlayIndex].forEach((player, index) => {
					if (player.hasBall) {
						startIndex = index
					}
					pathLength += player.path.length
					player.hasBall = false
				});
				this.player[this.playerPlayIndex][playerIndex].hasBall = true
				let basketFrame = this.basketFrame.find(item => item.frameIdx === this.playerPlayIndex).front
				if (Object.keys(basketFrame).length > 0 || pathLength > 0) {
					basketFrame = this.basketFrame.find(item => item.frameIdx === this.playerPlayIndex).after
				}
				this.$set(basketFrame, 'startIndex', startIndex);
				this.$set(basketFrame, 'endIndex', playerIndex);
				this.toDo()

			},
			setLine() {
				if (this.playerLine) {
					this.playerLine = false
					const ctx = this.ctx;
					ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

				} else {
					this.playerLine = true
					this.drawAllPath()
				}

				this.frameLine.forEach(item => {
					item.show = this.playerLine
				})
			},
			setSec(s) {
				Vue.set(this.frameList, this.playerPlayIndex, s);
			},
			deleteframe() {
				this.toDo()
				this.frameList.splice(this.playerPlayIndex, 1);
				if (this.playerPlayIndex > 0) {
					this.player.splice(this.playerPlayIndex, 1);
					this.chooseFrame(this.frameList.length - 1);
				} else {
					this.player[0].forEach(item => {
						item.path = []
						const ctx = this.ctx;
						ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
					})
				}



			},
			setLineColor(c) {
				this.linecolor = c
				this.hidePath()
				this.showPath()

			},

			showSecMenu(d) {
				if (d == 0 && this.showDrawer || d == 1 && this.showLineDrawer || d == 2 && this.showcolorDrawer) {
					this.showDrawer = false;
					this.showLineDrawer = false;
					this.showcolorDrawer = false;
					return;
				}
				this.showDrawer = false;
				this.showLineDrawer = false;
				this.showcolorDrawer = false;
				if (d == 0) {
					this.showDrawer = true
				}

				if (d == 1) {
					this.showLineDrawer = true
				}

				if (d == 2) {
					this.showcolorDrawer = true
				}
			},
			addNewFrame(t = 3) {
				// const lastFrame = this.player[this.frameList.length - 1]
				// let isMove = false
				// lastFrame.forEach(item=> {
				// 	if (item.path.length > 0) {
				// 		isMove = true
				// 	}
				// })

				if (this.frameList.length > 0) {

					const player = this.player[this.player.length - 1]
					const basketFrame = this.basketFrame.find(item => item.frameIdx == this.frameList.length - 1)
					const _ = require('lodash');
					let tmpPlayer = _.cloneDeep(player);
					player.forEach((item, index) => {

						if (item.path.length == 0) {
							tmpPlayer[index].currentPos = item.currentPos
						} else {
							tmpPlayer[index].currentPos = {
								x: item.path[item.path.length - 1].x - 12,
								y: item.path[item.path.length - 1].y + 30
							}
						}
					});

					tmpPlayer.forEach(item => {
						item.path = []
					});

					if (basketFrame.after.startIndex !== basketFrame.after.endIndex) {
						tmpPlayer[basketFrame.after.startIndex].hasBall = false
						tmpPlayer[basketFrame.after.endIndex].hasBall = true
					}
					this.player.push(tmpPlayer)
				}
				this.frameList.push(t)
				this.basketFrame.push({
					frameIdx: this.frameList.length - 1,
					front: {},
					after: {}
				})
				this.frameLine.push({
					frameIdx: this.frameList.length - 1,
					show: true
				})
				this.chooseFrame(this.frameList.length - 1)
				this.toDo()

			},
			chooseFrame(index) {
				this.basketDrawer = false
				this.playerPlayIndex = index
				if (this.player[index].length > 0) {
					const ctx = this.ctx;
					ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
					let afterFrame = this.basketFrame.find(item => item.frameIdx === index)
					if (afterFrame.after.startIndex !== afterFrame.after.endIndex) {
						this.player[index].forEach((item, i) => {
							item.hasBall = false
							if (i === afterFrame.after.startIndex) {
								item.hasBall = true
							}
						});
					}
					this.player[index].forEach((item, i) => {
						if (item.path.length > 0) {
							// item.currentPos = item.path[0]
							this.moveBall(item.path[0].x, item.path[0].y, i)
						} else {
							this.moveBall(item.currentPos.x + 12, item.currentPos.y - 30, i)
						}
					});

					if (this.playerLine && this.frameLine[this.playerPlayIndex].show) {
						this.drawAllPath()
					}


				}
			},
			checkBasketball(index, m) {
				let item = this.basketFrame.find(item => item.frameIdx === index);
				return item[m].startIndex !== item[m].endIndex ? true : false;
			},
			setBasketVoice(index) {
				this.chooseFrame(index)
				this.selectVideo = true
				this.showVoice = true
				this.basketDrawer = false
				this.showDrawer = false
				this.showLineDrawer = false
				this.showcolorDrawer = false
			},
			getBasketballContent(index, item) {
				if (this.checkBasketball(index)) {
					return '<i class="iconfont icon-lanqiu1"></i>';
				} else {
					return item + 's';
				}
			},
			setBasketballSite(m, index) {
				this.chooseFrame(index)

				this.basketDrawer = !this.basketDrawer
				this.showDrawer = false
				this.showLineDrawer = false
				this.showcolorDrawer = false
				this.showVoice = false
				this.basketSite = m

			},
			updateSite(m) {

				const idx = this.playerPlayIndex
				const frame = this.basketFrame.find(item => item.frameIdx === idx)
				let cloneBox = null
				const checkOther = Object.keys(frame[m]).length

				if (this.basketSite != m && checkOther <= 0) {
					const _ = require('lodash');
					let cloneSite = null
					m == 'front' ? cloneSite = 'after' : cloneSite = 'front'
					cloneBox = _.cloneDeep(frame[cloneSite]);
					frame[this.basketSite] = {}
					frame[m] = cloneBox
					this.basketSite = m

					if (m == 'after') {
						const hasBallPlayer = frame['after'].startIndex
						this.player[this.playerPlayIndex].forEach((item, index) => {
							item.hasBall = false
							if (hasBallPlayer == index) {
								item.hasBall = true
							}
						})
					}
				}
			},
			deleteSite() {
				const idx = this.playerPlayIndex
				const site = this.basketSite
				const frame = this.basketFrame.find(item => item.frameIdx === idx)
				frame[site] = {}
				this.basketDrawer = false
			},
			deleteVoice() {
				const idx = this.playerPlayIndex
				console.log(this.voiceList)
				this.voiceList = this.voiceList.filter(item => item.frameIdx !== idx)
				this.showVoice = false
			},
			showBasketFrame() {
				this.isbasketFrame = !this.isbasketFrame
				const _ = require('lodash');
				if (this.isbasketFrame && this.clonebasketFrame.length > 0) {
					this.basketFrame = _.cloneDeep(this.clonebasketFrame);
					this.clonebasketFrame = []
				} else {
					this.clonebasketFrame = _.cloneDeep(this.basketFrame);
					this.basketFrame.forEach(item => {
						item.front = {}
						item.after = {}
					})
				}

			},
			checkLineVisib() {
				let item = this.frameLine.find(item => item.frameIdx === this.playerPlayIndex)
				return item.show
			},
			toDo() {
				const _ = require('lodash');
				let obj = {
					player: _.cloneDeep(this.player),
					basketFrame: _.cloneDeep(this.basketFrame),
					frameList: _.cloneDeep(this.frameList),
					frameIndex: _.cloneDeep(this.playerPlayIndex),
					voiceList: _.cloneDeep(this.voiceList)
				};
				this.todoList.push(obj);
			},

			reDo() {
				if (this.redoList.length > 0) {
					const _ = require('lodash');
					const retaskIdx = this.redoList.length - 1;
					const redotask = _.cloneDeep(this.redoList[retaskIdx]);
					this.redoList.splice(retaskIdx, 1);

					// 恢复状态
					this.playerPlayIndex = _.cloneDeep(redotask.frameIndex);
					this.basketFrame = _.cloneDeep(redotask.basketFrame);
					this.frameList = _.cloneDeep(redotask.frameList);
					this.player = _.cloneDeep(redotask.player);
					this.voiceList = _.cloneDeep(redotask.voiceList);
					this.drawAllPath();
					this.toDo();
				}
			},

			toBackStep() {

				if (this.todoList.length > 1) {
					const _ = require('lodash');
					const taskIdx = this.todoList.length - 1;
					const backtask = _.cloneDeep(this.todoList[taskIdx - 1]);
					const redotask = _.cloneDeep(this.todoList[taskIdx]);
					this.todoList.splice(taskIdx, 1);
					this.redoList.push(redotask);

					// 恢复状态
					this.playerPlayIndex = _.cloneDeep(backtask.frameIndex);
					this.basketFrame = _.cloneDeep(backtask.basketFrame);
					this.frameList = _.cloneDeep(backtask.frameList);
					this.player = _.cloneDeep(backtask.player);
					this.voiceList = _.cloneDeep(backtask.voiceList);
					this.drawAllPath();
				}
			},
			setMessageSize() {
				this.messageSize == 60 ? this.messageSize =100 : this.messageSize = 60
			},
			async startRecording() {
				console.log("开始录音",this.recorder)
				if (this.isRecording) {
				        return; // 防止重复启动录音
				      }
			      try {
			        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
			        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			        this.recorder = new Recorder(this.audioContext);
			        
			        this.recorder.init(stream);
			        this.recorder.start();
					this.isRecording = true;
			      } catch (error) {
			        console.error('录音启动失败:', error);
			      }
			},
			async stopRecording() {
				console.log("结束录音",this.recorder)
				this.touchSecond = 0
				if (!this.isRecording) {
				        return;
				}
			      try {
			        const { blob, buffer } = await this.recorder.stop();
			        this.isRecording = false; 
			        const audioUrl = URL.createObjectURL(blob);
			        // this.$refs.audio.src = audioUrl;
					console.log("音频url",audioUrl)
					console.log("音频buffer",buffer)
					const filename = "/warboard/"+this.mid + Date.now() + Math.random().toString(36).substring(2, 15) + '.mp3';
					this.uploadAudio(filename,blob)
			        // 如果需要上传，可以在这里进行上传
			        // const formData = new FormData();
			        // formData.append('file', blob);
			        // 上传逻辑...
			      } catch (error) {
			        console.error('录音停止失败:', error);
			      }
				  
			},
			async requestMicrophonePermission() {
			      try {
			        // 尝试获取用户的麦克风权限
			        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			        // 如果成功获取权限，可以关闭stream以释放资源
			        stream.getTracks().forEach((track) => track.stop());
			        console.log('麦克风权限已授权');
			        // 可以在这里通知用户权限已获得，或者进行其他操作
			      } catch (error) {
			        console.error('用户拒绝授权麦克风或发生错误:', error);
			        // 处理错误，例如通知用户
			      }
			},
			// startRecording() {
			// 	wx.startRecord();
			// },
			// stopRecording() {
			// 	wx.stopRecord({
			// 		success: async (res) => {
			// 			let Voice = this.voiceList.find(item => item.frameIdx === this.playerPlayIndex)
			// 			if (Voice) {
			// 				Voice.localId = res.localId
			// 				Voice.time = this.touchSecond
			// 			} else {
			// 				this.voiceList.push({
			// 					frameIdx: this.playerPlayIndex,
			// 					localId: res.localId,
			// 					time: this.touchSecond,
			// 					hasPaused: false
			// 				})
			// 			}
			// 			const tempFilePath = res.tempFilePath;
			// 			console.log("结束录音",res)
			// 			let file = await this.convertTempFilePathToBlob(tempFilePath)
			// 			const filename = '/warboard/' + this.mid + Date.now() + Math.random().toString(36).substring(2, 15) + '.mp3';
			// 			this.uploadAudio(filename,file)
			// 			this.touchSecond = 0

			// 		},
			// 		fail: (err) => {
			// 			console.log(err)
			// 		}
			// 	});
			// },
			playRecording() {
				const Voice = this.voiceList.find(item => item.frameIdx === this.playerPlayIndex)
				if (Voice.localId) {
					const audioContext = new (window.AudioContext || window.webkitAudioContext)();
					const sourceNode = audioContext.createBufferSource();
					fetch(Voice.localId)
						.then(response => response.arrayBuffer())
						.then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
						.then(audioBuffer => {
						  // 设置音频源为解码后的音频数据
						  sourceNode.buffer = audioBuffer;
				
						  // 连接音频源到音频上下文
						  sourceNode.connect(audioContext.destination);
				
						  // 播放音频
						  sourceNode.start();
						})
						.catch(error => {
						  console.error('播放录音时发生错误:', error);
						});
				}
			},
			checkBasketVoice(index) {
				const Voice = this.voiceList.find(item => item.frameIdx === index)
				if (Voice) {
					return true;
				} else {
					return false;
				}
			},
			startAnimation() {
				this.animationActive = true;
				this.setAnimationDelay();
				this.startRecording()

			},
			stopAnimation() {
				this.animationActive = false;
				this.resetAnimation();
				this.stopRecording()
				
			},
			setAnimationDelay() {
				this.startTime = Date.now();
				this.touchTimer = setInterval(() => {
					this.touchSecond = ((Date.now() - this.startTime) / 1000).toFixed(0);
				}, 1000);
				const circles = document.querySelectorAll('.circle');
				circles.forEach((circle, index) => {
					circle.style.animationPlayState = 'running';
					circle.style.animationDelay = `${index * 0.5}s`;
				});
			},
			resetAnimation() {
				clearInterval(this.touchTimer);
				this.isVoice = false
				const circles = document.querySelectorAll('.circle');
				circles.forEach((circle) => {
					circle.style.animationPlayState = 'paused';
					circle.style.animationDelay = '0s';
				});
			},
			saveTatic() {
				let taticData = {
					basketball: this.basketFrame,
					frame: this.frameList,
					player: this.player,
					voice: this.voiceList,
					line: this.frameLine,
					if_home: this.if_home,
					if_guest: this.if_guest
				}
				let StringData = JSON.stringify(taticData);
				let formData = new FormData();
				formData.append('title', this.taticName);
				formData.append('content', StringData);
				this.$api.post('/m/match/setwarboard', formData).then(response => {
					if (response.data.code == 1)  {
						this.$message({
							message: '保存成功',
							type: 'success'
						});
						this.taticName = ""
						this.issave = false
						this.getTaticList(true)
					}  else {
						this.$message({
							message: response.data.data,
							type: 'warning'
						});
					}
				}).catch(error => {
					console.error("There was an error!", error);
				})
				
			},
			updateBoard() {
				let taticData = {
					basketball: this.basketFrame,
					frame: this.frameList,
					player: this.player,
					voice: this.voiceList,
					line: this.frameLine,
					if_home: this.if_home,
					if_guest: this.if_guest
				}
				let StringData = JSON.stringify(taticData);
				let formData = new FormData();
				formData.append('id', this.taticid);
				formData.append('title', this.taticName);
				formData.append('content', StringData);
				this.$api.post('/m/match/setwarboard', formData).then(response => {
					if (response.data.code == 1)  {
						this.$message({
							message: '更新成功',
							type: 'success'
						});
						this.taticName = ""
						this.issave = false
						this.getTaticList(true)
					}  else {
						this.$message({
							message: response.data.data,
							type: 'warning'
						});
					}
				}).catch(error => {
					console.error("There was an error!", error);
				})
			},
			getTaticList(save = false) {
				let formData = new FormData();
				formData.append('pageindex', 1);
				formData.append('pagesize', -1);
				this.$api.post('/m/match/warboardlist', formData).then(response => {
					if (response.data.code == 1) {
						this.taticList = response.data.data.rows
						let formData = new FormData();
						formData.append("memberid", this.taticList[0].playerid);
						this.$api.post('/app/user/info', formData).then(response => {
							this.taticList.map((item) => {
							    item.avatarUrl = response.data.data.realportrait;
							    item.name = response.data.data.realname;
							});
						}).catch(error => {
							console.error("There was an error!", error);
						})
						
						if (save) {
							const newTatic = response.data.data.rows[0];
							this.taticName = newTatic.title;
							this.shareInfo.taticName = newTatic.title;
							this.taticid = newTatic.id;
							this.initWechatJsSDK()
						}
					}
					
				}).catch(error => {
					console.error("There was an error!", error);
				})
			},
			getTaticInfo() {
				let formData = new FormData();
				formData.append('id', this.taticid);
				this.$api.post('/m/match/getwarboard', formData).then(response => {
					const taticData = JSON.parse(response.data.data.content)
					this.basketFrame = taticData.basketball
					this.frameList = taticData.frame
					this.player = taticData.player
					this.voiceList = taticData.voice
					this.frameLine = taticData.line
					this.if_home = taticData.if_home
					this.if_guest = taticData.if_guest
					this.drawAllPath()
					this.isbasketFrame = true
					this.shareInfo.taticName = response.data.data.title
					this.taticName = response.data.data.title
					if (response.data.data.playerid != this.$route.query.mid) {
						let formData = new FormData();
						formData.append('memberid', response.data.data.playerid);
						this.$api.post('/app/user/info', formData).then(response => {
							console.log("所有者信息",response)
							this.shareInfo.username = response.data.data.realname
							this.shareInfo.userid = response.data.data.id
							this.getUserTaticlist(response.data.data.id)
							this.initWechatJsSDK()
						}).catch(error => {
							console.error("There was an error!", error);
						})
						this.isSharePage = true
					} else {
						this.isEditPage = true
					}
				}).catch(error => {
					console.error("There was an error!", error);
				})
			},
			toTatic(id = 0) {
				if (id == 0) {
					id = this.nowChooseId
				}
				location.href = 'https://www.douzhan.club/m/dz/warboard?id='+id
			},
			openMyBoard() {
				this.isMyBoard = true
			},
			openMyLike() {
				this.isMyLike = true
			},
			delTatic() {
				this.$confirm('确定要删除战术板吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					
					let formData = new FormData();
					formData.append('id', this.nowChooseId);
					this.$api.post('/m/match/delwarboard', formData).then(response => {
						this.taticList = this.taticList.filter(item => item.id !== this.nowChooseId)
						console.log(response)
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					}).catch(error => {
						console.error("There was an error!", error);
					})
					
				}).catch(() => {        
				});
				
			},
			newBoard() {
				location.href = 'https://www.douzhan.club/m/dz/warboard'
			},
			toBBS() {
				this.$router.push('/bbs')
			},
			register() {
				location.href = 'https://www.douzhan.club/resources/organ/#/pages/user/reg'
			},
			async uploadAudio(filename,file) {
				try {
				this.client = new OSS({
					region: 'oss-cn-beijing',
					accessKeyId: 'LTAI4GBbhUc2BB1YTufqNAyL',
					accessKeySecret: '0qdTEyY3Gy3Xh6OEREH60Ch0rOQaro',
					bucket: 'douzhansys',
					secure: true,
				});
				const result = await this.client.put(filename, file);
					let video = result.url
					let Voice = this.voiceList.find(item => item.frameIdx === this.playerPlayIndex)
					if (Voice) {
						Voice.localId = video
						Voice.time = this.touchSecond
					} else {
						this.voiceList.push({
							frameIdx: this.playerPlayIndex,
							localId: video,
							time: this.touchSecond,
							hasPaused: false
						})
					}
				} catch (err) {
					console.error('上传失败：', err);
				}
			},
			convertTempFilePathToBlob(tempFilePath) {
			    return new Promise((resolve, reject) => {
			        const xhr = new XMLHttpRequest();
			        xhr.open('GET', tempFilePath, true);
			        xhr.responseType = 'blob';
			
			        xhr.onload = function () {
						if (xhr.status === 200) {
							resolve(xhr.response);
						} else {
							reject(new Error('请求失败，状态码：' + xhr.status));
						}
			        };
			
			        xhr.onerror = function () {
						reject(new Error('请求出错'));
			        };
			
			        xhr.send();
			    });
			},
			sendRepair() {
				this.$refs.inputElement.blur();
				let formData = new FormData();
				formData.append('liveID', this.taticid);
				formData.append('chatmsg', this.repairText);
				formData.append('type', 3);
				this.$api.post('/app/match/SendChat', formData).then(response => {
					if (response.data.code === 1) {
						this.$message({
							type: 'success',
							message: '评论成功'
						});
						this.repairText = ''
						this.getRepair()
					} else {
						this.$message({
							type: 'warning',
							message: response.data.data
						});
					}
					
				}).catch(error => {
					console.error("There was an error!", error);
				})
			},
			getRepair() {
				if(this.taticid == 0) {
					this.messageList = []
					return;
				}
				this.$api.get(`app/match/ChatInfo`,{
				  params: {
				    liveID: this.taticid,
				    type: 3
				  }
				}).then(response => {
					if (response.data.code == 1) {
						this.messageList = response.data.data
						this.initDanmus();
					} else {
						this.messageList = []
					}
					
				})
				.catch(error => {
				  console.error("There was an error!", error);
				});
			},
			timeAgo(timestamp) {
			  const now = new Date();
			  const currentYear = now.getFullYear();
			  const difference = now.getTime() - timestamp;
		
			  const minute = 60 * 1000;
			  const hour = 60 * minute;
			  const day = 24 * hour;
		
			  if (difference < minute) {
				return '刚刚';
			  } else if (difference < 2 * minute) {
				return '1分钟前';
			  } else if (difference < hour) {
				return Math.floor(difference / minute) + '分钟前';
			  } else if (difference < 2 * hour) {
				return '1小时前';
			  } else if (difference < day) {
				return Math.floor(difference / hour) + '小时前';
			  } else if (difference < 2 * day) {
				return '昨天';
			  } else {
				const date = new Date(timestamp);
				const year = date.getFullYear();
				const month = (date.getMonth() + 1).toString().padStart(2, '0');
				const dayOfMonth = date.getDate().toString().padStart(2, '0');
				// 判断是否是当前年份
				if (year === currentYear) {
				  return `${month}-${dayOfMonth}`;
				} else {
				  return `${year}-${month}-${dayOfMonth}`;
				}
			  }
			},
			addCollect(id = 0) {
				let collectId = 0
				if (id == 0) {
					collectId = this.taticid
				} else {
					collectId = id
				}
				let formData = new FormData();
				formData.append('uid', this.mid);
				formData.append('fid', collectId);
				formData.append('type', 5);
				this.$api.post('/app/user/favorite', formData).then(response => {
					if (response.data.code === 1) {
						this.isMyLikeMenu = false
						this.$message({
							type: 'success',
							message: response.data.data
						});
						this.getCollect()
					} else {
						this.$message({
							type: 'warning',
							message: response.data.data
						});
					}
					
				}).catch(error => {
					console.error("There was an error!", error);
				})
			},
			async getCollect() {
			  try {
			    let formData = new FormData();
			    formData.append("uid", this.mid);
			    formData.append("type", 5);
			    formData.append("status", 0);
			    formData.append("pageindex", 1);
			    formData.append("pagesize", -1);
			
			    const response = await this.$api.post('/app/user/favoritelist', formData);
			
			    if (response.data.code == 1) {
			      this.isCollect = response.data.data.rows.some(
			        (item) => item.id == this.taticid
			      );
			
			      this.taticLikeList = response.data.data.rows;
			
			      // 使用 Promise.all 并发处理每个玩家信息请求
			      const requests = this.taticLikeList.map(async (item) => {
			        let formData = new FormData();
			        formData.append("memberid", item.playerid);
			        try {
			          const userInfoResponse = await this.$api.post(
			            "/app/user/info",
			            formData
			          );
			          item.avatarUrl = userInfoResponse.data.data.realportrait;
			          item.name = userInfoResponse.data.data.realname;
			        } catch (error) {
			          console.error("Fetching user info error:", error);
			        }
			      });
			
			      // 等待所有请求完成
			      await Promise.all(requests);
				  this.$forceUpdate();
			
			    } else {
			      this.taticLikeList = [];
			    }
			  } catch (error) {
			    console.error("There was an error fetching the collection:", error);
			  }
			},
			getUserTaticlist(id) {
				let formData = new FormData();
				formData.append('pageindex', 1);
				formData.append('pagesize', -1);
				formData.append('memberid', id);
				this.$api.post('/m/match/warboardlist', formData).then(response => {
					if (response.data.code == 1) {
						this.sharetaticList = response.data.data.rows
						const index = this.sharetaticList.findIndex(
							(item) => item.id == this.taticid
						);
					
						if (index !== -1) {
							const [targetItem] = this.sharetaticList.splice(index, 1);
							// 将目标项添加到数组的第一位
							this.sharetaticList.unshift(targetItem);
						}
						let formData = new FormData();
						formData.append("memberid", this.sharetaticList[0].playerid);
						this.$api.post('/app/user/info', formData).then(response => {
							this.sharetaticList.map((item) => {
							    item.avatarUrl = response.data.data.realportrait;
							    item.name = response.data.data.realname;
							});
						}).catch(error => {
							console.error("There was an error!", error);
						})
					} else {
						this.sharetaticList = []
					}
					
				}).catch(error => {
					console.error("There was an error!", error);
				})
			},
			showMyLikeMenu(id) {
				this.nowChooseId = id
				this.isMyLikeMenu = true
				this.isChooseCollect = this.taticLikeList.some(
				  (item) => item.id == id
				);
				
			},
			initDanmus() {
			  this.danmuList = this.messageList.map((comment) => {
				return {
				  text: comment.content,
				  x: this.canvasWidth + Math.random() * 200, // 初始位置：屏幕右侧随机位置
				  y: Math.random() * (this.canvasHeight - 30), // 垂直位置随机（避免出界）
				  speed: Math.random() * 3 + 2, // 随机滚动速度（2~5秒完成一轮）
				};
			  });
				setTimeout(() => {
			      if (this.danmuList.length > 0) {
			        this.toggleDanmu();
			      } else {
			        console.warn("没有可用的弹幕数据，不启动弹幕功能");
			      }
			    }, 2000);
			},
		
			// 控制弹幕滚动
			toggleDanmu() {
			  this.isDanmuActive = !this.isDanmuActive;
		
			  if (this.isDanmuActive) {
				this.startDanmu(); // 开始滚动弹幕
			  } else {
				this.stopDanmu(); // 停止弹幕
			  }
			},
		
			// 开始滚动弹幕
			startDanmu() {
			      const layer = this.$refs.danmuLayer;
			      const children = layer.children;
			
			      // 遍历每个弹幕元素，并启动其动画
			      Array.from(children).forEach((danmuElement, index) => {
			        // 获取当前弹幕的滚动速度和初始位置
			        const danmu = this.danmuList[index];
			        const step = this.canvasWidth / (danmu.speed * 60); // 每帧移动的像素（假设 60FPS）
			
			        // 动画逻辑：使用 setInterval 模拟滚动
			        const timer = setInterval(() => {
			          danmu.x -= step; // 每帧向左移动一定像素
			          if (danmu.x + danmuElement.offsetWidth < 0) {
			            // 如果弹幕完全移出屏幕，则重置位置
			            danmu.x = this.canvasWidth + Math.random() * 200;
			          }
			          // 更新弹幕的样式
			          danmuElement.style.left = `${danmu.x}px`;
			        }, 16); // 每隔 16ms 更新一次
			
			        // 保存定时器以便在停止时清理
			        this.animationTimers.push(timer);
			      });
			    },
		
			// 停止滚动弹幕
			stopDanmu() {
			  // 清除所有定时器
			  this.animationTimers.forEach((timer) => clearInterval(timer));
			  this.animationTimers = [];
			},
		}
	}
</script>
<style scoped>
	@import url('@/assets/css/color/iconfont.css');

	body {
		overflow: hidden;
	}

	.content {
		background-color: #141414;
		min-height: 100vh;
		touch-action: pan-y;
	}

	.player-item {
		border-radius: 50%;
		width: 60px;
		height: 60px;
		background-color: #000;
		color: #fff;
		text-align: center;
		line-height: 60px;
	}

	.board-box {
		width: 90%;
		height: 450px;
		border: 1px solid #000;
		margin: auto;
	}

	.ball {
		width: 30px;
		height: 30px;
		background-color: #000;
		color: #fff;
		text-align: center;
		line-height: 30px;
		border-radius: 50%;
		position: absolute;
		top: 100px;
		/* 初始位置 */
		left: 100px;
		/* 初始位置 */
		cursor: pointer;
		font-size: 14px;
		z-index: 9;
	}

	.basketball_icon {
		position: relative;
		right: -10px;
		bottom: 20px;
		display: block;
		width: 30px;
		height: 30px;
		z-index: 8;
		font-size: 30px;
	}

	.backetball_move {
		position: absolute;
		display: block;
		width: 30px;
		height: 30px;
		z-index: 8;
		font-size: 30px;
	}

	.play_button {
		width: 200px;
		height: 90px;
		margin: auto;
		font-size: 35px;
	}

	.btn_box {
		width: 60px;
		position: absolute;
		display: flex;
		flex-direction: column;
	}

	.board_btn {
		height: 30px;
		line-height: 30px;
		font-size: 13px;
		border-radius: 7px;
		margin-bottom: 2px;
		border: 1px solid #ccc;

	}

	.formation_box {
		border: 1px solid #ccc;
		display: flex;
		justify-content: space-around;
	}

	.formation_box_item {
		width: 100rpx;
		height: 100rpx;
		text-align: center;
		line-height: 100rpx;
		border-radius: 10rpx;
		border: 2rpx solid orange;
		font-size: 26rpx;
	}

	.canvas-board {
		width: calc(100vw - 2px);
		/* height: 40vh; */
		/* background-image: url('../../assets/images/board.jpg'); */
		background-size: 100% 100%;
		aspect-ratio: 4/3.2;

	}

	.top_menu_nav {
		display: flex;
		justify-content: right;
		width: 100%;
		min-height: 50px;
	}

	.btns-box {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}

	.menu_btn {
		margin: 6px;
		color: #fff;
		border-radius: 10px;
		font-weight: bold;
		border: 0;
	}

	.my_tactic {
		background-color: #2a2a2a;
	}

	.export {
		background-color: #fe2b54;
		width: 80px;
	}

	.export:hover {
		color: #fff;
		background-color: #fe2b54 !important;
		/* 点击时保持颜色不变 */
	}

	/* 点击时保持颜色不变 */
	.export:active {
		background-color: #fe2b54 !important;
		/* 点击时保持颜色不变 */
	}

	.export:focus {
		color: #fff;
		background-color: #fe2b54 !important;
		/* 点击时保持颜色不变 */
	}

	.saveBtn {
		background-color: #fff;
		width: 80px;
		color: #000;
	}

	.middle_menu_nav {
		color: #c0c0c0;
		display: flex;
		justify-content: space-around;
		background-color: #1c1c1c;
	}

	.middle_menu_item {
		display: flex;
		flex-direction: column;
		padding-top: 8px;
		padding-bottom: 6px;
	}

	.middle_menu_item i {
		font-size: 22px;
	}

	.middle_menu_item span {
		font-size: 12px;
		margin-top: 10px;
	}

	.middle_time_nav {
		display: flex;
		color: #c0c0c0;
		align-items: center;
		background-color: #141414;
		height: 50px;
	}

	.middle_time_nav div {
		width: 33%;
	}

	.tool_box i {
		font-size: 22px;
	}

	.tool_box {
		display: flex;
		justify-content: right;
	}

	.time_box {
		font-size: 15px;
		font-weight: bold;
	}

	.bottom_time_nav {
		background-color: #1c1c1c;
		height: 200px;
		overflow-x: scroll;
		-ms-overflow-style: none;
		/* 隐藏 IE 和 Edge 浏览器的滚动条 */
		scrollbar-width: none;
		/* Firefox 浏览器 */
		overflow: -webkit-scrollbar;
		/* 隐藏 Chrome 和 Safari 浏览器的滚动条 */
		overflow-y: auto;
		position: relative;
		width: 100vw;
		margin-bottom: 10px;
	}

	.bottom_time_float {
		position: relative;
		top: -200px;
		display: flex;
		align-items: center;
		height: 180px;
		justify-content: space-between;
		width: 100vw;


	}


	.bottom_time_nav::-webkit-scrollbar {
		display: none;
		/* 隐藏 Chrome 和 Safari 浏览器的滚动条 */
	}

	.bottom_time_nav_inner {
		height: 180px;
		width: auto;

	}

	.btn_box {
		width: 120rpx;
		position: absolute;
	}

	.board_btn {
		height: 60rpx;
		line-height: 60rpx;
		font-size: 26rpx;
		border-radius: 15rpx;
		margin-bottom: 5rpx;
		border: 1px solid #ccc;

	}

	.time_line {
		display: flex;
		padding-top: 10px;
		padding-left: 20px;
	}

	.time_item {
		width: 60px;
		color: #c0c0c0;
		font-size: 10px;

	}

	.scroll_line {
		width: 2px;
		height: 100%;
		background-color: #c0c0c0;
		z-index: 9;
		position: absolute;
		left: 50px;
		height: 150px;
		top: 25px;
	}

	.scroll_box {
		display: flex;
		height: calc(100% - 40px);
		align-items: center;
		padding-left: 50px;


	}

	.scroll_item {
		width: 60px;
	}

	.scroll_item .scroll_line {
		margin: 0 auto;
		margin-top: -75px;
		margin-left: 30px;
		position: absolute;
	}

	.balldefined {
		background-color: #fe2b54;
	}

	.addFrame {
		display: flex;
		justify-content: left;
		align-items: center;
		margin: auto;
		width: 100%;
		position: absolute;
		margin-left: 20px;
		right: calc(-100vw + 80px);

	}

	.scroll_text {
		right: calc(50vw - 80px);
		position: absolute;
		width: 160px;
	}

	.addFrame_btn {
		width: 50px;
		height: 50px;
		background-color: #fff;
		border-radius: 8px;
		text-align: center;
		line-height: 50px;
		color: #000;
		margin-right: 20px;
		z-index: 99;
		box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .2);

	}

	.addFrame_btn i {
		font-size: 25px;
	}

	.addFrame_text {
		color: #fff;
	}

	.picker-container {
		display: flex;
		justify-content: space-around;
		background-color: #2a2a2a;
		height: 100%;
		align-items: center;
	}

	/deep/ .message-container {
		display: flex;
		background-color: #fff;
		border-radius: 20px 20px 0px 0px;
		flex-direction: column;
	}

	/deep/ .el-drawer__body {
		border-radius: 20px 20px 0px 0px;
	}

	/deep/ .el-drawer.btt {
		border-radius: 20px 20px 0px 0px;
	}

	.share_item {
		display: flex;
		flex-direction: column;
		color: #fff;
	}

	.share-icon {
		font-size: 35px;
		margin-bottom: 20px;
	}

	.share_text {
		font-size: 14px;
	}

	.picker-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px;
		font-size: 16px;
		border-bottom: 1px solid #ebeef5;
	}

	.picker-scroll {
		flex: 1;
		overflow-y: auto;
		padding: 16px;
	}

	.picker-scroll ul {
		list-style: none;
		padding: 0;
		margin: 0;
		text-align: center;
	}

	.picker-scroll li {
		padding: 8px 0;
		cursor: pointer;
	}

	.picker-scroll li.active {
		color: #409EFF;
		font-weight: bold;
	}

	.frame_scroll {
		width: 150px;
		height: 45px;
		background-color: #fff;
		border-radius: 5px;
		position: relative;
		z-index: 8;
		display: flex;
		align-items: center;
		border: 2px solid #141414;
		box-shadow: 0px 0px 1px 2px #141414;
		line-height: 60px;
		color: #141414;
		font-size: 18px;
		font-weight: bold;
		display: flex;
		justify-content: center;

	}

	.icon-suoding {
		position: absolute;
		right: 10px;
	}

	.voice_scroll {
		height: 20px;
		background-image: url('@/assets/images/voice.png');
		background-size: auto 100%;
		position: absolute;
		display: flex;
		justify-content: space-between;
		z-index: 8;
		bottom: 30px;
	}

	.basketFrame {
		display: flex;
		justify-content: space-between;
		position: relative;
		z-index: 8;

	}

	.frame_scroll_line {
		height: 30px;
		width: 5px;
		border-radius: 5px;
		background-color: #ccc;
		margin: 0 10px;
	}

	.frame_scroll_inner {
		background-color: #ccc;
		width: calc(100% - 50px);
		height: 50px;
	}

	.bottom_menu {

		display: flex;
		justify-content: space-around;
		position: relative;
		top: -180px;

	}

	.bottom_menu_btn {
		display: flex;
		flex-direction: column;
	}

	.share_mod {
		margin-left: 20px;
		margin-right: 10px;

	}
	
	.selectPage {
		font-weight: bold;
		color: #fff!important;
		line-height: 20px;
	}
	
	.selectPage::before {
		display: block;
		content: '';
		width: 60%;
		height: 3px;
		background-color: #fff;
		border-radius: 3px;
		position: relative;
		bottom: -30px;
		left: 0;
		right: 0;
		margin: auto;
		
	}

	.share_mod>.bottom_text {
		font-size: 18px;
		position: relative;
	}

	.bottom_text {
		font-size: 12px;
		color: #c0c0c0;
	}

	.bottom_menu_btn .iconfont {
		font-size: 24px;
		margin-bottom: 10px;
		color: #c0c0c0;
	}

	.bottom_menu_menu {
		display: flex;
		justify-content: space-around;
		height: 40px;
		color: #c0c0c0;
		line-height: 40px;
		background-color: #2a2a2a;
		position: relative;
		top: -190px;
	}

	.bold_text {
		font-weight: bold;
		color: #fff;
	}

	.color_block {
		width: 20px;
		height: 20px;
		border-radius: 5px;
		border: 2px solid #c0c0c0;
	}

	.bottom_menu_menu_item {
		display: flex;
		align-items: center;
	}

	.playerInfo_box {
		display: flex;
		align-items: center;
		color: #fff;
		position: relative;
	}

	.playerInfo_box_face {
		margin: 0px 10px;
	}

	.playerInfo_box_face img {
		width: 30px;
		height: 30px;
		border-radius: 30px;
	}

	.playerInfo_box_name {
		color: #fff;
		font-size: 14px;
	}

	.playerInfo_box_list {
		width: 170px;
		background-color: #fff;
		position: absolute;
		top: 40px;
		left: 20px;
		border-radius: 6px;
		box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .2);
		background: #1c1c1c;
		padding: 0px 5px;
		z-index: 99999;
	}

	.playerInfo_box_list_item {
		height: 40px;
		line-height: 40px;
		border-bottom: 1px solid #c0c0c0;
		font-size: 14px;
	}

	.playerInfo_box_list_item_tatic {
		width: 80%;
		border-bottom: 1px solid #c0c0c0;
		margin: 0 auto;
		height: 40px;
		line-height: 40px;
		font-size: 13px;
		text-align: left;
	}

	.playerInfo_box_list_item_tatic:last-child {
		border-bottom: 0px;
	}

	.playerInfo_box_list_item:last-child {
		border-bottom: 0px;
	}

	.registerBtn {
		background-color: rgba(0, 0, 0, 0);
		border: 2px solid #fff;
		color: #fff;
	}

	.saveDialog {
		width: 300px;
		height: 160px;
		border-radius: 10px;
		background-color: #141414;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .2);
	}

	.fansDialog {
		width: 200px;
		height: 250px;
		border-radius: 10px;
		background-color: #141414;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .2);
	}

	.fansDialog img {
		margin-top: 30px;
	}

	.fansDialog span {
		color: #fff;
		height: 60px;
		line-height: 60px;
	}

	.saveDialog_input {
		border: 0;
		border-bottom: 1px solid #fff;
		width: 80%;
		margin: 0 auto;
		background-color: rgba(0, 0, 0, 0);
		color: #fff;
		height: 40px;
		line-height: 40px;
		padding: 0px 5px;
		margin: 20px 0px;
		outline: none;
	}

	.saveDialog span {
		height: 80px;
		line-height: 80px;
		color: #fff;
	}

	.saveDialog_btns {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.maskdiv {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, .6);
		z-index: 99;
	}

	.isbasketBall {
		top: 40px;
		height: 20px;
	}

	.isChoose {
		background-color: #c0f7ff;
		box-shadow: 0px 0px 1px 2px #409EFF;
	}

	.isbasketBallChoose {
		background-color: #fe6d70 !important;
		box-shadow: 0px 0px 1px 2px #fe7679;
		color: #fff;
	}

	.isVoiceChoose {
		box-shadow: 0px 0px 1px 1px #aafebf;
		background-color: #4c8851 !important;
		color: #fff;
	}

	.basketFrame_item {
		height: 25px;
		width: 60px;
		background-color: #fff;
		border-radius: 5px;
		line-height: 25px;
		box-shadow: 0px 0px 1px 2px #141414;
		position: relative;
		margin-top: 5px;
	}

	.basketFrame_item img {
		width: 25px;
		height: 25px;
	}

	.front_frame {
		position: absolute;
		left: 0;
	}

	.after_frame {
		position: absolute;
		right: 0;
	}

	.front_frame:before {
		content: '●';
		font-size: 10px;
		position: absolute;
		left: 4px;
		color: #848484;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	.after_frame:before {
		content: '●';
		font-size: 10px;
		position: absolute;
		right: 4px;
		color: #848484;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	.isbasketBallChoose .front_frame:before {
		color: #fff;
	}

	.mask-share {
		position: fixed;
		background-color: rgba(0, 0, 0, .8);
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: 99999;
	}

	.mask-share img {
		width: 100%;
	}

	.share-button {
		width: 170px;
		height: 50px;
		border-radius: 15px;
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #fff;
		color: #fff;
		text-align: center;
		line-height: 50px;
		font-size: 18px;
		margin-top: 50px;
	}

	.loading-screen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		opacity: 1;
		transition: opacity 0.5s ease;
	}

	.loading-screen img {
		width: 50px;
		height: 50px;
	}

	.fade-out {
		opacity: 0;
		pointer-events: none;
		/* 确保渐隐后不再阻止点击事件 */
	}

	.noneSelect {
		color: #434343;
	}

	.message-container-nav {
		text-align: center;
		font-size: 12px;
		width: 100%;
		height: 40px;
		line-height: 40px;
	}

	.message-item {
		display: flex;
		margin-bottom: 20px;
		padding:0px 30px;
	}

	.message-item-left {
		width: 100px;
		display: flex;
		justify-content: right;
	}

	.message-item-left img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid #ccc;
	}

	.message-item-right {
		display: flex;
		flex-direction: column;
		justify-content: left;
		align-items: left;
		text-align: left;
		padding-left: 10px;
	}

	.message-item-right-name {
		font-size: 12px;
		color: #a6a6a6;
	}

	.message-item-right-content {
		font-size: 14px;
		margin-top: 10px;
		line-height: 22px;
		width: calc(100vw - 100px);
	}

	.message-item-right-btns {
		display: flex;
		margin-top: 20px;
		font-size: 12px;

	}

	.message-item-time,
	.message-item-site {
		color: #a6a6a6;
	}

	.message-item-right-btns-left {
		display: flex;
		width: 60%;
		align-items: center;

	}

	.message-item-report-btn {
		margin-left: 20px;
	}

	.message-item-right-btns-right {
		display: flex;
		justify-content: right;
		width: 40%;
	}

	.like-btn,
	.unlike-btn {
		margin-right: 20px;
		white-space: nowrap;
	}

	.message-container-btns {
		display: flex;
		justify-content: right;
		height: 40px;
		line-height: 40px;
	}
	
	.board-container-btns .iconfont {
		position: absolute;
		right: 10px;
	}
	
	.board-container-btns {
		margin-top: 20px;
	}
	
	.board-container-btns .message-container-nav{
		font-size: 16px;
		font-weight: bold;
	}

	.microphone-animation {
		position: relative;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background-color: red;
		display: flex;
		align-items: center;
	}

	.secondTips {
		position: absolute;
		bottom: 90%;
		font-size: 120px;
		width: 100px;
		display: block;
	}

	.voice-icon {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 99;
		font-size: 35px;
		margin: auto;
		margin-top: 30px;
	}

	.circle {
		position: absolute;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background-color: rgba(255, 0, 0, 0.2);
		animation: pulse 2s infinite;
		animation-play-state: paused;
	}

	@keyframes pulse {
		0% {
			transform: scale(1);
			opacity: 1;
		}

		100% {
			transform: scale(2);
			opacity: 0;
		}
	}

	/deep/ .el-drawer {
		overflow: visible;
	}

	.shareBtn i {
		font-size: 30px;
		color: #fff;
		display: flex;
		flex-direction: column;
	}

	.modelName {
		font-size: 20px;
		color: #fff;
		margin: 100px auto;
		display: block;
	}

	.shareBtn {
		margin-top: 50px;
	}

	.shareBtn_div {
		display: flex;
		justify-content: space-around;
	}
	
	.bottom_view_box {
		height: calc(35vh - 20px);
		margin-top: -150px;
		overflow-y: scroll;
		-ms-overflow-style: none;
		/* 隐藏 IE 和 Edge 浏览器的滚动条 */
		scrollbar-width: none;
		/* Firefox 浏览器 */
		overflow: -webkit-scrollbar;
		/* 隐藏 Chrome 和 Safari 浏览器的滚动条 */
	}
	
	.bottom_view_box::-webkit-scrollbar {
		display: none;
		/* 隐藏 Chrome 和 Safari 浏览器的滚动条 */
	}
	
	.grid-container {
	  display: grid;
	  grid-template-columns: repeat(2, 1fr); /* 每列4个 */
	  gap: 5px; /* 间隔5px */
	}
	
	.board-grid-container {
		width: 92%;
		margin: 20px auto;
		gap: 25px;
	}
	
	.bottom_view_like {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  flex-direction: column;
	  color: #fff;
	  font-size: 12px;
	  border-radius: 10px;
	  overflow: hidden;
	  position: relative;
	}
	
	.bottom_view_like img {
	  width: 100%;
	  height: 100%;
	}
	
	.repair_box {
		width: 100%;
		height: 60px;
		background-color: #1c1c1c;
		position: fixed;
		bottom: 0;
	}
	
	.repair_box_input {
		width: calc(80% - 60px);
		height: 40px;
		border-radius: 40px;
		border: 0;
		background-color: #141414;
		position: relative;
		margin-top: 6px;
		outline: none;
		padding-left: 20px;
		color: #fff;
		padding-right: 60px;
	}
	
	.repair_box .icon-fabu {
		position: absolute;
		right: 60px;
		font-size: 26px;
		color: #fff;
		top: 12px;
	}
	
	.repair_box .icon-liwu {
		font-size: 24px;
	}
	
	.like_box-info {
		display: flex;
		justify-content: start;
		text-align: left;
		align-items: left;
		width: 100%;
		padding-top: 5px;
		background-color: #e5e5e5;
		position: relative;
	}
	
	.like_box-info i {
		color: #000;
		position: absolute;
		bottom: 10px;
		right: 0px;
	}
	
	.like_box-info img {
		width: 40px;
		height: 40px;
		border-radius: 35px;
		margin: 5px;
	}
	
	.bottom_view_like_info {
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: left;
		text-align: left;
		position: relative;
	}
	
	.like-title {
		font-size: 14px;
		font-weight: bold;
		margin: 5px;
		color: #000;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 120px;
	}
	
	.like-user {
		color: #666666;
		margin-left: 5px;
		margin-bottom: 10px;
	}
	
	.menu-scroll-div {
		height: 120px;
		overflow-y: scroll;
	}
	
	
	/* 滚动条的轨道 */
	.menu-scroll-div::-webkit-scrollbar-track {
	    background: #1c1c1c;
	    border-radius: 10px;
	}
	
	.board-container-search-input {
		position: relative;
	}
	
	.board-container-search-input input {
		width: 90%;
		height: 35px;
		background-color: #eeeeee;
		border: 0px;
		padding-left: 10px;
		border-radius: 35px;
		outline: none;
		
	}
	
	.board-container-search-input i {
		position: absolute;
		right: 20px;
		top: 10px;
	}
	
	.like-hot-box {
		background-color: #000;
		width: 100%;
		display: flex;
		justify-content: space-between;
		height: 40px;
		align-items: center;
		position: absolute;
		bottom: 60px;
		background: linear-gradient(to top,rgba(0,0,0,0.4),rgba(0,0,0,0));
	}
	
	.hot-box {
		margin-right: 10px;
	}
	
	
	
	.board_menu_box_main {
		display: flex;
		justify-content: space-around;
		padding-top: 20px;
		background-color: rgba(42, 42, 42, 1);
		height: 100%;
	}
	
	.board_menu_box_item {
		display: flex;
		flex-direction: column;
	}
	
	.board_menu_box_item span {
		color: #fff;
		font-size: 14px;
		margin-top: 5px;
	}
	
	.board_menu_box_item i {
		font-size:35px;
		margin-bottom: 5px;
		color: #fff;
	}
	
	.empty-box {
		width: 90vw;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		margin-top: 40%;
		font-size: 14px;
		color: #686868;
		
	}
	
	.share-title {
		color: #fff;
		position: absolute;
		top: 350px;
		left: 20px;
		text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
		font-size: 12px;
	}
	
	.share_like_item {
		width: 92%;
		margin: 0 auto;
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
		position: relative;
		
		
	}
	
	.icon-dangqian {
		position: absolute;
		right: -8px;
		z-index: 1;
		font-size: 40px;
	}
	
	.share_like_item img {
		width: 40px;
		height: 40px;
		margin-left: 15px;
	}
	
	.share_like_item .like_box-info {
		background-color: #1c1c1c;
		padding: 5px 0px;
	}
	
	
	.share-like-tool {
		display: flex;
		width: 50%;
		justify-content: space-between;
		position: absolute;
		right: 0;
		top: 22px;
	}
	
	.share-like-tool i {
		font-size: 22px;
	}
	
	.bottom_text_box img{
		width: 25px;
		height: 25px;
		border-radius: 25px;
		margin-right: 10px;
	}
	
	.bottom_text_box {
		display: flex;
		align-items: center;
	}
	
	.redborder {
		/* border: 2px solid #fe6d70; */
	}
	
	.redborder img {
		border: 2px solid #fe6d70;
	}
	
	.redborder>.like-user {
		font-weight: bold;
		color: #fff;
	}
	
	.selectText {
		font-weight: bold;
		color: #fff!important;
	}
	
	.message-tab-btns {
		width: 100%;
		display: flex;
		justify-content: space-around;
		height: 50px;
		line-height: 50px;
		margin-bottom: 20px;
	}
	
	.message-container-btn {
		width: 30%;
	}
	
	
	
	.messageChoose {
		border-bottom: 2px solid red;
		height: 50px;
		font-weight: bold;
	}
	
	.message-scroll {
		overflow-y: scroll;
	}
	
	.message-container-nav-float {
		display: flex;
		justify-content: space-around;
		margin-bottom: 30px;
	}
	
	.num-box {
		display: flex;
		flex-direction: column;
	}
	
	.num-box-number {
		font-size: 40px;
		font-weight: bold;
	}
	
	.num-box-text {
		font-size: 14px;
	}
	
	.icon-icon_collect_selected {
		color: #ec4c61!important;
	}
	
	/* 弹幕层样式 */
	.danmu-layer {
	  position: absolute;
	  top: 30px;
	  left: 0;
	  pointer-events: none; /* 禁止鼠标事件穿透 */
	  z-index: 10;
	  overflow: hidden;
	}
	
	/* 弹幕文字样式 */
	.danmu {
	  position: absolute;
	  white-space: nowrap;
	  color: #fff;
	  font-size: 20px;
	  font-weight: bold;
	  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
	}
	
	/* 弹幕滚动动画 */
	@keyframes danmu-scroll {
	  from {
	    transform: translateX(0);
	  }
	  to {
	    transform: translateX(-100%);
	  }
	}
	
	
	
	


</style>