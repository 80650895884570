<template>
	<div class="login">
		<div class="logo">
			<img class="logo-image" src="@/assets/images/basket_logo.png" />
		</div>
		<div class="from">
			<el-input class="form-input" type="text" v-model="form.username" placeholder="请输入用户名"></el-input>
			<el-input class="form-input" type="password" v-model="form.password" placeholder="请输入密码"></el-input>
		</div>
		<div class="btn" @click="handleClick">
			登录
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					username: '',
					password: ''
				}
			}
		},
		mounted() {
		},
		methods: {
			handleClick() {
				let formData = new FormData();
				formData.append('username', this.form.username);
				formData.append('password', this.form.password);
				this.$api.post('playv2/login', formData).then(response => {
				  if (response.data.code == 1) {
				      // this.$message({
				      //     type: 'success',
				      //     message: '登录成功'
				      // });
				      localStorage.setItem('userinfo', JSON.stringify(response.data.data));
				      localStorage.setItem('tips_show', true);
				      this.$store.dispatch('setLogin', { username: this.form.username, timestamp: new Date().getTime() });
				      this.$router.replace({ path: '/menu' });
				  } else {
				      this.$message({
				          type: 'error',
				          message: response.data.data
				      });
				  }
				  
				  
				})
				.catch(error => {
				  console.error("There was an error!", error);
				});
			}
		}
	}
</script>

<style scoped>
	.login {
		width: 100%;
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}
	
	.logo {
		margin: 100px 0 100px;
		text-align: center;
	}
	
	.logo-image {
		width: 250px;
		height: 85px;
	}
	
	.from {
		padding: 50px 60px;
	}
	
	.form-input {
		margin-bottom:20px;
	}
	
	.btn {
		width: 70%;
		height: 40px;
		margin-left: 30px;
		line-height: 40px;
		text-align: center;
		color: #fff;
		background-image: linear-gradient(to right, #9501ff, #6300ff);
		border-radius: 100px;
		margin: 0 auto;
	}
</style>
