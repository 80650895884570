<template>
	<div>
		<div class="match-box-info">
		    <div class="match-box-info-item" @click="toTeam">
		        <span class="match-box-info-item-num">{{playersNum || 0}}</span>
		        <span class="match-box-info-item-name">球员</span>
		    </div>
		    <div class="line"></div>
		    <div class="match-box-info-item">
		        <span class="match-box-info-item-num">{{fansNum || 0}}</span>
		        <span class="match-box-info-item-name">粉丝</span>
		    </div>
		    <div class="line"></div>
		    <div class="match-box-info-item" @click="toRef">
		        <span class="match-box-info-item-num">{{refsNum || 0}}</span>
		        <span class="match-box-info-item-name">裁判</span>
		    </div>
		    <div class="line"></div>
		    <div class="match-box-info-item" @click="toTeam">
		        <span class="match-box-info-item-num">{{teamsNum || 0}}</span>
		        <span class="match-box-info-item-name">队伍</span>
		    </div>
		</div>
		<div class="content">
			<div class="menu-item" @click="toCreate()">
				<i class="iconfont icon-xinjianchuangkou"></i>
				<span>创建队伍</span>
			</div>
			<div class="menu-item" @click="toTeam()">
				<i class="iconfont icon-24gf-userGroup2"></i>
				<span>管理队伍</span>
			</div>
			
		
			<div class="menu-item" @click="toPub()">
				<i class="iconfont icon-youjianyouxiang"></i>
				<span>发布通知</span>
			</div>
			<div class="menu-item" @click="toTool()">
				<i class="iconfont icon-fangge"></i>
				<span>比赛控制</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [{
				  value: '2',
				  label: '免费'
				}, {
				  value: '1',
				  label: '报一个队伍收一次费用'
				}, {
				  value: '0',
				  label: '报几个队伍收几次费用'
				}],
				value: '0',
				isEditTitle: false,
				matchTitle: "斗战篮球赛事测试",
				tempTitle: '',
				dialogVisible: false,
				dialogLCVisible: false,
				cropperImage: "",
				cropperWidth: 500,
				cropperHeight: 250,
				firstImg: '',
				secondImg: require('@/assets/images/slider.jpg'),
				chooseImgBox: "",
				carouseId: 0,
				liveid: "",
				fansNum: 0,
				refsNum: 0,
				playersNum: 0,
				teamsNum: 0,
				is_signup: 1,
				isSignupReferee: 1,
				is_Auth: 0,
				is_create: 0,
				is_scoreend: 0,
				is_liveend: false,
				params: "name,teamsname,playtype,playno,height,weight,sfzh,tel,portrait,pregfee,unregfee",
				price: "",
				payType: "0",
				oldprice: 0,
				members: "",
				oldmembers: '',
				inputWidth: 100,
				dialogQrVisible: false,
				username: '',
				userlogo: '',
				url: "",
				joinType: '0',
				isShow: false
			}
		},
		created() {
		    this.$api.post('api/Main/GetGame').then(response => {
		       if (response.data.code == 0) {
		           this.$message({
		             type: 'error',
		             message: response.data.data
		           });
		           this.$router.push({ path: '/mlogin'});
		           return;
		       }
		       this.liveid = response.data.data.id
		       this.GetFansList()
		       this.GetRefsList()
		       this.GetPlayersList()
		       this.GetTeamsList()
		       
		   }).catch(error => {
		       console.error("There was an error!", error);
		   }) 
		   const userinfo = JSON.parse(localStorage.getItem('userinfo'));
		   this.username = userinfo.name
		   this.userlogo = userinfo.logourl
		},
		methods: {
			toCreate() {
				this.$router.push({ path: '/mcreate'});
			},
			toTeam() {
				this.$router.push({ path: '/mteam'});
			},
			toPub() {
				this.$router.push({ path: '/mpub'});
			},
			toTool() {
				this.$router.push({ path: '/mtool'});
			},
			toRef() {
				this.$router.push({ path: '/mref'});
			},
			GetFansList() {
			    this.$api.get(`api/Main/favoritelist`,{
			      params: {
			        PageIndex: 1,
			      }
			    }).then(response => {
			        this.fansNum = response.data.data.total
			    })
			    .catch(error => {
			      console.error("There was an error!", error);
			    });
			},
			GetRefsList() {
			    this.$api.get(`app/match/referees`,{
			      params: {
			        PageIndex: 1,
			        liveid: this.liveid
			      }
			    }).then(response => {
			        this.refsNum = response.data.data.total
			    })
			    .catch(error => {
			      console.error("There was an error!", error);
			    });
			},
			GetPlayersList() {
			    this.$api.get(`api/Team/Players`,{
			      params: {
			        PageIndex: 1,
			        liveid: this.liveid,
			        teamid: 0
			      }
			    }).then(response => {
			        this.playersNum = response.data.total
			    })
			    .catch(error => {
			      console.error("There was an error!", error);
			    });
			},
			GetTeamsList() {
			    this.$api.get('api/Team/Teams', {
			      params: {
			        limit: -1,
			        PageIndex: 1,
			      }
			    })
			    .then(response => {
			        this.teamsNum = response.data.total
			    })
			    .catch(error => {
			      console.error("There was an error!", error);
			    });
			},
		}
	}
</script>

<style scoped>
	.content {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		padding-top: 50px;
	}
	
	.menu-item {
		width: calc(100% - 20px);
		height: 160px;
		border: 1px solid #ccc;
		border-radius: 5px;
		margin: 10px;
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		align-items: center;
	}
	
	.iconfont {
		font-size: 50px;
		margin-bottom: 15px;
	}
	
	.match-box-info {
	    display: flex;
	    justify-content: space-around;
	    width: calc(100% - 80px);
	    margin: 0 auto;
	    padding: 40px;
	    padding-bottom: 20px;
	    
	}
	
	.match-box-info-item {
	    text-align: center;
	    width: 100px;
	    cursor: pointer;
	}
	
	.line {
	    width: 1px;
	    border-left: 1px solid #ccc;
	    height: 50px;
	    margin: 0px 20px;
	    margin-top: 5px;
	}
	
	.match-box-info-item-num {
	    font-size: 34px;
	    color: #000;
	    font-weight: bold;
	}
	
	.match-box-info-item-name {
	    display: block;
	    font-size: 16px;
	    color: #666;
	}
</style>